import { Box, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { Fragment, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { CRUD_LABELS, CRUD_MODELS, FORM_CONSTANTS, FORM_MODEL, SCIENTIFIC_RESEARCH_CONSTANTS } from '../../../constants';
import CrudTable from '../../../components/Crud/CrudTable/CrudTable';
import { formActions, journalDomesticActions } from '../../../actions';
import DuyetThanhTichModal from '../DuyetThanhTichModal';
import ThanhTichInsert from '../../DangKyHoSo/ThanhTichInsert';
import AnhChanDung from '../../../components/CommonComponents/AnhChanDung/AnhChanDung';

const OPTIONS = [
  ...SCIENTIFIC_RESEARCH_CONSTANTS.FILTER_TYPE,
  { key: CRUD_MODELS.MONOGRAPH, value: CRUD_MODELS.MONOGRAPH, title: CRUD_LABELS[CRUD_MODELS.MONOGRAPH] }
];

function ThongTinCaNhan(props) {
  const dispatch = useDispatch();

  const { id } = useParams();
  const form = useSelector((state) => state.formReducer.form);

  const handleReload = () => {
    dispatch(formActions.getApplicationFormById(id));
    dispatch(journalDomesticActions.filterJournalDomestic({
      page: 1, pageSize: 10000, percent: 0, title: ''
    }));
  }

  useEffect(() => {
    handleReload();
  }, []);

	return (
    <Fragment>
      <Grid container>
        <Grid item xs={12}>
          <Box width='100%' display='flex' justifyContent='center'>
            <AnhChanDung photoUrl={form?.photoUrl?.[0]} />
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box mt={1}>
            {/* <b>Họ và tên: </b><span>{form?.profile?.name}</span> */}
            <Typography variant='h6'><b>{form?.profile?.name}</b></Typography>
          </Box>
          <Box mt={1}>
            <span>{form?.profile?.email}</span>
          </Box>
          {/* <Box mt={1}>
            <b>{FORM_MODEL.major.label}: </b>
            <span>
              {FORM_MODEL.major.options.find((e) => e.value === form?.[FORM_MODEL.major.key])?.title}
            </span>
          </Box>
          <Box mt={1}>
            <b>{FORM_MODEL.specialization.label}: </b>
            <span>
              {[
                ...FORM_CONSTANTS.SPECIALIZATION_OPTIONS.accounting,
                ...FORM_CONSTANTS.SPECIALIZATION_OPTIONS.business,
                ...FORM_CONSTANTS.SPECIALIZATION_OPTIONS.economics,
                ...FORM_CONSTANTS.SPECIALIZATION_OPTIONS.finance,
                ...FORM_CONSTANTS.SPECIALIZATION_OPTIONS.management,
              ].find((e) => e.value === form?.[FORM_MODEL.specialization.key])?.title}
            </span>
          </Box> */}
        </Grid>
      </Grid>
    </Fragment>
  );
}

export default ThongTinCaNhan;
