import React, { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Dialog,
  Container,
  CardContent,
  DialogContent,
  InputAdornment,
  OutlinedInput,
  DialogTitle,
  Typography,
  IconButton,
  Divider,
  Card,
  DialogActions,
  Button,
  CircularProgress,
} from '@mui/material';
import { useCookies } from 'react-cookie';
import { useNavigate } from 'react-router-dom';
import { Close, Visibility, VisibilityOff } from '@mui/icons-material';

import KPTextform from '../../../components/KPTComponents/KPTextform';
import DefaultLabel from '../../../components/CommonComponents/DefaultLabel';

import { useStyles } from "./styles";
import { ROUTER, USER_CONSTANTS } from '../../../constants';
import { KPTYPE } from '../../../components/KPTComponents/KPTextform/KPTextform';
import { userActions } from '../../../actions';

function DangNhap() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const loading = useSelector(state => state.userReducer.loading);
  const profile = useSelector(state => state.profileReducer.profile);

  const [cookies] = useCookies(['jwt']);
  const token = cookies['jwt'];
  
  const [values, setValues] = useState({ showPassword: false });
  const [openReset, setOpenReset] = useState(false);
  const [apiCalled, setApiCalled] = useState(null);
  const [resetValues, setResetValues] = useState(null);

  useEffect(() => {
    if (!!profile) {
      const { ROLE } = USER_CONSTANTS;
      if (profile?.userRole === ROLE.council || profile?.userRole === ROLE.super_admin) {
        navigate(ROUTER.DANH_SACH_HO_SO);
      } else {
        navigate(ROUTER.DANG_KY_HO_SO);
      }
    }
  }, [profile]);

  const textForm = useMemo(() => ([
    { key: 'username', xs: 12, label: 'Email', type: KPTYPE.email, required: true },
    {
      key: 'password',
      xs: 12,
      label: 'Mật khẩu',
      type: KPTYPE.render,
      required: true,
      labelRender: <React.Fragment />,
      render: (props) => (
        <React.Fragment>
          <DefaultLabel label='Mật khẩu' required={true} />
          <OutlinedInput
            fullWidth
            type={values.showPassword ? KPTYPE.text : 'password'}
            size='small'
            variant='outlined'
            placeholder='Mật khẩu'
            error={!!props.error ? true : undefined}
            value={props.value}
            onChange={(e) => props.handleChangeForm(props.id, e, props.type)}
            inputProps={{ style: { padding: 8 } }}
            endAdornment={
              <InputAdornment position='end'>
                <IconButton onClick={() => setValues({ ...values, showPassword: !values.showPassword })}>
                  {values.showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
              </InputAdornment>
            }
          />
        </React.Fragment>
      ),
    },
    { key: 'submit', xs: 12, label: 'Đăng nhập', labelRender: <React.Fragment />, type: 'submit' },
  ]), [values]);

  const resetForm = [{ key: 'email', xs: 12, label: 'Email cần khôi phục', required: true }];

  const handleSubmit = async () => {
    const payload = { username: values.username, password: values.password };
    dispatch(userActions.login(payload));
  };

  const handleCloseDialog = () => {
    setOpenReset(false);
  }
  
  return (
    <Box height='calc(100vh - 96px)' alignContent='center'>
      <Container maxWidth="sm">
        <Card style={{ backgroundColor: '#e7f5e5' }}>
          <CardContent>
            <Typography variant='h4' align='center'><b>Đăng nhập</b></Typography>
            <KPTextform
              textForm={textForm}
              values={values}
              setValues={setValues}
              handleSubmit={handleSubmit}
            />
          </CardContent>
        </Card>
        <Box display='flex' justifyContent='space-between' mt={2}>
          <Typography className={classes.link} onClick={() => navigate(ROUTER.DANG_KY)}>Đăng ký tài khoản mới</Typography>
          <Typography className={classes.link} align='right' onClick={() => setOpenReset(true)}>Quên mật khẩu?</Typography>
        </Box>
      </Container>
      <Dialog open={openReset} maxWidth='xs' fullWidth>
        <DialogTitle style={{ padding: '4px 12px 4px 24px' }}>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography variant='h5'>Khôi phục mật khẩu</Typography>
            <IconButton onClick={handleCloseDialog}><Close /></IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          {loading ? (
            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' padding='8px 32px' height={64}>
              <CircularProgress />
              <Typography style={{ textAlign: 'center' }}>
                Vui lòng đợi trong giây lát
              </Typography>
            </Box>
          ) : (
            apiCalled ? (
              <Box display='flex' justifyContent='center' alignItems='center' padding='8px 32px' height={64}>
                <Typography style={{ textAlign: 'center' }}>
                  {`Mật khẩu mới đã được gửi vào email: ${resetValues.email}!`}
                </Typography>
              </Box>
            ) : (
              apiCalled === null ? (
                <Box display='flex' justifyContent='center' alignItems='center' padding='8px 32px' height={64}>
                  <Typography style={{ textAlign: 'center' }}>
                    Nhập email của bạn tại đây và hệ thống sẽ gửi mật khẩu mới vào hòm thư của bạn!
                  </Typography>
                </Box>
              ) : (
                <Box display='flex' justifyContent='center' alignItems='center' padding='8px 32px' height={64}>
                  <Typography style={{ textAlign: 'center' }}>
                    Khôi phục mật khẩu thất bại!
                  </Typography>
                </Box>
              )
            )
          )}
          <KPTextform
            id='reset-f0rm'
            textForm={resetForm}
            values={resetValues}
            setValues={setResetValues}
            handleSubmit={() => {
              dispatch(userActions.resetByEmail(resetValues.email)).then((res) => setApiCalled(res));
            }}
          />
        </DialogContent>
        <DialogActions sx={{ px: 3 }}>
          <Button
            fullWidth
            variant='contained'
            type='submit'
            form='reset-f0rm'
            disabled={apiCalled}
          >
            Khôi phục mật khẩu
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
}

export default DangNhap;
