import React from 'react';

import KPTable from '../../../../components/KPTComponents/KPTable';

import { ActionColumn } from '../../../../containers/DangKyHoSo/utils';
import { TRAINEE_GUIDANCE_MODEL, CRUD_MODELS } from '../../../../constants';

function HuongDanTable({ rows, setDialog, ...props }) {
  const columns = [
    // { key: 'stt', width: '10%', title: 'STT', render: (_, row, id) => id + 1 },
    { key: 'orderNumber', width: '10%', title: 'STT' },
    { ...TRAINEE_GUIDANCE_MODEL.title, width: '30%' },
    {
      ...TRAINEE_GUIDANCE_MODEL.target,
      width: '20%',
      align: 'center',
      render: (value) => TRAINEE_GUIDANCE_MODEL.target.options.find((e) => e.value === value)?.title,
	  },
    {
      ...TRAINEE_GUIDANCE_MODEL.role,
      width: '20%',
      align: 'center',
      render: (value) => TRAINEE_GUIDANCE_MODEL.role.options.find((e) => e.value === value)?.title,
    },
    {
      key: 'link',
      label: 'Tác vụ',
      width: '20%',
      render: (_, row) => (
        <ActionColumn
          row={row}
          type={CRUD_MODELS.TRAINEE_GUIDANCE}
          setDialog={setDialog}
          setPreview={props.setPreview}
          setDeleteCrud={props.setDeleteCrud}
        />
      )
    }
  ];

  return (
    <KPTable
      columns={columns}
      nonBorder={!!props.nonBorder}
      rows={rows}
      // loading={loading}
    />
  );
}

export default HuongDanTable;
