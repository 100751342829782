import React from 'react';
import { useSelector } from 'react-redux';

import KPTextform from '../../../../components/KPTComponents/KPTextform';

import { KPTYPE } from '../../../../components/KPTComponents/KPTextform/KPTextform';
import { convertFeToBe } from '../../../../utils';
import { EDUCATION_PROGRESS_MODEL } from '../../../../constants';

function QuaTrinhDaoTaoForm(props) {
  const { disabled } = props;

  const form = useSelector((state) => state.formReducer.form);

  const textForm = [
    { ...EDUCATION_PROGRESS_MODEL.schoolYear, xs: 12  },
    // { ...EDUCATION_PROGRESS_MODEL.isLastThreeYear, labelRender: <></>, xs: 12 },
    { key: 'xx', label: 'Số lượng NCS đã hướng dẫn', type: KPTYPE.render, xs: 12, render: () => <></> },
    { ...EDUCATION_PROGRESS_MODEL.main, xs: 6 },
    { ...EDUCATION_PROGRESS_MODEL.other, xs: 6 },
    { ...EDUCATION_PROGRESS_MODEL.master, xs: 12 },
    { ...EDUCATION_PROGRESS_MODEL.bachelor, xs: 16 },
    { key: 'yy', label: 'Số giờ chuẩn gd trực tiếp trên lớp', type: KPTYPE.render, xs: 12, render: () => <></> },
    { ...EDUCATION_PROGRESS_MODEL.dh, xs: 6 },
    { ...EDUCATION_PROGRESS_MODEL.sdh, xs: 6 },
    { ...EDUCATION_PROGRESS_MODEL.dm, xs: 12 },
    { ...EDUCATION_PROGRESS_MODEL.proof, xs: 12, disabled },
  ];

  const handleSubmit = async () => {
    const converted = await convertFeToBe(props.values, EDUCATION_PROGRESS_MODEL, '/form/' + form.id);
    if (converted.error) {
      dispatch(userActions.notification({ message: converted.error, type: 'error' }));
      return;
    }
    const payload = converted.values;
    if (converted.values.proof) payload.proof = JSON.parse(converted.values.proof);
    props.handleSubmit(payload);
  }

  return <KPTextform {...props} textForm={textForm} handleSubmit={handleSubmit} />
}

export default QuaTrinhDaoTaoForm;
