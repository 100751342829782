import axios from "axios";

import { HEADERS } from "../constants";

export const getDoi = async (doi) => {
  const type1 = 'https://doi.org/';
  const type2 = 'doi.org/';

  var doiLink;
  if (doi?.startsWith(type1)) {
    doiLink = doi;
  } else if (doi?.startsWith(type2)) {
    doiLink = `https://${doi}`;
  } else {
    doiLink = `https://doi.org/${doi}`;
  }

  let response = null, error = null;

  try {
    response = await axios({ url: doiLink, method: 'GET', headers: HEADERS.DOI_HEADER });
  } catch (e) {
    error = e.request;
  }


  if (!error && response?.status === 200) {
    let responseJournal = null, errorJournal = null;

    try {
      responseJournal = await axios({
        url: process.env.REACT_APP_DOMAIN + '/api/v1/journal/filter',
        method: 'POST',
        data: {
          title: response?.data?.['container-title'].replace(/[&\\#,+()$~%.'":*?<>{}]/g, ''),
          year: parseInt(response?.data?.issued?.['date-parts']?.[0]?.[0]),
          page: 1,
          pageSize: 100,
        },
        headers: HEADERS.DEFAULT_HEADER,
      });
    } catch (e) {
      errorJournal = e.request;
    }


    if (!errorJournal && responseJournal?.status === 200) {
      const responseData = responseJournal?.data?.data;
      return ({
        success: true,
        message: 'OK',
        data: {
          info: response?.data,
          journal: responseData?.list?.[0]
        }
      });
    } else {
      return ({ success: false, message: 'Link DOI bị lỗi', data: null });
    }
  } else {
    return ({ success: false, message: 'Link DOI bị lỗi', data: null });
  }
};