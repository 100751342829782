import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';

import { KPTYPE } from '../../../../components/KPTComponents/KPTextform/KPTextform';
import { SCIENTIFIC_TASK_MODEL } from '../../../../constants';

import KPTextform from '../../../../components/KPTComponents/KPTextform';
import DefaultLabel from '../../../../components/CommonComponents/DefaultLabel';

function NhiemVuForm(props) {
  const { disabled } = props;

  const dOptions = useSelector((state) => state.formReducer.researchDirections?.map(
    (e, id) => ({ key: e.id, value: e.id, title: `[${id + 1}] ${e.title}` })
  )) || [];

  const textForm = [
    { ...SCIENTIFIC_TASK_MODEL.title, xs: 12, disabled },
    { ...SCIENTIFIC_TASK_MODEL.code, xs: 12, disabled },
    { ...SCIENTIFIC_TASK_MODEL.managementLevel, xs: 6, disabled },
    { ...SCIENTIFIC_TASK_MODEL.role, xs: 6, disabled },

    {
      key: 'labelx',
      xs: 12,
      labelRender: <DefaultLabel label='Thời gian thực hiện' />,
      type: KPTYPE.render,
      render: () => null,
    },
    { ...SCIENTIFIC_TASK_MODEL.startTime, xs: 5, labelRender: <></> },
    { key: 'to_0', xs: 2, labelRender: <Box />, type: KPTYPE.render, render: (props) => (
      <Box sx={{ height: 39, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography className='kpt-textfield-label'>đến</Typography>
      </Box>
    ) },
    { ...SCIENTIFIC_TASK_MODEL.endTime, xs: 5, labelRender: <></> },

    { ...SCIENTIFIC_TASK_MODEL.inspectTime, xs: 12, md: 5, required: true },
    { ...SCIENTIFIC_TASK_MODEL.phase, xs: 12, md: 7, disabled },
    { ...SCIENTIFIC_TASK_MODEL.researchDirectionId, xs: 12, type: KPTYPE.select, options: dOptions, disabled },
    { ...SCIENTIFIC_TASK_MODEL.result, xs: 12, disabled },
    { ...SCIENTIFIC_TASK_MODEL.proof, xs: 12, disabled },
  ];

  return <KPTextform {...props} textForm={textForm} />
}

export default NhiemVuForm;
