export const ROUTER = {
  HOME: '/',
  CHI_TIET_HO_SO: '/chi-tiet-ho-so',
  DANG_KY: '/dang-ky',
  CAU_HINH: '/cau-hinh',
  DANG_NHAP: '/dang-nhap',
  // GIOI_THIEU: '/gioi-thieu',
  DOI_MAT_KHAU: '/doi-mat-khau',
  DANG_KY_HO_SO: '/dang-ky-ho-so',
  DANH_SACH_HO_SO: '/danh-sach-ho-so',
  QUAN_LY_NGUOI_DUNG: '/quan-ly-nguoi-dung',
  PHAN_QUYEN_HOI_DONG: '/phan-quyen-ho-dong',
  DANH_SACH_THANH_TICH: '/danh-sach-thanh-tich',
}

export const ROUTER_CONSTANTS = {
  NAV_ITEMS: [
    // { key: 'gioi-thieu', title: 'Giới thiệu', to: ROUTER.GIOI_THIEU },
    { key: 'dang-ky-ho-so', title: 'Đăng ký hồ sơ', to: ROUTER.DANG_KY_HO_SO },
  ],
  COUNCIL_ITEM: [
    { key: 'cham-diem', title: 'Chấm điểm', to: ROUTER.DANH_SACH_HO_SO },
  ],
  ADMIN_DROPDOWN: [
    { key: 'cau-hinh', title: 'Cấu hình Bảng tính điểm', to: ROUTER.CAU_HINH },
    { key: 'danh-sach-tai-khoan', title: 'Quản lý người dùng', to: ROUTER.QUAN_LY_NGUOI_DUNG },
    { key: 'phan-quyen-ho-dong', title: 'Phân quyền hội đồng', to: ROUTER.PHAN_QUYEN_HOI_DONG },
    { key: 'danh-sach-ho-so', title: 'Danh sách hồ sơ', to: ROUTER.DANH_SACH_HO_SO },
  ],
}
