import React from 'react';

import KPTable from '../../../../components/KPTComponents/KPTable';

import { ActionColumn } from '../../../../containers/DangKyHoSo/utils';
import { CRUD_MODELS, EDUCATE_DEGREE_MODEL } from '../../../../constants';

function BangCapTable({ rows, setDialog, ...props }) {
  const columns = [
    {
      ...EDUCATE_DEGREE_MODEL.degreeType,
      width: '30%',
      render: (value) => EDUCATE_DEGREE_MODEL.degreeType.options.find((e) => e.value === value)?.title,
    },
    { ...EDUCATE_DEGREE_MODEL.major },
    { ...EDUCATE_DEGREE_MODEL.specialization },
    {
      key: 'link',
      label: 'Tác vụ',
      width: '20%',
      render: (_, row) => (
        <ActionColumn
          row={row}
          type={CRUD_MODELS.EDUCATE_DEGREE}
          setDialog={setDialog}
          setPreview={props.setPreview}
          setDeleteCrud={props.setDeleteCrud}
        />
      )
    }
  ];

  return (
    <KPTable
      columns={columns}
      nonBorder={!!props.nonBorder}
      rows={rows}
      // loading={loading}
    />
  );
}

export default BangCapTable;
