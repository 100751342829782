import { apiCall, convertBeToFe } from "../utils";
import { userActions, formActions } from "../actions";
import { SCORING_TABLE_API, SCORING_TABLE_TYPE } from "../constants";

const { notification } = userActions;

export const scoringTableActions = { insert, update, remove };

function insert(payload) {
  return async(dispatch) => {
    dispatch(loading());

    const api = SCORING_TABLE_API.insert();
    const { response, error } = await apiCall({ ...api, payload });

    if (!error && response.status === 200) {
      dispatch(success({ ...payload, id: response.data.data }));
      dispatch(notification({ message: 'Thêm thông tin thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Thêm thông tin thất bại', type: 'error' }));
    }
  };

  function loading() { return { type: SCORING_TABLE_TYPE.INSERT_SCORING_TABLE_LOADING } }
  function success(payload) { return { type: SCORING_TABLE_TYPE.INSERT_SCORING_TABLE_SUCCESS, payload } }
  function failure(error) { return { type: SCORING_TABLE_TYPE.INSERT_SCORING_TABLE_FAILURE, error } }
}

function update(payload) {
  return async(dispatch) => {
    dispatch(loading());

    const api = SCORING_TABLE_API.update();
    const { response, error } = await apiCall({ ...api, payload });

    if (!error && response.status === 200) {
      dispatch(success(payload));
      dispatch(notification({ message: 'Cập nhật thông tin thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Cập nhật thông tin thất bại', type: 'error' }));
    }
  };

  function loading() { return { type: SCORING_TABLE_TYPE.UPDATE_SCORING_TABLE_LOADING } }
  function success(payload) { return { type: SCORING_TABLE_TYPE.UPDATE_SCORING_TABLE_SUCCESS, payload } }
  function failure(error) { return { type: SCORING_TABLE_TYPE.UPDATE_SCORING_TABLE_FAILURE, error } }

  // function loading() { return { type: crudActionType(UPDATE, model, LOADING) } }
  // function success(payload) { return { type: crudActionType(UPDATE, model, SUCCESS), payload } }
  // function failure(error) { return { type: crudActionType(UPDATE, model, FAILURE), error } }
}

function remove(model, id) {
  return async(dispatch) => {
    dispatch(loading());

    const api = SCORING_TABLE_API.delete(model, id);
    const { response, error } = await apiCall(api);

    if (!error && response.status === 200) {
      dispatch(success(id));
      dispatch(notification({ message: 'Xóa dữ liệu thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Xóa dữ liệu thất bại', type: 'error' }));
    }
  };

  function loading() { return { type: SCORING_TABLE_TYPE.DELETE_SCORING_TABLE_LOADING } }
  function success(id) { return { type: SCORING_TABLE_TYPE.DELETE_SCORING_TABLE_SUCCESS, id } }
  function failure(error) { return { type: SCORING_TABLE_TYPE.DELETE_SCORING_TABLE_FAILURE, error } }
}