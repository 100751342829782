import { KPTYPE } from "../components/KPTComponents/KPTextform/KPTextform";
import { AUTHOR_BASE_MODEL, SR_BASE_MODEL } from "./scienctificResearch.constants";

export const SOLUTION_CONSTANTS = {
  ROLE_OPTIONS: [
    { key: 'main', title: 'Tác giả chính', value: 'main' },
    { key: 'member', title: 'Đồng tác giả', value: 'member' },
  ],
  TYPE_OPTIONS: [
    { key: 'solution', title: 'Sáng chế', value: 'solution' },
    { key: 'patent', title: 'Giải pháp hữu ích', value: 'patent' },
  ],
};

export const SOLUTION_MODEL = {
  ...SR_BASE_MODEL,
  ...AUTHOR_BASE_MODEL,
  type: { key: 'type', label: 'Phân loại', type: KPTYPE.select, options: SOLUTION_CONSTANTS.TYPE_OPTIONS, required: true },
  title: { key: 'title', label: 'Tên bằng độc quyền sáng chế, giải pháp hữu ích', required: true },
  organizationName: { key: 'organizationName', label: 'Tên cơ quan cấp' },
  issueDate: { key: 'issueDate', label: 'Ngày cấp', type: KPTYPE.date },
  role: { key: 'role', label: 'Vai trò', type: KPTYPE.select, options: SOLUTION_CONSTANTS.ROLE_OPTIONS },
};

