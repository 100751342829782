import { apiCall } from '../utils';
import { COUNCIL_API, COUNCIL_TYPE, FILE_API, FILE_TYPE, FORM_TYPE } from '../constants';

import { userActions } from '.';
const { notification } = userActions;


export const councilActions = {
  filter,
  insert,
  update,
  // getApplicationFormById,
};

function filter(payload) {
  return async(dispatch) => {
    dispatch(loading());

    const api = COUNCIL_API.filter();
    const { response, error } = await apiCall({ ...api, payload });

    if (!error && response.status === 200) {
      dispatch(success(response.data.data));
      dispatch(userActions.notification({ message: 'Lấy dữ liệu thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(userActions.notification({ message: 'Lấy dữ liệu thất bại', type: 'error' }));
    }
  };

  function loading() { return { type: COUNCIL_TYPE.FILTER_COUNCIL_LOADING } }
  function success(payload) { return { type: COUNCIL_TYPE.FILTER_COUNCIL_SUCCESS, payload } }
  function failure(error) { return { type: COUNCIL_TYPE.FILTER_COUNCIL_FAILURE, error } }
}

function insert(payload) {
  return async(dispatch) => {
    dispatch(loading());

    const api = COUNCIL_API.insert();
    const { response, error } = await apiCall({ ...api, payload });

    if (!error && response.status === 200) {
      dispatch(success({ payload, id: response.data.data }));
      dispatch(userActions.notification({ message: 'Cập nhật dữ liệu thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(userActions.notification({ message: 'Cập nhật dữ liệu thất bại', type: 'error' }));
    }
  };

  function loading() { return { type: COUNCIL_TYPE.INSERT_COUNCIL_LOADING } }
  function success(payload) { return { type: COUNCIL_TYPE.INSERT_COUNCIL_SUCCESS, payload } }
  function failure(error) { return { type: COUNCIL_TYPE.INSERT_COUNCIL_FAILURE, error } }
}

function update(payload) {
  return async(dispatch) => {
    dispatch(loading());

    const api = COUNCIL_API.update();
    const { response, error } = await apiCall({ ...api, payload });

    if (!error && response.status === 200) {
      dispatch(success(payload));
      dispatch(userActions.notification({ message: 'Cập nhật dữ liệu thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(userActions.notification({ message: 'Cập nhật dữ liệu thất bại', type: 'error' }));
    }
  };

  function loading() { return { type: COUNCIL_TYPE.UPDATE_COUNCIL_LOADING } }
  function success(payload) { return { type: COUNCIL_TYPE.UPDATE_COUNCIL_SUCCESS, payload } }
  function failure(error) { return { type: COUNCIL_TYPE.UPDATE_COUNCIL_FAILURE, error } }
}

// function getApplicationFormById(id) {
//   return async(dispatch) => {
//     dispatch(loading());

//     const api = COUNCIL_API.getApplicationFormById(id);
//     const { response, error } = await apiCall(api);

//     if (!error && response.status === 200) {
//       dispatch(success(response.data.data));
//       dispatch(notification({ message: 'Lấy hồ sơ thành công', type: 'success' }));
//       return true;
//     } else {
//       dispatch(failure(error));
//       dispatch(notification({ message: 'Lấy hồ sơ thất bại', type: 'error' }));
//       return false;
//     }
//   };

//   function loading() { return { type: FORM_TYPE.GET_APPLICATION_FORM_BY_ID_LOADING } }
//   function success(payload) { return { type: FORM_TYPE.GET_APPLICATION_FORM_BY_ID_SUCCESS, payload } }
//   function failure(error) { return { type: FORM_TYPE.GET_APPLICATION_FORM_BY_ID_FAILURE, error } }
// }
