import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Button, Typography } from '@mui/material';

import tnt from '../../assets/image/home/quoc-huy.png';
import styles from './index.module.css';
import { ROUTER, USER_CONSTANTS } from '../../constants'; 
import { useScreenSize } from '../../hooks/useScreenSize';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';

const SLOGAN = 'Chào mừng quý vị đến với hệ thống đăng ký hồ sơ xét công nhận đạt tiêu chuẩn chức danh GS/PGS năm 2024';

function Home() {
  const navigate = useNavigate();
  const isMobileMode = useScreenSize();
  
  const profile = useSelector(state => state.profileReducer.profile);
  
  const [cookies] = useCookies(['cookieName']);
  const [textLength, setTextLength] = useState(-10);

  const token = cookies.jwt;

  const anim = setTimeout(() => {
    if (textLength < SLOGAN.length) setTextLength(textLength + 1);
    else clearTimeout(anim);
  }, 35);

  useEffect(() => {
    if (!!profile) {
      const { ROLE } = USER_CONSTANTS;
      if (profile?.userRole === ROLE.council || profile?.userRole === ROLE.super_admin) {
        navigate(ROUTER.DANH_SACH_HO_SO);
      } else {
        navigate(ROUTER.DANG_KY_HO_SO);
      }
    }
  }, [profile]);

  if (token) return null;

  return (
    <Grid
        container
        spacing={4}
        alignContent="center"
        justifyContent="center"
        style={{ minHeight: '100vh' }}
      >
      <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
        <img alt='logo' src={tnt} style={{ height: 180 }} />
      </Grid>
      <Grid item xs={12}>
        <Box color='#fff'>
          <Typography variant={isMobileMode ? 'h4' : 'h3'} className={styles.title}>
            <b>HỘI ĐỒNG GIÁO SƯ NHÀ NƯỚC NGÀNH KINH TẾ</b>
          </Typography>
          <Typography variant={isMobileMode ? 'h5' : 'h4'} className={styles.textChay}>
            {textLength > 0 ? SLOGAN.substr(0, textLength) : <>&nbsp;</>}
          </Typography>
        </Box>
      </Grid>
      <Grid item xs={10} md={2}>
        <Button className={styles.button} href={ROUTER.DANG_KY} fullWidth variant='contained' color='secondary'>Đăng ký</Button>
      </Grid>
      <Grid item xs={10} md={2}>
        <Button className={styles.button} href={ROUTER.DANG_NHAP} fullWidth variant='contained' color='secondary'>Đăng nhập</Button>
      </Grid>
      <Box className={styles.mask} />
    </Grid>
  );
}

export default Home;
