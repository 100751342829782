// export const JOURNAL_DOMESTIC_CONSTANTS = {
// };

import { HEADERS } from "./headers"

// export const JOURNAL_DOMESTIC_MODEL = {
// };

export const JOURNAL_DOMESTIC_TYPE = {
  FILTER_JOURNAL_DOMESTIC_LOADING: 'FILTER_JOURNAL_DOMESTIC_LOADING',
  FILTER_JOURNAL_DOMESTIC_SUCCESS: 'FILTER_JOURNAL_DOMESTIC_SUCCESS',
  FILTER_JOURNAL_DOMESTIC_FAILURE: 'FILTER_JOURNAL_DOMESTIC_FAILURE',
}

export const JOURNAL_DOMESTIC_API = {
  filter: () => ({
    endPoint: '/api/v1/journalDomestic/filter',
    headers: HEADERS.DEFAULT_HEADER,
    method: 'POST',
  }),
}