import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Button, Typography, Box, Divider } from '@mui/material';
import { USER_CONSTANTS } from '../../../constants';
import KPTable from '../../../components/KPTComponents/KPTable';
import PopupMenu from '../../../components/CommonComponents/PopupMenu';
import KPTextform from '../../../components/KPTComponents/KPTextform';
import { userActions } from '../../../actions';
import { KPTYPE } from '../../../components/KPTComponents/KPTextform/KPTextform';
import { ReplayOutlined } from '@mui/icons-material';




function QuanLyNguoiDung(props) {
  const dispatch = useDispatch();

	// const role = useSelector(state => state.userReducer.user?.role);
  const filterData = useSelector((state) => state.userReducer.filterData);
  // const openSeasonId = useSelector(state => state.seasonReducer.openSeasonId);

  // const isSuperAdmin = role === 'super_admin' || role?.includes('qcv_admin_');

  const [values, setValues] = useState(null);
  const [modifyMenuAnchor, setModifyMenuAnchor] = useState(false);

  const handleReload = () => {
    dispatch(userActions.filter({ page: 1, pageSize: 10000 }));
  };

  // useEffect(() => {
  //   if (openSeasonId) dispatch(formActions.thongKe(openSeasonId));
  // }, [openSeasonId]);

  useEffect(() => {
    handleReload();
  }, []);

  // var newAllUserList = allUserList?.filter((e) => e.profile?.email && e.profile?.name);
  // newAllUserList = newAllUserList?.map((e) => ({
  //   ...e,
  //   name: e.profile?.name,
  //   email: e.profile?.email,
  // }));

  const openModifyMenu = (e, id) => {
    setValues({ ...values, userID: id });
    setModifyMenuAnchor(e.currentTarget);
  };

  const closeModifyMenu = () => {
    setValues(null);
    setModifyMenuAnchor(null);
  };

  const handleChangeRole = () => {
    dispatch(userActions.changeRole(values));
    closeModifyMenu();
  };

  var columns = [
    { title: <b>STT</b>, dataIndex: 'id', width: '5%', render: (_, row, id) => id + 1 },
    { title: <b>Người dùng</b>, dataIndex: 'profile', width: '20%', render: (value) => value.name },
    { title: <b>Email</b>, dataIndex: 'username', width: '30%' },
    {
      title: <b>Chức vụ</b>,
      dataIndex: 'role',
      width: '20%',
      render: (value) => USER_CONSTANTS.ROLE_OPTIONS.find((e) => value === e.value)?.title,
    },
    {
      title: <b>Tác vụ</b>,
      dataIndex: 'cc-actions',
      width: '15%',
      render: (_, row) => (
        <Typography
          variant='body2'
          color='primary'
          className='action-btn'
          onClick={(e) => {
            openModifyMenu(e, row.id);
            setValues({ role: row.role, userId: row.id });
          }}
        >
          Cấp Quyền
        </Typography>
      ),
    },
  ];

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 2 }}>
        <Typography variant='h5'><b>Quản lý hồ sơ</b></Typography>
        <Button variant='outlined' color='inherit' startIcon={<ReplayOutlined />} onClick={handleReload}>Tải lại</Button>
      </Box>
      <KPTable columns={columns} rows={filterData?.list.sort((a, b) => a.id - b.id) || []} />

      <PopupMenu
        position='center'
        anchorEl={modifyMenuAnchor}
        handleClose={closeModifyMenu}
        render={
          <div style={{ padding: 8, width: 320, minWidth: 320, maxWidth: 320 }}>
            <Typography style={{ paddingBottom: 16 }}>Cấp quyền cho người dùng này?</Typography>
            <KPTextform
              {...props}
              values={values}
              setValues={setValues}
              textForm={[{ key: 'role', xs: 12, type: KPTYPE.select, options: USER_CONSTANTS.ROLE_OPTIONS, }]}
            />
            <Button
              variant='outlined'
              color='secondary'
              style={{ width: 'calc(50% - 4px)', marginTop: '10px' }}
              onClick={closeModifyMenu}
            >
              Huỷ
            </Button>
            <Button
              variant='contained'
              color='primary'
              style={{
                marginLeft: 8,
                width: 'calc(50% - 4px)',
                marginTop: '10px',
              }}
              onClick={handleChangeRole}
            >
              Xác nhận
            </Button>
          </div>
        }
      />
    </>
  );
}
export default QuanLyNguoiDung;
