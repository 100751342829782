import dayjs from 'dayjs';

const sentenceCase = (input) => {
  const sentence = input
    ?.replace(/[-_]([A-Za-z])/g, ' $1')
    ?.replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
    ?.replace(/([a-z])([A-Z])/g, '$1 $2')
    ?.toLowerCase()

  return upperCaseFirst(sentence)
}

export const dateFormatter = (value) => {
  const dateForm = value?.replaceAll('/', '');
  const date = dateForm?.substr(0, 2);
  const month = dateForm?.substr(2, 2);
  const year = dateForm?.substr(-4);
  return(value ? dayjs(`${month}/${date}/${year}`).format('YYYY-MM-DDTHH:mm:ssZ') : null);
}

export const numRender = (num) => {
  if (num >= 10) return num;
  return `0${num}`;
}

export const upperCaseFirst = (input) => input?.charAt(0)?.toUpperCase() + input.substring(1)

export const toSnakeCase = (input) => sentenceCase(input).replace(/\s/g, '_')