import React from 'react';

import KPTextform from '../../../../components/KPTComponents/KPTextform';
import { AWARD_MODEL } from '../../../../constants';

function TacPhamForm(props) {
  const { disabled } = props;

  const textForm = [
    { ...AWARD_MODEL.title, xs: 12, disabled },
    { ...AWARD_MODEL.organizationName, xs: 12, disabled },
    { ...AWARD_MODEL.recognitionDocument, xs: 12, disabled },
    { ...AWARD_MODEL.issueDate, xs: 12, disabled },
    { ...AWARD_MODEL.level, xs: 6, disabled },
    { ...AWARD_MODEL.phase, xs: 6, disabled },
    { ...AWARD_MODEL.role, xs: 6, disabled },
    { ...AWARD_MODEL.authorNumber, xs: 6, disabled },
    { ...AWARD_MODEL.proof, xs: 12, disabled },
  ];

  return <KPTextform {...props} textForm={textForm} />
}

export default TacPhamForm;
