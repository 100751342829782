import React, { useMemo, useState } from 'react';
import { Box, Button, Divider, Grid, Typography } from '@mui/material';
import { CRUD_LABELS, CRUD_MODELS } from '../../../constants/crud.constants';

import PopupMenu from '../../CommonComponents/PopupMenu';
import SachTable from '../../../containers/Crud/Sach/KyLuatTable';
import DeTaiTable from '../../../containers/Crud/DeTai/DeTaiTable';
import BaiBaoTable from '../../../containers/Crud/BaiBao/BaiBaoTable';
import KyLuatTable from '../../../containers/Crud/KyLuat/KyLuatTable';
import BangCapTable from '../../../containers/Crud/BangCap/BangCapTable';
import TacPhamTable from '../../../containers/Crud/TacPham/TacPhamTable';
import SangCheTable from '../../../containers/Crud/SangChe/SangCheTable';
import NhiemVuTable from '../../../containers/Crud/NhiemVu/NhiemVuTable';
import HuongDanTable from '../../../containers/Crud/HuongDan/HuongDanTable';
import KhenThuongTable from '../../../containers/Crud/KhenThuong/KhenThuongTable';
import ChuongTrinhTable from '../../../containers/Crud/ChuongTrinh/ChuongTrinhTable';

const DEFAULT_DELETE_CRUD_STATE = {
  anchorEl: null,
  handleDelete: null,
}

function CrudTable({
  type,
  rows,
  setDialog,
  filterType,
  setPreview,
  setDuyetThanhTich
}) {
  const [deleteCrud, setDeleteCrud] = useState({ ...DEFAULT_DELETE_CRUD_STATE });

  const closeDeleteMenu = () => {
    setDeleteCrud({ ...DEFAULT_DELETE_CRUD_STATE });
  }

  const tableMapComponent = useMemo(() => {
    const tableProps = { rows, setDialog, setDuyetThanhTich, setPreview, setDeleteCrud, nonBorder: true };

    const tableMap = {
      [CRUD_MODELS.AWARD]: <TacPhamTable { ...tableProps } />,
      [CRUD_MODELS.REWARD]: <KhenThuongTable { ...tableProps } />,
      [CRUD_MODELS.PROGRAM]: <ChuongTrinhTable { ...tableProps } />,
      [CRUD_MODELS.SOLUTION]: <SangCheTable { ...tableProps } />,
      [CRUD_MODELS.MONOGRAPH]: <SachTable { ...tableProps } />,
      [CRUD_MODELS.DISCIPLINE]: <KyLuatTable { ...tableProps } />,
      [CRUD_MODELS.EDUCATE_DEGREE]: <BangCapTable { ...tableProps } />,
      [CRUD_MODELS.SCIENTIFIC_TASK]: <NhiemVuTable { ...tableProps } />,
      [CRUD_MODELS.RESEARCH_PROJECT]: <DeTaiTable { ...tableProps } />,
      [CRUD_MODELS.TRAINEE_GUIDANCE]: <HuongDanTable { ...tableProps } />,
      [CRUD_MODELS.SCIENTIFIC_RESEARCH]: <BaiBaoTable { ...tableProps } filterType={filterType} />,
    }

    if (!type || !tableMap.hasOwnProperty(type)) return null;
    return tableMap[type];
  }, [rows, setDialog, filterType, type]);

  return (
    <>
      {tableMapComponent}

      <PopupMenu
        position='center'
        anchorEl={deleteCrud.anchorEl}
        handleClose={closeDeleteMenu}
        render={
          <>
            <Box p={2} width={320} maxWidth={320}>
              <Typography align='center'>
                Xác nhận xoá <b>{CRUD_LABELS?.[type]}</b>?
              </Typography>
            </Box>
            <Divider />
            <Grid container spacing={2} sx={{ p: 2 }}>
              <Grid item xs={6}>
                <Button fullWidth variant='outlined' onClick={closeDeleteMenu}>
                  Huỷ
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Button fullWidth variant='contained' color='primary' onClick={() => {
                  deleteCrud.handleDelete();
                  closeDeleteMenu();
                }}>
                  Xác nhận
                </Button>
              </Grid>
            </Grid>
          </>
        }
      />
    </>
  );
}

export default CrudTable;
