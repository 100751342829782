import { validEmail } from "../../../utils";
import { KPTYPE } from "./KPTextform";

export const empty = (type) => {
  switch (type) {
    case KPTYPE.int:
    case KPTYPE.float:
      return '';
    case KPTYPE.date:
      return 'dd/mm/yyyy';
    case KPTYPE.dropzone:
      return [];
    case KPTYPE.richtext:
      return ' ';
    case KPTYPE.checkbox:
      return false;
    default:
      return '';
  }
}

const getValueFromObj = (values, key) => {
  const keys = key.split('.');
  var temp = {...values};
  keys.forEach((k) => temp = temp?.[k]);
  return temp;
}

export const getValue = (values, elm) => {
  return [elm.value, getValueFromObj(values, elm.key), empty(elm.type)].find((item) => (item != undefined && item != null));
}

export const getError = (values, elm) => {
  if (!elm.disabled && elm.required) {
    if (elm.type === KPTYPE.dropzone) {
      if (getValue(values, elm).length === 0) {
        return `Không được để trống thông tin về: ${elm.label}`;
      }
    } else {
      if (getValue(values, elm) === empty(elm.type)) {
        return `Không được để trống thông tin về: ${elm.label}`;
      }
    }
  }

  if (elm.type === KPTYPE.email && !validEmail(getValue(values, elm))) {
    return `${elm.label} không đúng định dạng`;
  }
}