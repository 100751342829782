import { createTheme } from '@mui/material/styles';
import { PRIMARY_COLOR, SECONDARY_COLOR } from './constants/enums';
import { COLOR_PALETTE } from './constants/enums';


// Create a theme instance.
const theme = createTheme({
  palette: {
    primary: {
      main: COLOR_PALETTE.PRIMARY,
    },
    secondary: {
      main: COLOR_PALETTE.SECONDARY,
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        input: {
          backgroundColor: '#fff',
          ':disabled': {
            backgroundColor: 'rgba(239, 239, 239, 0.5)'
          }
        },
      }
    },
    MuiSelect: {
      styleOverrides: {
        select: {
          "&.Mui-disabled": {
            backgroundColor: 'rgba(239, 239, 239, 0.5)'
          },
        },
      }
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          boxShadow: 'none',
          border: '1px solid rgba(0,0,0,0.25)',
        }
      }
    },
  },
  typography: {
    fontFamily: 'SFProDisplay'
  },
});

export default theme;
