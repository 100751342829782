import React from 'react';

import KPTextform from '../../../../components/KPTComponents/KPTextform';
import { RESEARCH_PROJECT_MODEL } from '../../../../constants';

function DeTaiForm(props) {
  const { disabled } = props;

  const textForm = [
    { ...RESEARCH_PROJECT_MODEL.title, xs: 12, disabled },
    { ...RESEARCH_PROJECT_MODEL.role, xs: 12, disabled },
    { ...RESEARCH_PROJECT_MODEL.taskAssignmentDocument, xs: 12, disabled },
    { ...RESEARCH_PROJECT_MODEL.assessmentAgency, xs: 12, disabled },
    { ...RESEARCH_PROJECT_MODEL.usageDocument, xs: 12, disabled },
    { ...RESEARCH_PROJECT_MODEL.note, xs: 12, disabled },
    { ...RESEARCH_PROJECT_MODEL.proof, xs: 12, disabled },
  ];

  return <KPTextform {...props} textForm={textForm} />
}

export default DeTaiForm;
