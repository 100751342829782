import { KPTYPE } from '../components/KPTComponents/KPTextform/KPTextform';
import { PHASE_OPTIONS } from './enums';

export const SR_BASE_MODEL = {
  phase: { key: 'phase', label: 'Giai đoạn (Trước/Sau TS/PGS)', type: KPTYPE.select, options: PHASE_OPTIONS, required: true },
  note: { key: 'note', label: 'Ghi chú' },
  proof: { key: 'proof', label: 'Minh chứng', type: KPTYPE.dropzone },
}

export const AUTHOR_BASE_MODEL = {
  numberAuthor: { key: 'numberAuthor', label: 'Số tác giả', type: KPTYPE.int, required: true },
  numberMain: { key: 'numberMain', label: 'Số tác giả chính', type: KPTYPE.int },
  author: { key: 'author', label: 'Danh sách tác giả', type: KPTYPE.textarea },
  role: { key: 'role', label: 'Vai trò', required: true },
}

export const SCIENTIFIC_RESEARCH_CONSTANTS = {
  FILTER_TYPE: [
    { key: 'international', value: 'international', title: 'Bài báo đăng trên Tạp chí Quốc tế uy tín' },
    { key: 'other', value: 'other', title: 'Bài báo đăng trên Tạp chí Quốc tế khác' },
    { key: 'conference', value: 'conference', title: 'Bài báo đăng trên Kỷ yếu Hội thảo' },
    { key: 'domestic', value: 'domestic', title: 'Bài báo đăng trên Tạp chí trong nước' },
  ],
  TYPE_OPTIONS: [
    { key: 'q1', title: 'Q1', value: 'q1' },
    { key: 'q2', title: 'Q2', value: 'q2' },
    { key: 'q3', title: 'Q3', value: 'q3' },
    { key: 'q4', title: 'Q4', value: 'q4' },
    { key: 'scopus', title: 'Scopus', value: 'scopus' },
    { key: 'q5', title: 'Q5', value: 'q5' },
    { key: 'q6', title: 'Q6', value: 'q6' },
  ],
  ROLE_OPTIONS: [
    { key: 'main', title: 'Tác giả chính (Tác giả đầu, tác giả liên hệ)', value: 'main' },
    { key: 'member', title: 'Đồng tác giả', value: 'member' },
  ],
  CORE_COLECTION_OPTIONS: [
    { key: 'SCIE', title: 'SCIE' },
    { key: 'SSCI', title: 'SSCI' },
    { key: 'ESCI', title: 'ESCI' },
    { key: 'AHCI', title: 'AHCI' },
    { key: 'scopus', title: 'Scopus' },
  ],
  BELONG_TO_OPTIONS: [
    { key: 'aci', title: 'Thuộc ACI (Tối đa 1.25 điểm)', value: 'aci' },
    { key: 'economic', title: 'Không thuộc danh mục ISSI/Scopus, nhưng được xuất bản bởi các nhà xuất bản: Springer, Elsevier, Wley- Blackwell, Taylor&Francis, Sage, Oxford University Press, Cambridge University Press, Emerald, Macmillan Publishers, Edward Elgar Publishing, De Gruyter, World Scientific, Routledge, Peter Lang, Thomson Reuters, McGraw Hill (Tối đa 1.0 điểm)', value: 'economic' },
    { key: 'top500', title: 'Thuộc Top 500 Trường ĐH hàng đầu thế giới do Times Higher Education (THE) hợp tác với Thomson Reuters bình chọn, xếp hạng hàng năm (Tối đa 1.0 điểm)', value: 'top500' },
    { key: 'other', title: 'Khác (do HDGS ngành đánh giá và quyết định)', value: 'other' },
  ]
};

export const SCIENTIFIC_RESEARCH_MODEL = {
  ...SR_BASE_MODEL,
  ...AUTHOR_BASE_MODEL,
  role: { key: 'role', label: 'Vai trò', type: KPTYPE.select, options: SCIENTIFIC_RESEARCH_CONSTANTS.ROLE_OPTIONS, required: true },
  doi: { key: 'doi', label: 'Link DOI' },
  jif: { key: 'jif', label: 'Impact factor' },
  filterType: { key: 'filterType', label: 'Phân loại', type: KPTYPE.select, options: SCIENTIFIC_RESEARCH_CONSTANTS.FILTER_TYPE, required: true },
  page: { key: 'page', label: 'Trang' },
  issn: { key: 'issn', label: 'ISSN/ISBN', required: true },
  type: { key: 'type', label: 'Hạng Q (Năm xuất bản)', required: true },
  issue: { key: 'issue', label: 'Số' },
  title: { key: 'title', label: 'Tên công bố', type: KPTYPE.textarea, required: true },
  volume: { key: 'volume', label: 'Tập', required: true },
  totalRefs: { key: 'totalRefs', label: 'Số trích dẫn của bài báo', type: KPTYPE.int },
  
  publisher: { key: 'publisher', label: 'Nhà xuất bản', required: true },
  publishedYear: { key: 'publishedYear', label: 'Năm xuất bản', required: true },
  publishedMonth: { key: 'publishedMonth', label: 'Tháng xuất bản' },
  
  belongTo: { key: 'belongTo', label: 'Bài báo thuộc hạng mục nào', type: KPTYPE.radio, options: SCIENTIFIC_RESEARCH_CONSTANTS.BELONG_TO_OPTIONS },
  
  journalId: { key: 'journalId', label: 'Tên tạp chí/kỷ yếu khoa học', required: true },
  journalTitle: { key: 'journalTitle', label: 'Tên tạp chí/kỷ yếu khoa học', required: true },
  journalHIndex: { key: 'journalHIndex', label: 'H-index (Năm xuất bản)' },
  journalCountry: { key: 'journalCountry', label: 'Tạp chí thuộc Nước nào?' },
  journalDomesticId: { key: 'journalDomesticId', label: 'Tên tạp chí khoa học trong nước', required: true },
  journalCoreCollection: { key: 'journalCoreCollection', label: 'Phân loại tạp chí' },

  researchDirectionId: { key: 'researchDirectionId', label: 'Hướng nghiên cứu' },
};

