import React, { useState } from 'react';
import {
  Box,
  Button,
  IconButton,
  Typography,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { fileRender } from '../../../utils';

function PreviewModal(props) {
  const [id, setId] = useState(0);

  const handleClose = () => {
    setId(0);
    props.handleClose();
  }

  const handleChangeId = (num) => {
    const newId = id + num;
    if (newId < props.url?.length && newId >= 0) setId(newId);
  }

  return (
    <Dialog fullWidth maxWidth='md' open={props.open}>
      <DialogTitle style={{ padding: '4px 12px 4px 24px' }}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='h5'>File minh chứng</Typography>
          <IconButton onClick={handleClose}><Close /></IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        {props.url?.[id] && (
          <iframe
            src={fileRender(props.url[id])}
            style={{ minHeight: '50vh', width: 'calc(100% - 4px)', overflow: 'auto' }}
          />
        )}
      </DialogContent>
      <DialogActions>
        <Grid container>
          <Grid item xs={4}>
            <Typography style={{ lineHeight: '36px' }}>Minh chứng: {`${id + 1}/${props.url?.length}`}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Box display='flex' justifyContent='center'>
              <Button
                target='_blank'
                rel='noreferrer'
                href={props.url?.[id] ? fileRender(props.url[id]) : null}
                variant='outlined'
              >
                Mở bản gốc
              </Button>
            </Box>
          </Grid>
          <Grid item xs={4}>
            <Box display='flex' justifyContent='flex-end'>
              <Button color='secondary' variant='contained' onClick={() => handleChangeId(-1)} style={{ marginRight: 8 }}>Trước</Button>
              <Button variant='contained' onClick={() => handleChangeId(1)}>Sau</Button>
            </Box>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}

export default PreviewModal;
