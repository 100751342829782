import { convertBeToFe } from '../utils';
import { PROFILE_MODEL, PROFILE_TYPE, USER_TYPE } from '../constants';

const initialState = {
  profile: null,
  loading: false,
}

export function profileReducer(state = initialState, action) {
  switch (action.type) {
    case PROFILE_TYPE.UPDATE_PROFILE_LOADING:
      return { ...state, loading: true };

    case PROFILE_TYPE.UPDATE_PROFILE_FAILURE:
      return { ...state, loading: false };

    case USER_TYPE.LOGIN_SUCCESS:
    case PROFILE_TYPE.UPDATE_PROFILE_SUCCESS:
      return { ...state, loading: false, profile: convertBeToFe(action.payload, PROFILE_MODEL) }
    
    case USER_TYPE.LOGOUT_SUCCESS:
      return { ...state, loading: false, profile: null };

    default:
      return state
  }
}
