import React from 'react';
import { Box, Grid } from '@mui/material';
import { useDispatch } from 'react-redux';

import KPTextfield from '../KPTextfield/KPTextfield';

import { userActions } from '../../../actions';
import { dateFormatter } from '../../../utils';
import { jumpToReleventDiv } from '../../../utils/anim';
import { getError, getValue } from './utils';

export const KPTYPE = {
  date: 'date',
  text: 'text',
  int: 'int',
  json: 'json',
  float: 'float',
  submit: 'submit',
  email: 'email',
  render: 'render',
  button: 'button',
  select: 'select',
  checkbox: 'checkbox',
  richtext: 'richtext',
  dropzone: 'dropzone',
  radio: 'radio',
  textarea: 'textarea',
}

export const DEFAULT_ERROR = { key: null, value: null }

function KPTextform({
  values = {},
  setValues,
  textForm = [],
  component = 'form',
  ...props
}) {
  const dispatch = useDispatch();
  const [error, setError] = React.useState({ ...DEFAULT_ERROR });

  const handleChangeForm = (name, event, type) => {
    var value;
    switch (type) {
      case KPTYPE.int:
        value = parseInt(event.target.value);
        break;
      case KPTYPE.float:
        value = parseFloat(event.target.value);
        break;
      case KPTYPE.date:
      case KPTYPE.dropzone:
      case KPTYPE.richtext:
        value = event;
        break;
      case KPTYPE.checkbox:
        value = event.target.checked;
        break;
      default:
        value = event.target.value;
        break;
    }
    
    const keys = name.split('.');
    const lastKey = keys.pop();
    const newValues = { ...values };

    var nestedObj = newValues;
    keys.forEach(k => {
      if (!nestedObj[k]) nestedObj[k] = {};
      nestedObj = nestedObj[k];
    });
    nestedObj[lastKey] = value;

    setValues(newValues);
  
    if (error.key === name) setError({ ...DEFAULT_ERROR });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const formattedValues = { ...values };

    for (const elm of textForm) {
      const err = getError(values, elm);
      if (!!err) {
        setError({ key: elm.key, value: err });
        jumpToReleventDiv(elm.key);
        dispatch(userActions.notification({
          message: `Không được để trống thông tin về: ${elm.label}`,
          type: 'error'
        }));
        return;
      }

      if (elm.type === KPTYPE.date) {
        formattedValues[elm.key] = dateFormatter(formattedValues[elm.key]);
      }
    }

    props.handleSubmit(formattedValues);
  }

  const formError = props.error || error;
 
  return (
    <Box id={props.id} component={component} onSubmit={handleSubmit}>
      <Grid container spacing={1}>
        {textForm.map((elm) => (
          <Grid item key={elm.key} xs={elm.xs} md={elm.md}>
            <KPTextfield
              {...elm}
              key={undefined}
              values={elm.type === KPTYPE.render ? values : undefined}
              id={elm.key}
              value={getValue(values, elm)}
              error={(formError.key === elm.key) ? formError.value : null}
              handleChangeForm={props.handleChangeForm || handleChangeForm}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default KPTextform;
