import { CRUD_API, CRUD_TYPE, JOURNAL_DOMESTIC_API, JOURNAL_DOMESTIC_TYPE } from "../constants";
import { apiCall, convertBeToFe } from "../utils";
import { userActions, formActions } from ".";

const { notification } = userActions;

export const journalDomesticActions = {
  filterJournalDomestic
};

function filterJournalDomestic(payload) {
  return async(dispatch) => {
    dispatch(loading());

    const api = JOURNAL_DOMESTIC_API.filter();
    const { response, error } = await apiCall({ ...api, payload });

    if (!error && response.status === 200) {
      dispatch(success(response.data.data.list));
      // dispatch(notification({ message: 'Lấy dữ liệu thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Lấy dữ liệu thất bại', type: 'error' }));
    }
  };

  function loading() { return { type: JOURNAL_DOMESTIC_TYPE.FILTER_JOURNAL_DOMESTIC_LOADING } }
  function success(payload) { return { type: JOURNAL_DOMESTIC_TYPE.FILTER_JOURNAL_DOMESTIC_SUCCESS, payload } }
  function failure(error) { return { type: JOURNAL_DOMESTIC_TYPE.FILTER_JOURNAL_DOMESTIC_FAILURE, error } }
}
