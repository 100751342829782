import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';

import theme from './theme';
import AppLayout from './components/AppLayout';

import { store } from './helpers';

import './index.css';
import 'ckeditor5/ckeditor5.css';

const App = () => {
  return (
    <Provider store={store}>
      <SnackbarProvider maxSnack={2} style={{ pointerEvents: 'all' }}>
        <ThemeProvider theme={theme}>
          <AppLayout />
        </ThemeProvider>
      </SnackbarProvider>
    </Provider>
  );
}

const root = createRoot(document.getElementById('root'));
root.render(<App />);
