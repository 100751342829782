import React from 'react';
import { Box } from '@mui/material';
import { CRUD_MODELS, SCIENTIFIC_RESEARCH_CONSTANTS } from '../constants';

export const autoScore = (type, data, scoreInfos, defaultMaxPoint=null) => {
  const filteredScoreInfos = scoreInfos.filter((info) => info.type === type);
  
  // B1: Tìm xem thành tích thuộc hạng mục gì
  var found, text = [];

  for (var i = 0; i < filteredScoreInfos.length; i++) {
    const filtered = filteredScoreInfos[i];

    // Nếu có defaultMaxPoint (tức là hội đồng sửa khung điểm) thì dùng điểm của hội đồng
    // Không thì lấy điểm theo khung hạng mục thành tích
    const val = eval(filtered.jsEvaluate);
    const p = defaultMaxPoint == null ? val : defaultMaxPoint;

    if (data.journalDomesticId) {
      // Tạp chí trong nước thì lấy điểm theo từng tạp chí
      if (data.journalDomesticId === filtered.journalDomesticId && val > 0) {
        found = { ...filtered, data, point: p, maxPoint: p, defaultMaxPoint: val };
        break;
      }
    } else {
      // Không phải tạp chí trong nước thì lấy điểm theo tiêu chí
      if (val > 0 && !filtered.journalDomesticId) {
        found = { ...filtered, data, point: p, maxPoint: p, defaultMaxPoint: val };
        break;
      }
    }
  }

  if (found) {
    text = [
      <React.Fragment>
        {'Thành tích thuộc hạng mục '}
        <b>{found.description}</b>
        {', với số điểm: '}
        <b>{found.score?.includes('\n') ? (
          found.score?.split('\n').map((e, id) => <Box key={`bul_${id}`} ml={4}>{e}</Box>)
        ) : (
          found.score
        )}. </b>
      </React.Fragment>
    ];

    if (defaultMaxPoint && (defaultMaxPoint != found.defaultMaxPoint)) {
      text.push(
        <React.Fragment>
          (Hội đồng đã sửa khung điểm thành <b>0-{defaultMaxPoint}</b>)
        </React.Fragment>
      );
    }
  } else {
    const p = defaultMaxPoint;
    found = { data, point: p, maxPoint: p, defaultMaxPoint };
    text.push(<span>Thành tích không thuộc hạng mục nào, hội đồng thư ký tự cho điểm.</span>);
  }

  // B2: Chia điểm theo số tác giả
  switch (type) {
    case CRUD_MODELS.MONOGRAPH:
    case CRUD_MODELS.SCIENTIFIC_RESEARCH:
      if (found.data.numberMain > 0) {
        // 2/3 tổng điểm chia đều cho số tác giả, 1/3 chia đều cho số tác giả chính
        const scoreForEveryone = (found.point * 2/3) / found.data.numberAuthor;
        const ScoreForEachMain = (found.data.role === 'main') ? (found.point * 1/3) / found.data.numberMain : 0;
        found.point = Math.round((scoreForEveryone + ScoreForEachMain) * 100) / 100;;
        text.push(
          <React.Fragment>
            <br /><br />
            <span>Thành tích có </span><b>{found.data.numberAuthor}</b><span> tác giả tham gia, với </span>
            <span><b>{found.data.numberMain}</b> </span>
            <span>{SCIENTIFIC_RESEARCH_CONSTANTS.ROLE_OPTIONS[0].title}. Trong đó, ứng viên là </span>
            <span><b>{SCIENTIFIC_RESEARCH_CONSTANTS.ROLE_OPTIONS.find((role) => role.value === found.data.role)?.title}</b></span>
            <span> nên được hưởng </span>
            <span>
              <b>
                {found.data.role === 'main' ? (
                  `2/3 x 1/${found.data.numberAuthor} + 1/3 x 1/${found.data.numberMain} = ${
                    Math.round((2/3 * 1/found.data.numberAuthor + 1/3 * 1/found.data.numberMain) * 100) / 100
                  }`
                ) : (
                  `2/3 x 1/${found.data.numberAuthor} = ${Math.round((2/3 * 1/found.data.numberAuthor) * 100) / 100}`
                )}
              </b>
            </span>
            <span> tổng số điểm.</span>
          </React.Fragment>
        );
      } else {
        // Chia đều theo đầu người
        found.point = Math.round(found.point / found.data.numberAuthor * 100) / 100;
        text.push(
          <React.Fragment>
            <br /><br />
            <span>Thành tích có </span><b>{found.data.numberAuthor}</b><span> tác giả tham gia, với </span>
            <span><b>{found.data.numberMain}</b> </span>
            <span>{SCIENTIFIC_RESEARCH_CONSTANTS.ROLE_OPTIONS[0].title}. Trong đó, ứng viên là </span>
            <span><b>{SCIENTIFIC_RESEARCH_CONSTANTS.ROLE_OPTIONS.find((role) => role.value === found.data.role)?.title}</b></span>
            <span> nên được hưởng </span>
            <span><b>{`1/${found.data.numberAuthor} = ${1 / found.data.numberAuthor}`}</b></span>
            <span> tổng số điểm.</span>
          </React.Fragment>
        );
      }
      break;
    default:
      break;
  }
  text.push(
    <React.Fragment>
      <br /><br />
      <span>Vậy ứng viên được tối đa: <b>{found.point || 0} điểm.</b></span>
    </React.Fragment>
  );

  return { ...found, text }
}