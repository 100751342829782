import { KPTYPE } from '../components/KPTComponents/KPTextform/KPTextform';
import { CRUD_LABELS, CRUD_MODELS } from './crud.constants';

const options = [
  CRUD_MODELS.SCIENTIFIC_RESEARCH,
  CRUD_MODELS.MONOGRAPH,
].map((e) => ({
  key: e,
  value: e,
  title: CRUD_LABELS[e],
}));

export const SCORING_INFO_MODEL = {
  title: { key: 'title', label: 'Tên tạp chí', required: true },
  issn: { key: 'issn', label: 'Chỉ số ISSN', required: true },
  type: { key: 'type', label: 'Loại', type: KPTYPE.select, options: options, required: true },
  description: { key: 'description', label: 'Miêu tả', required: true },
  score: { key: 'score', label: 'Điểm', required: true },
  jsEvaluate: { key: 'jsEvaluate', label: 'Điểm', required: true },
};

export const defaultRows = [
  {
    title: 'Các tạp chí khoa học Việt Nam và quốc tế',
    issn: null,
    type: CRUD_MODELS.SCIENTIFIC_RESEARCH,
    description: 'SCI, SCIE, SSCI (ISI) có IF≥2 và A&HCI(ISI)',
    score: '0-3.0',
    jsEvaluate: 
`(data.filterType === 'international' && (
(
  data.journalCoreCollection?.includes('SCIE')
  || data.journalCoreCollection?.includes('SSCI')
) && data.jif >= 2
) || data.journalCoreCollection?.includes('AHCI')) ? 3.0 : 0`
  },
  {
    title: 'Các tạp chí khoa học Việt Nam và quốc tế',
    issn: null,
    type: CRUD_MODELS.SCIENTIFIC_RESEARCH,
    description: 'SCI, SCIE, SSCI (ISI) có IF<2 và Scopus(Q1)',
    score: '0-2.0',
    jsEvaluate: 
`((
  (data.journalCoreCollection?.includes('SCIE') || data.journalCoreCollection?.includes('SSCI'))
  && data.jif < 2
) || data.type === 'q1') ? 2.0 : 0`
  },
  {
    title: 'Các tạp chí khoa học Việt Nam và quốc tế',
    issn: null,
    type: CRUD_MODELS.SCIENTIFIC_RESEARCH,
    description: 'ESCI (ISI) và Scopus (Q2, Q3, Q4)',
    score: '0-1.5',
    jsEvaluate: 
`(
  data.journalCoreCollection?.includes('ESCI')
  || data.type === 'q2'
  || data.type === 'q3'
  || data.type === 'q4'
  || data.journalCoreCollection?.includes('scopus')
) ? 1.5 : 0`
  },
  {
    title: 'Các tạp chí khoa học quốc tế khác (ACI, …)',
    issn: null,
    type: CRUD_MODELS.SCIENTIFIC_RESEARCH,
    description: 'ACI',
    score: '0-1.25',
    jsEvaluate: "(data.filterType === 'other' && data.belongTo === 'aci') ? 1.25 : 0"
  },
  {
    title: 'Các tạp chí khoa học quốc tế khác (ACI, …)',
    issn: null,
    type: CRUD_MODELS.SCIENTIFIC_RESEARCH,
    description: 'Khác (do HDGS ngành đánh giá và quyết định)',
    score: '0-1.0',
    jsEvaluate: "(data.filterType === 'other' && data.belongTo === 'other') ? 1.0 : 0"
  },
  {
    title: 'Các tạp chí khoa học quốc tế khác (ACI, …)',
    issn: null,
    type: CRUD_MODELS.SCIENTIFIC_RESEARCH,
    description: 'Thuộc Top 500 Trường ĐH hàng đầu thế giới do Times Higher Education (THE) hợp tác với Thomson Reuters bình chọn, xếp hạng hàng năm.',
    score: '0-1.25',
    jsEvaluate: "(data.filterType === 'other' && data.belongTo === 'top500') ? 1.0 : 0"
  },
  {
    title: 'Báo cáo khoa học viết bằng tiếng Anh tại hội nghị khoa học quốc tế',
    issn: null,
    type: CRUD_MODELS.SCIENTIFIC_RESEARCH,
    description: 'Báo cáo khoa học viết bằng tiếng Anh tại hội nghị khoa học quốc tế, đăng toàn văn trong kỷ yếu (Proceedings), có phản biện khoa học, có chỉ số ISBN',
    score: '0-1.0',
    jsEvaluate: "(data.filterType === 'conference' && data.belongTo === 'international' && data.issn) ? 1.0 : 0"
  },
  {
    title: 'Báo cáo khoa học tại hội nghị khoa học quốc gia',
    issn: null,
    type: CRUD_MODELS.SCIENTIFIC_RESEARCH,
    description: 'Báo cáo khoa học tại hội nghị khoa học quốc gia, đăng toàn văn trong kỷ yếu (Proceedings), có phản biện khoa học, từ năm 2017 phải có chỉ số ISBN',
    score: '0-0.5',
    jsEvaluate: "(data.filterType === 'conference' && data.belongTo === 'domestic' && (data.publishYear < 2017 || data.issn)) ? 0.5 : 0"
  },
  {
    title: 'Các Tạp chí khoa học về chủ đề Kinh tế học, Kinh doanh, Tài chính, Ngân hàng, Bảo hiểm, Kế toán, Kiểm toán, Quản trị và Quản lý',
    issn: null,
    type: CRUD_MODELS.SCIENTIFIC_RESEARCH,
    description: 'Không thuộc danh mục ISSI/Scopus, nhưng được xuất bản bởi các nhà xuất bản: Springer, Elsevier, Wley- Blackwell, Taylor&Francis, Sage, Oxford University Press, Cambridge University Press, Emerald, Macmillan Publishers, Edward Elgar Publishing, De Gruyter, World Scientific, Routledge, Peter Lang, Thomson Reuters, McGraw Hill.',
    score: '0-0.5',
    jsEvaluate: "(data.filterType === 'other' && data.belongTo === 'economic') ? 0.5 : 0"
  },
  {
    title: 'Sách',
    issn: null,
    type: CRUD_MODELS.MONOGRAPH,
    description: 'Giáo trình',
    score: '0-2.5',
    jsEvaluate: "(data.type === 'sachGiaoTrinh') ? 2.5 : 0"
  },
  {
    title: 'Sách',
    issn: null,
    type: CRUD_MODELS.MONOGRAPH,
    description: 'Chuyên khảo',
    score: '0-3.0',
    jsEvaluate: "(data.type === 'sachChuyenKhao') ? 3.0 : 0"
  },
  {
    title: 'Sách',
    issn: null,
    type: CRUD_MODELS.MONOGRAPH,
    description: 'Tham khảo',
    score: '0-1.5',
    jsEvaluate: "(data.type === 'sachThamKhao') ? 1.5 : 0"
  },
  {
    title: 'Sách',
    issn: null,
    type: CRUD_MODELS.MONOGRAPH,
    description: 'Sách hướng dẫn',
    score: '0-1',
    jsEvaluate: "(data.type === 'sachHuongDan') ? 1 : 0"
  },
  // {
  //   title: 'Các tạp chí khoa học quốc tế khác (ACI, …)',
  //   issn: null,
  //   type: CRUD_MODELS.SCIENTIFIC_RESEARCH,
  //   description: 'Khác (do HDGS ngành đánh giá và quyết định)',
  //   score: '0-1.0',
  //   jsEvaluate: "(data.filterType === 'other' && !data.isACI) ? 1.0 : 0"
  // },
  // {
  //   title: 'Các tạp chí khoa học thuộc nhóm chuyên nhành Kinh tế',
  //   issn: null,
  //   type: CRUD_MODELS.SCIENTIFIC_RESEARCH,
  //   description: 'Thuộc Top 500 Trường ĐH hàng đầu thế giới do Times Higher Education (THE) hợp tác với Thomson Reuters bình chọn, xếp hạng hàng năm.',
  //   score: '0-1.0',
  //   jsEvaluate: "(data.filterType === 'other' && !data.isBelongToEconomicMajor) ? 1.0 : 0"
  // },

];