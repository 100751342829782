import React from 'react';
import { Popover } from '@mui/material';

function PopupMenu({ render, anchorEl, position, containerStyle, handleClose }) {

  return (
    <Popover
      keepMounted
      open={!!anchorEl}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: position || 'center' }}
      transformOrigin={{ vertical: 'top', horizontal: position || 'center' }}
      PaperProps={{
        style: { ...containerStyle }
      }}
    >
      {render}
    </Popover>
  );
}

export default PopupMenu;
