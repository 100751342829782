import React from 'react';
import { useSelector } from 'react-redux';
import { Box, Typography } from '@mui/material';

import theme from '../../../../theme';
import KPTextform from '../../../../components/KPTComponents/KPTextform';
import DefaultLabel from '../../../../components/CommonComponents/DefaultLabel';

import { KPTYPE } from '../../../../components/KPTComponents/KPTextform/KPTextform';
import { TRAINEE_GUIDANCE_MODEL } from '../../../../constants';

function HuongDanForm(props) {
  const { disabled } = props;

  const dOptions = useSelector((state) => state.formReducer.researchDirections?.map(
    (e, id) => ({ key: e.id, value: e.id, title: `[${id + 1}] ${e.title}` })
  )) || [];


  const textForm = [
    { key: 'noti', xs: 12, type: KPTYPE.render, labelRender: <></>, render: () => (
      <Typography variant='subtitle1' style={{ color: theme.palette.error.main }}>
        <i>(Lưu ý: Ứng viên chức danh GS chỉ kê khai thông tin về hướng dẫn NCS)</i>
      </Typography>
    ) },
    { ...TRAINEE_GUIDANCE_MODEL.title, xs: 12, disabled },
    { ...TRAINEE_GUIDANCE_MODEL.target, xs: 6, disabled },
    { ...TRAINEE_GUIDANCE_MODEL.role, xs: 6, disabled },
    {
      key: 'labelx',
      xs: 12,
      labelRender: <DefaultLabel label='Thời gian hướng dẫn' required={true} />,
      type: KPTYPE.render,
      render: () => null,
    },
    
    { ...TRAINEE_GUIDANCE_MODEL.startTime, xs: 5, labelRender: <></>, required: true },
    { key: 'to_0', xs: 2, labelRender: <Box />, type: KPTYPE.render, render: () => (
      <Box sx={{ height: 39, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography className='kpt-textfield-label'>đến</Typography>
      </Box>
    ) },
    { ...TRAINEE_GUIDANCE_MODEL.endTime, xs: 5, labelRender: <></>, required: true },

    { ...TRAINEE_GUIDANCE_MODEL.researchDirectionId, xs: 12, type: KPTYPE.select, options: dOptions, disabled },
    { ...TRAINEE_GUIDANCE_MODEL.trainingBase, xs: 12, disabled },
    { ...TRAINEE_GUIDANCE_MODEL.issueDate, xs: 12, disabled },
    { ...TRAINEE_GUIDANCE_MODEL.proof, xs: 12, disabled },
  ];

  return <KPTextform {...props} textForm={textForm} />
}

export default HuongDanForm;
