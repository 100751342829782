import { USER_TYPE } from '../constants';

const initialState = {
  filterData: null,
  loading: false,
  loadingGlobal: false,
}

export function userReducer(state = initialState, action) {
  switch (action.type) {
    case USER_TYPE.LOGIN_LOADING:
    case USER_TYPE.LOGOUT_LOADING:
    case USER_TYPE.REGISTER_LOADING:
    case USER_TYPE.RESET_BY_EMAIL_LOADING:
    case USER_TYPE.CHANGE_PASSWORD_LOADING:
      return { ...state, loading: true };

    case USER_TYPE.FILTER_USER_LOADING:
    case USER_TYPE.CHANGE_ROLE_LOADING:
      return { ...state, loadingGlobal: true };

    case USER_TYPE.LOGIN_FAILURE:
    case USER_TYPE.LOGIN_SUCCESS:
    case USER_TYPE.LOGOUT_FAILURE:
    case USER_TYPE.LOGOUT_SUCCESS:
    case USER_TYPE.REGISTER_FAILURE:
    case USER_TYPE.REGISTER_SUCCESS:
    case USER_TYPE.RESET_BY_EMAIL_FAILURE:
    case USER_TYPE.RESET_BY_EMAIL_SUCCESS:
    case USER_TYPE.CHANGE_PASSWORD_FAILURE:
    case USER_TYPE.CHANGE_PASSWORD_SUCCESS:
      return { ...state, loading: false };

    case USER_TYPE.FILTER_USER_FAILURE:
    case USER_TYPE.CHANGE_ROLE_FAILURE:
      return { ...state, loadingGlobal: false };

    case USER_TYPE.NOTIFICATION:
      return { ...state, notification: action.payload };

    case USER_TYPE.SET_LOADING_GLOBAL:
      return { ...state, loadingGlobal: action.loading };

    case USER_TYPE.FILTER_USER_SUCCESS:
      return { ...state, loadingGlobal: false, filterData: action.payload };

    case USER_TYPE.CHANGE_ROLE_SUCCESS:
      const filterData = state.filterData;
      const list = filterData.list;
      const index = list.findIndex((e) => e.id === action.payload.userId);
      list[index].role = action.payload.role;
      return { ...state, loadingGlobal: false, filterData: { ...state.filterData, list } };
      
    default:
      return state
  }
}
