import { apiCall } from '../utils';
import { FILE_API, FILE_TYPE } from '../constants';

import { userActions } from '.';
const { notification } = userActions;

export const fileActions = {
  importMultiFile,
};

export const importMultiFileAsync = async (file, path) => {
  const formData = new FormData();
  file.forEach((e) => formData.append('file', e));
  path.forEach((e) => formData.append('path[]', e));

  const api = FILE_API.importMultiFile();
  return await apiCall({ ...api, payload: formData });
}

function importMultiFile(file, path) {
  return async(dispatch) => {
    dispatch(loading());

    const { response, error } = importMultiFileAsync(file, path);

    if (!error && response.status === 200) {
      dispatch(success());
      return true;
    } else {
      dispatch(failure(error));
      // dispatch(notification({ message: 'Thêm minh chứng thất bại', type: 'error' }));
      return false;
    }
  };

  function loading() { return { type: FILE_TYPE.IMPORT_FILE_LOADING } }
  function success() { return { type: FILE_TYPE.IMPORT_FILE_SUCCESS } }
  function failure(error) { return { type: FILE_TYPE.IMPORT_FILE_FAILURE, error } }
}
