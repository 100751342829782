import { combineReducers } from 'redux';

import { userReducer } from './user.reducer';
import { formReducer } from './form.reducer';
import { councilReducer } from './council.reducer';
import { profileReducer } from './profile.reducer';
import { journalDomesticReducer } from './journalDomestic.reducer';

const rootReducer = combineReducers({
    userReducer,
    formReducer,
    councilReducer,
    profileReducer,
    journalDomesticReducer,
});

export default (state, action) => rootReducer(state, action);
