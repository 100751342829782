import React from "react";
import { Box, Card, CardContent } from "@mui/material";

import KPTextform from "../../../components/KPTComponents/KPTextform";

import { textForm } from "../utils";

function MucC({ error, values, setValues }) {
  return (
    <Card id='muc_e' style={{ width: '100%', marginTop: 16 }}>
      <CardContent>
        <KPTextform
          component={Box}
          error={error}
          values={values}
          setValues={setValues}
          textForm={textForm.mucE}
        />
      </CardContent>
    </Card>
  )
}

export default MucC;
