import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
  accordionRoot: {
    backgroundColor: 'transparent',
    mb: 4,
    border: 'none',
    boxShadow: 'none',
    '&::before': {
      backgroundColor: 'transparent'
    }
  },
  accordionExpanded: {
    margin: '0 !important',
  },
  accordionSummaryRoot: {
    marginBottom: -1,
    padding: 0,
    minHeight: '12px !important',
  },
  accordionDetailsRoot: {
    padding: 0,
    paddingLeft: 16
  },
  accordionSummaryContent: {
    margin: '12px 0 !important',
  },
  accordionSummaryExpanded: {},
}));