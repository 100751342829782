import React, { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Menu,
  Close,
  VpnKey,
  ExitToApp,
  ExpandMore,
} from '@mui/icons-material';
import {
  Box,
  Link,
  Button,
  AppBar,
  Drawer,
  Divider,
  MenuItem,
  Accordion,
  Container,
  Typography,
  AccordionDetails,
  AccordionSummary,
} from '@mui/material';

import { userActions } from '../../../actions';
import { COLOR_PALETTE, ROUTER, ROUTER_CONSTANTS, USER_CONSTANTS } from '../../../constants';

import tnt from '../../../assets/image/home/quoc-huy.png';
import styles from './AppHeader.module.css';
import PopupMenu from '../../../components/CommonComponents/PopupMenu';

function AppHeader() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const locationUrl = window.location.href;

  const [openDrawer, setOpenDrawer] = useState(false);
  const [userMenuAnchor, setUserMenuAnchor] = useState(false);
  const [adminMenuAnchor, setAdminMenuAnchor] = useState(false);

  const profile = useSelector(state => state.profileReducer.profile);

  const openUserMenu = (e) => setUserMenuAnchor(e.currentTarget);
  const closeUserMenu = () => setUserMenuAnchor(null);

  const openAdminMenu = (e) => setAdminMenuAnchor(e.currentTarget);
  const closeAdminMenu = () => setAdminMenuAnchor(null);

  const routeTo = (route) => {
    navigate(route);
    closeUserMenu();
    closeAdminMenu();
  }

  const logout = () => {
    dispatch(userActions.logout()).then((success) => {
      if (success) {
        navigate(ROUTER.DANG_NHAP);
        window.location.reload();
      }
    });
    closeUserMenu();
    closeAdminMenu();
  }

  const userMenu = [
    { key: 'doi-mk', title: 'Đổi mật khẩu', icon: <VpnKey />, onClick: () => routeTo(ROUTER.DOI_MAT_KHAU) },
    { key: 'dang-xuat', title: 'Đăng xuất', icon: <ExitToApp />, onClick: logout },
  ];

  const navItems = useMemo(() => {
    const items = [...ROUTER_CONSTANTS.NAV_ITEMS];
    if (profile?.userRole === USER_CONSTANTS.ROLE.council) {
      ROUTER_CONSTANTS.COUNCIL_ITEM.forEach((item) => items.push(item))
    }
    return items;
  }, [profile]);

  return (
    <AppBar className={styles.appBar} position='fixed'>
      <Container maxWidth='lg' sx={{ display: { xs: 'none', sm: 'block' } }}>
        <Box display='flex' height={64} alignItems='center' justifyContent='space-between'>
          <Box display='flex' alignItems='center'>
            <img alt='logo' src={tnt} style={{ marginRight: 32, height: 52, marginTop: 4 }} />
            {navItems.map((e) => (
              <Link className={styles.routeButton} key={e.key} href={e.to}>{e.title}</Link>
            ))}
            {(profile?.userRole === USER_CONSTANTS.ROLE.super_admin) && (
              <Link className={`${styles.routeButton} ${styles.adminButton}`} onClick={openAdminMenu}>
                Quản trị viên
                <svg className={styles.downIcon} viewBox='7 10 10 5'><path d='M7 10l5 5 5-5z' /></svg>
              </Link>
            )}
          </Box>
          <Box display='flex' alignItems='center' justifyContent='flex-end'>
            {profile ? (
              <Link className={`${styles.routeButton} ${styles.adminButton}`} onClick={openUserMenu}>
                {profile.email}
                <svg className={styles.downIcon} viewBox='7 10 10 5'><path d='M7 10l5 5 5-5z' /></svg>
              </Link>
              ) : (
                <>
                  <Link href={ROUTER.DANG_KY} className={styles.routeButton}>Đăng ký</Link>
                  <Button
                    color='primary'
                    variant='contained'
                    href={ROUTER.DANG_NHAP}
                    style={{ float: 'right', margin: '10px 0 10px 16px' }}
                  >
                    Đăng nhập
                  </Button>
                </>
              )}
          </Box>
        </Box>
      </Container>
      <Container maxWidth='xl' sx={{ display: { xs: 'block', sm: 'none' } }}>
        <Box display='flex' height={64} alignItems='center' justifyContent='space-between'>
          <Box display='flex' alignItems='center'>
            <img alt='logo' src={tnt} style={{ marginRight: 32, height: 52, marginTop: 4 }} />
          </Box>
          <Box width='100%'>
            {openDrawer ? (
              <Close style={{ color: 'black', float: 'right' }} onClick={() => setOpenDrawer(false)} />
            ) : (
              <Menu style={{ color: 'black', float: 'right' }} onClick={() => setOpenDrawer(true)} />
            )}
          </Box>
        </Box>
      </Container>
      <PopupMenu
        position='right'
        anchorEl={userMenuAnchor}
        handleClose={closeUserMenu}
        render={
          <>
            {userMenu.map((e) => (
              <MenuItem key={e.key} onClick={e.onClick}>
                {e.icon}
                <Box className={styles.userMenu}>{e.title}</Box>
              </MenuItem>
            ))}
          </>
        } 
      />

      <PopupMenu
        position='center'
        anchorEl={adminMenuAnchor}
        disableRestoreFocus
        handleClose={closeAdminMenu}
        render={
          <>
            {ROUTER_CONSTANTS.ADMIN_DROPDOWN.map((e) => (
              <MenuItem key={e.key} onClick={() => routeTo(e.to)} style={{ fontWeight: 'bold' }}>
                {e.title}
              </MenuItem>
            ))}
          </>
        } 
      />

      <Drawer
        anchor='top'
        open={openDrawer}
        PaperProps={{ style: { marginTop: 64, color: 'black' } }}
        onClose={() => setOpenDrawer(false)}
        style={{ zIndex: 2 }}
      >
        {navItems.map((e) => (
          <Link
            key={e.key}
            href={e.to}
            className={styles.mobileRouteButton}
            style={{
              height: 56,
              lineHeight: '56px',
              borderRadius: 0,
              borderLeftColor: locationUrl.includes(e.to) ? COLOR_PALETTE.PRIMARY : '#ffffff00',
              backgroundColor: locationUrl.includes(e.to) ? `${COLOR_PALETTE.PRIMARY}10` : '#ffffff00',
            }}
          >
            {e.title}
          </Link>
        ))}
        {(profile?.userRole === USER_CONSTANTS.ROLE.super_admin) && (
          <Accordion classes={{
            root: styles.accordionRoot
          }}>
            <AccordionSummary className={styles.mobileUser} expandIcon={<ExpandMore />}>
              <Typography><b>Quản trị viên</b></Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.mobileUserMenu}>
              <Box width='100%'>
                {ROUTER_CONSTANTS.ADMIN_DROPDOWN.map((e) => (
                  <MenuItem key={e.key} style={{ height: 56 }} onClick={() => {
                    routeTo(e.to);
                    setOpenDrawer(false);
                  }}>
                    <Typography variant='subtitle1'>
                      {e.title}
                    </Typography>
                  </MenuItem>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
        <Divider />
        {profile ? (
          <Accordion classes={{
            root: styles.accordionRoot
          }}>
            <AccordionSummary className={styles.mobileUser} expandIcon={<ExpandMore />}>
              <Typography style={{ fontWeight: 600 }}>{profile.email}</Typography>
            </AccordionSummary>
            <AccordionDetails className={styles.mobileUserMenu}>
              <Box width='100%'>
                {userMenu.map((e) => (
                  <MenuItem key={e.key} onClick={() => { e.onClick(); setOpenDrawer(false); }} style={{ height: 56 }}>
                    {e.icon}
                    <Typography variant='subtitle1' style={{ marginLeft: 16, fontWeight: 600 }}>{e.title}</Typography>
                  </MenuItem>
                ))}
              </Box>
            </AccordionDetails>
          </Accordion>
        ) : (
          <Box style={{ marginLeft: 20, lineHeight: '56px' }} onClick={() => { setOpenDrawer(false); navigate(ROUTER.DANG_NHAP); }}>
            <b>Đăng nhập</b>
          </Box>
        )}
      </Drawer>
    </AppBar>
  )
}

export default AppHeader;
