import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import {
  Menu,
  MenuItem,
  Grid,
  Button,
  TextField,
  IconButton,
  FormControl,
  FormHelperText,
  InputAdornment,
} from '@mui/material';
import { Event } from '@mui/icons-material';
import { withStyles } from '@mui/styles';

import { KPTYPE } from '../KPTextform/KPTextform';

import DefaultLabel from '../../CommonComponents/DefaultLabel';

const monthList = [
  { value: '01', name: 'Tháng 01'},
  { value: '02', name: 'Tháng 02'},
  { value: '03', name: 'Tháng 03'},
  { value: '04', name: 'Tháng 04'},
  { value: '05', name: 'Tháng 05'},
  { value: '06', name: 'Tháng 06'},
  { value: '07', name: 'Tháng 07'},
  { value: '08', name: 'Tháng 08'},
  { value: '09', name: 'Tháng 09'},
  { value: '10', name: 'Tháng 10'},
  { value: '11', name: 'Tháng 11'},
  { value: '12', name: 'Tháng 12'},
];

function KPTimePicker(props) {
  const formRef = React.useRef();
  const textareaRef = React.useRef();

  const dateRef = React.useRef();
  const monthRef = React.useRef();
  const yearRef = React.useRef();

  const {value, setValue} = props;

  const [anchorEl, setAnchorEl] = useState(null);
  const [cursorPosition, setCursorPosition] = useState(null);

  const StyledMenu = withStyles({
    paper: {
      border: 'solid 1px #d3d4d5',
      width: Math.max(formRef?.current?.offsetWidth - 2, 320),
    },
  })((props) => (
    <Menu
      elevation={0}
      // getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  ));

  const dateFormat = (value) => {
    const dateForm = value?.replaceAll('/', '');
    const date = dateForm?.substr(0, 2);
    const month = dateForm?.substr(2, 2);
    const year = dateForm?.substr(-4);
    return(`${month}/${date}/${year}`)
  }

  const renderFormat = (value) => {
    const dateForm = value?.replaceAll('/', '');
    const date = dateForm?.substr(0, 2);
    const month = dateForm?.substr(2, 2);
    const year = dateForm?.substr(-4);
    return(`${date}/${month}/${year}`)
  }

  const handleClick = () => setAnchorEl(formRef.current);
  const handleClose = () => setAnchorEl(null);

  const handleChange = (e) => {
    // Text to Array
    let charList = e.target.value.split('');
    let cursorIndex = e.target.selectionStart;

    /**
     * TH1: Select nhiều character thì chỉ đổi character đầu tiên và xoá những ký tự còn lại
     * TH2: Select 1 character mà là dấu '/' thì chuyển sang character kế tiếp
     * TH3: Còn lại thì chỉ cần đổi character đó 
     */
    if (charList.length < value.length) {
      const deletedNum = value.length - charList.length;
      charList = value.split('');
      charList[cursorIndex - 1] = e.target.value[cursorIndex - 1];

      const initForm = 'dd/mm/yyyy';
      for (let i = cursorIndex; i < cursorIndex + deletedNum; i++) {
        charList[i] = initForm[i]
      }

      setCursorPosition(cursorIndex);
    } else {
      if (charList[cursorIndex] === '/') {
        charList.splice(cursorIndex, 2);
        setCursorPosition(cursorIndex + 1);
      } else {
        charList.splice(cursorIndex, 1);
        setCursorPosition(cursorIndex);
      }
    }

    const newDate = renderFormat(charList.join('').replaceAll('/', ''));
    props.handleChangeForm(props.id, newDate, KPTYPE.date);
  }

  const handleSubmit = () => {
    const pickerDate = dateRef.current.value;
    const pickerMonth = monthRef.current.value;
    const pickerYear = yearRef.current.value;

    props.handleChangeForm(props.id, dayjs(`${pickerMonth}/${pickerDate}/${pickerYear}`).format('DD-MM-YYYY').replaceAll('-', '/'), KPTYPE.date);
    handleClose();
  }  
  
  useEffect(() => {
    if (cursorPosition !== null) textareaRef.current.selectionStart = textareaRef.current.selectionEnd = cursorPosition;
  }, [cursorPosition]);

  const dateForm = value?.replaceAll('/', '');
  let date = dateForm?.substr(0, 2);
  let month = dateForm?.substr(2, 2);
  let year = dateForm?.substr(-4);


  if (isNaN(parseInt(date))) date = 1;
  if (isNaN(parseInt(month))) month = '01';
  if (isNaN(parseInt(year))) year = (new Date()).getFullYear();

  const yearList = [];
  for (let i = 1950; i <= 2050; i++) yearList.push(i);

  const err = (value !== 'dd/mm/yyyy') && (
    ((!!anchorEl) && dayjs(dateFormat(value)).get('month') !== parseInt(month) - 1) ||
    (dayjs(dateFormat(value)).format('DD-MM-YYYY') === 'Invalid Date') || 
    (dayjs(dateFormat(value)).get('year') > 2050 || dayjs(dateFormat(value)).get('year') < 1950)
  );

  const error = props.error && (!value || value === 'dd/mm/yyyy');

  const content = () => {
    let dateList = [];
    for (let i = 1; i <= 31; i++) dateList.push(i);

    return (
      <Grid container spacing={1} style={{ padding: 8 }}>
        <Grid item xs={3}>
          <TextField
            fullWidth
            type='tel'
            size='small'
            label='Ngày'
            variant='outlined'
            defaultValue={parseInt(date)}
            inputRef={dateRef}
            inputProps={{ style: { padding: 8 } }}
          />
        </Grid>
        <Grid item xs={5}>
          <TextField
            select
            color='primary'
            fullWidth
            size='small'
            label='Tháng'
            variant='outlined'
            defaultValue={month}
            SelectProps={{
              SelectDisplayProps: { style: { padding: 8 } },
              MenuProps: {
                // getContentAnchorEl: null,
                MenuListProps: { style: { padding: 0 } },
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                transformOrigin: { vertical: 'top', horizontal: 'center' },
                style: { maxHeight: 320 }
              }
            }}
            inputRef={monthRef}
            inputProps={{ style: { padding: 0 } }}
          >
            {monthList.map((e) => <MenuItem key={`month-${e.value}`} value={e.value}>{e.name}</MenuItem>)}
          </TextField>
        </Grid>
        <Grid item xs={4}>
          <TextField
            select
            fullWidth
            size='small'
            label='Năm'
            variant='outlined'
            defaultValue={year}
            SelectProps={{
              SelectDisplayProps: { style: { padding: 8 } },
              MenuProps: {
                // getContentAnchorEl: null,
                MenuListProps: { style: { padding: 0 } },
                anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
                transformOrigin: { vertical: 'top', horizontal: 'center' },
                style: { maxHeight: 320 }
              }
            }}
            inputRef={yearRef}
            inputProps={{ style: { padding: 8 } }}
          >
            {yearList.map((e) => <MenuItem key={`year-${e}`} value={e}>{e}</MenuItem>)}
          </TextField>
        </Grid>
        <Grid item xs={12}>
            <Button fullWidth variant='contained' color='primary' onClick={handleSubmit}>Xác nhận</Button>
          </Grid>
      </Grid>
    );
  }

  return (
    <>
      {props.label ?? <DefaultLabel label={props.label} required={props.required} />}
      <FormControl
        fullWidth
        error={err || error}
        ref={formRef}
        required={props.required}
      >
        <TextField
          type='tel'
          fullWidth
          error={error}
          inputRef={textareaRef}
          disabled={props.disabled}
          size={props.size || 'small'}
          variant='outlined'
          value={value}
          inputProps={{ style: { padding: 8, backgroundColor: 'white' } }}
          InputProps={{
            style: { backgroundColor: 'white', paddingRight: 4 },
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton onClick={handleClick} disabled={props.disabled}><Event /></IconButton>
              </InputAdornment>
            )
          }}
          onChange={handleChange}
        />
        {err && <FormHelperText>Ngày không tồn tại</FormHelperText>}
        {error && <FormHelperText>Không được để trống</FormHelperText>}
      </FormControl>

      <StyledMenu anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        <form onSubmit={handleSubmit}>{content()}</form>
      </StyledMenu>
    </>
  )
}

export default KPTimePicker;
