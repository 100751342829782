import React from 'react';
import { Typography } from '@mui/material';

import theme from '../../../theme';

const DefaultLabel = ({ label, required }) => (
  <Typography className='kpt-textfield-label'>
    {label}
    {required ? <span style={{ color: theme.palette.error.main }}>*</span> : ''}
    {label ? ':' : ''}
  </Typography>
);

export default DefaultLabel;
