import { HEADERS } from '.';

export const FILE_API = {
  importMultiFile: (id) => ({
    endPoint: `/api/v1/file/upload`,
    headers: HEADERS.UPLOAD_FILE_HEADER,
    method: 'POST',
  }),
}

export const FILE_TYPE = {
  IMPORT_FILE_LOADING: 'IMPORT_FILE_LOADING',
  IMPORT_FILE_SUCCESS: 'IMPORT_FILE_SUCCESS',
  IMPORT_FILE_FAILURE: 'IMPORT_FILE_FAILURE',
};
