import { CRUD_API, CRUD_TYPE } from "../constants/crud.constants";
import { apiCall, convertBeToFe } from "../utils";
import { userActions, formActions } from "../actions";

const { notification } = userActions;

export const crudActions = { insert, update, remove };

function insert(model, payload) {
  return async(dispatch) => {
    dispatch(loading());

    const api = CRUD_API.insert(model);
    const { response, error } = await apiCall({ ...api, payload });

    if (!error && response.status === 200) {
      dispatch(success({ ...payload, id: response.data.data }));
      dispatch(notification({ message: 'Thêm thông tin thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Thêm thông tin thất bại', type: 'error' }));
    }
  };

  function loading() { return { type: CRUD_TYPE.INSERT_CRUD_LOADING } }
  function success(payload) { return { type: CRUD_TYPE.INSERT_CRUD_SUCCESS, model, payload } }
  function failure(error) { return { type: CRUD_TYPE.INSERT_CRUD_FAILURE, error } }
}

function update(model, payload) {
  return async(dispatch) => {
    dispatch(loading());

    const api = CRUD_API.update(model);
    const { response, error } = await apiCall({ ...api, payload });

    if (!error && response.status === 200) {
      dispatch(success(payload));
      dispatch(notification({ message: 'Cập nhật thông tin thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Cập nhật thông tin thất bại', type: 'error' }));
    }
  };

  function loading() { return { type: CRUD_TYPE.UPDATE_CRUD_LOADING } }
  function success(payload) { return { type: CRUD_TYPE.UPDATE_CRUD_SUCCESS, payload, model } }
  function failure(error) { return { type: CRUD_TYPE.UPDATE_CRUD_FAILURE, error } }

  // function loading() { return { type: crudActionType(UPDATE, model, LOADING) } }
  // function success(payload) { return { type: crudActionType(UPDATE, model, SUCCESS), payload } }
  // function failure(error) { return { type: crudActionType(UPDATE, model, FAILURE), error } }
}

function remove(model, id) {
  return async(dispatch) => {
    dispatch(loading());

    const api = CRUD_API.delete(model, id);
    const { response, error } = await apiCall(api);

    if (!error && response.status === 200) {
      dispatch(success(id));
      dispatch(notification({ message: 'Xóa dữ liệu thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Xóa dữ liệu thất bại', type: 'error' }));
    }
  };

  function loading() { return { type: CRUD_TYPE.DELETE_CRUD_LOADING } }
  function success(id) { return { type: CRUD_TYPE.DELETE_CRUD_SUCCESS, model, id } }
  function failure(error) { return { type: CRUD_TYPE.DELETE_CRUD_FAILURE, error } }
}