import { HEADERS } from '.';
import { KPTYPE } from '../components/KPTComponents/KPTextform/KPTextform';

export const FORM_API = {
  getApplicationFormById: (id) => ({
    endPoint: `/api/v1/form/${id}`,
    headers: HEADERS.DEFAULT_HEADER,
    method: 'GET',
  }),
  getAllApplicationForm: () => ({
    endPoint: '/api/v1/form/all',
    headers: HEADERS.DEFAULT_HEADER,
    method: 'GET',
  }),
  insertApplicationForm: () => ({
    endPoint: '/api/v1/form/create',
    headers: HEADERS.DEFAULT_HEADER,
    method: 'POST',
  }),
  updateApplicationForm: () => ({
    endPoint: '/api/v1/form/update',
    headers: HEADERS.DEFAULT_HEADER,
    method: 'PUT',
  }),
  filterApplicationForm: () => ({
    endPoint: '/api/v1/form/filter',
    headers: HEADERS.DEFAULT_HEADER,
    method: 'POST',
  }),
};

export const FORM_TYPE = {
  GET_APPLICATION_FORM_BY_ID_LOADING: 'GET_APPLICATION_FORM_BY_ID_LOADING',
  GET_APPLICATION_FORM_BY_ID_SUCCESS: 'GET_APPLICATION_FORM_BY_ID_SUCCESS',
  GET_APPLICATION_FORM_BY_ID_FAILURE: 'GET_APPLICATION_FORM_BY_ID_FAILURE',

  GET_ALL_APPLICATION_FORM_LOADING: 'GET_ALL_APPLICATION_FORM_LOADING',
  GET_ALL_APPLICATION_FORM_SUCCESS: 'GET_ALL_APPLICATION_FORM_SUCCESS',
  GET_ALL_APPLICATION_FORM_FAILURE: 'GET_ALL_APPLICATION_FORM_FAILURE',

  INSERT_APPLICATION_FORM_LOADING: 'INSERT_APPLICATION_FORM_LOADING',
  INSERT_APPLICATION_FORM_SUCCESS: 'INSERT_APPLICATION_FORM_SUCCESS',
  INSERT_APPLICATION_FORM_FAILURE: 'INSERT_APPLICATION_FORM_FAILURE',

  UPDATE_APPLICATION_FORM_LOADING: 'UPDATE_APPLICATION_FORM_LOADING',
  UPDATE_APPLICATION_FORM_SUCCESS: 'UPDATE_APPLICATION_FORM_SUCCESS',
  UPDATE_APPLICATION_FORM_FAILURE: 'UPDATE_APPLICATION_FORM_FAILURE',

  FILTER_APPLICATION_FORM_LOADING: 'FILTER_APPLICATION_FORM_LOADING',
  FILTER_APPLICATION_FORM_SUCCESS: 'FILTER_APPLICATION_FORM_SUCCESS',
  FILTER_APPLICATION_FORM_FAILURE: 'FILTER_APPLICATION_FORM_FAILURE',
};

const formStatus = {
  draft: { value: 'draft', label: 'Chưa nộp' },
  submitted: { value: 'submitted', label: 'Đã nộp' },
}

export const FORM_CONSTANTS = {
  CATEGORY_OPTIONS: [
    { key: 'gs', title: 'Giáo sư', value: 'gs' },
    { key: 'pgs', title: 'Phó giáo sư', value: 'pgs' },
  ],
  STATUS_MAP: formStatus,
  STATUS_OPTIONS: [
    { key: formStatus.draft.value, value: formStatus.draft.value, title: formStatus.draft.label },
    { key: formStatus.submitted.value, value: formStatus.submitted.value, title: formStatus.submitted.label },
  ],
  ENTITY_OPTIONS: [
    { key: 'gv', title: 'Giảng viên', value: 'gv' },
    { key: 'gvtg', title: 'Giảng viên thỉnh giảng', value: 'gvtg' },
  ],
  LANGUAGE_OPTIONS: [
    { value: "english", title: "Tiếng Anh", key: "english" },
    { value: "french", title: "Tiếng Pháp", key: "french" },
    { value: "german", title: "Tiếng Đức", key: "german" },
    { value: "russian", title: "Tiếng Nga", key: "russian" },
    { value: "spanish", title: "Tiếng Tây ban nha", key: "spanish" },
    { value: "other", title: "Khác", key: "other" }
  ],
  MAJOR_OPTIONS: [
    { value: "economics", title: "Ngành Kinh tế học", key: "economics" },
    { value: "business", title: "Ngành Kinh doanh", key: "business" },
    { value: "finance", title: "Ngành Tài chính-Ngân hàng-Bảo hiểm", key: "finance" },
    { value: "accounting", title: "Ngành Kế toán-Kiểm toán", key: "accounting" },
    { value: "management", title: "Ngành Quản trị-Quản lý", key: "management" }
  ],
  SPECIALIZATION_OPTIONS: {
    economics: [
      { value: "economics", title: "Kinh tế học", key: "economics" },
      { value: "political_economy", title: "Kinh tế chính trị", key: "political_economy" },
      { value: "public_policy", title: "Chính sách công", key: "public_policy" },
      { value: "investment_economics", title: "Kinh tế đầu tư", key: "investment_economics" },
      { value: "development_economics", title: "Kinh tế phát triển", key: "development_economics" },
      { value: "international_economics", title: "Kinh tế quốc tế", key: "international_economics" },
      { value: "economic_statistics", title: "Thống kê kinh tế", key: "economic_statistics" },
      { value: "economic_math", title: "Toán kinh tế", key: "economic_math" },
      { value: "economic_management", title: "Quản lý kinh tế", key: "economic_management" },
      { value: "economics_fields", title: "Kinh tế và các lĩnh vực", key: "economics_fields" },
      { value: "rural_development", title: "Phát triển nông thôn", key: "rural_development" },
      { value: "agricultural_systems", title: "Hệ thống nông nghiệp", key: "agricultural_systems" }
    ],
    business: [
      { value: "business", title: "Kinh doanh", key: "business" },
      { value: "business_management", title: "Quản trị kinh doanh", key: "business_management" },
      { value: "trade_business", title: "Kinh doanh thương mại", key: "trade_business" },
      { value: "marketing", title: "Marketing", key: "marketing" },
      { value: "commerce", title: "Thương mại", key: "commerce" },
      { value: "human_resource_management", title: "Quản trị nhân lực", key: "human_resource_management" },
      { value: "international_business", title: "Kinh doanh quốc tế", key: "international_business" },
      { value: "business_fields", title: "Kinh doanh các ngành và lĩnh vực", key: "business_fields" }
    ],
    finance: [
      { value: "finance_banking", title: "Tài chính-ngân hàng", key: "finance_banking" },
      { value: "public_finance", title: "Tài chính công", key: "public_finance" },
      { value: "insurance", title: "Bảo hiểm", key: "insurance" },
      { value: "customs", title: "Hải quan", key: "customs" },
      { value: "international_finance", title: "Tài chính quốc tế", key: "international_finance" },
      { value: "financial_markets", title: "Thị trường và các định chế tài chính", key: "financial_markets" }
    ],
    accounting: [
      { value: "accounting", title: "Kế toán", key: "accounting" },
      { value: "audit", title: "Kiểm toán", key: "audit" },
      { value: "business_accounting", title: "Kế toán doanh nghiệp", key: "business_accounting" },
      { value: "unit_accounting", title: "Kế toán đơn vị sự nghiệp", key: "unit_accounting" },
      { value: "public_accounting", title: "Kế toán công", key: "public_accounting" }
    ],
    management: [
      { value: "management_science", title: "Khoa học quản lý", key: "management_science" },
      { value: "public_management", title: "Quản lý công", key: "public_management" },
      { value: "human_resource_management", title: "Quản trị nhân lực", key: "human_resource_management" },
      { value: "management_information_systems", title: "Hệ thống thông tin quản lý", key: "management_information_systems" },
      { value: "office_management", title: "Quản trị văn phòng", key: "office_management" },
      { value: "science_technology_management", title: "Quản lý khoa học và công nghệ", key: "science_technology_management" },
      { value: "occupational_health_management", title: "Quản lý an toàn và sức khỏe nghề nghiệp", key: "occupational_health_management" },
      { value: "industrial_management", title: "Quản lý công nghiệp", key: "industrial_management" },
      { value: "energy_management", title: "Quản lý năng lượng", key: "energy_management" },
      { value: "logistics_supply_chain", title: "Logistics và chuỗi cung ứng", key: "logistics_supply_chain" }
    ]
  }
};

export const RESEARCH_DIRECTION_MODEL = {
  title: { key: 'title', label: 'Tên hướng nghiên cứu', type: KPTYPE.textarea, height: 1, required: true },
  description: { key: 'description', label: 'Chi tiết', type: KPTYPE.textarea, height: 1 },
}

export const FORM_MODEL = {
  category: { key: 'category', label: 'Đăng ký xét đạt tiêu chuẩn chức danh', type: KPTYPE.select, options: FORM_CONSTANTS.CATEGORY_OPTIONS, required: true },
  entity: { key: 'entity', label: 'Đối tượng đăng ký', type: KPTYPE.select, options: FORM_CONSTANTS.ENTITY_OPTIONS, required: true },
  major: { key: 'major', label: 'Ngành', type: KPTYPE.select, options: FORM_CONSTANTS.MAJOR_OPTIONS, required: true },
  specialization: { key: 'specialization', label: 'Chuyên ngành', required: true },

  photoUrl: { key: 'specialization', label: 'Chuyên ngành', type: KPTYPE.dropzone, required: true },
  
  selfAssessment: { key: 'selfAssessment', label: '1. Tự đánh giá tiêu chuẩn và nhiệm vụ của nhà giáo', type: KPTYPE.richtext },
  totalEducateYear: { key: 'totalEducateYear', type: KPTYPE.int, label: 'Năm', required: true },
  totalEducateMonth: { key: 'totalEducateMonth', type: KPTYPE.int, label: 'Tháng', required: true },
  
  pastWorkingProcessStartTime: { key: 'pastWorkingProcessStartTime', label: 'Thời gian công tác', type: KPTYPE.date, required: true },
  pastWorkingProcessEndTime: { key: 'pastWorkingProcessEndTime', type: KPTYPE.date, required: true },
  pastWorkingProcessUnit: { key: 'pastWorkingProcessUnit', label: 'Tên cơ quan công tác', required: true },
  pastWorkingProcessUnitAddress: { key: 'pastWorkingProcessUnitAddress', label: 'Địa chỉ cơ quan', required: true },
  pastWorkingProcessPosition: { key: 'pastWorkingProcessPosition', label: 'Chức vụ', required: true },

  currentWorkingProcessPosition: { key: 'currentWorkingProcessPosition', label: 'Chức vụ hiện nay', required: true },
  currentWorkingProcessHighestPosition: { key: 'currentWorkingProcessHighestPosition', label: 'Chức vụ cao nhất đã qua', required: true },
  currentWorkingProcessUnit: { key: 'currentWorkingProcessUnit', label: 'Cơ quan công tác hiện nay', required: true },
  currentWorkingProcessUnitPhone: { key: 'currentWorkingProcessUnitPhone', label: 'Điện thoại cơ quan', required: true },
  currentWorkingProcessUnitAddress: { key: 'currentWorkingProcessUnitAddress', label: 'Địa chỉ cơ quan', required: true },
  currentWorkingProcessUniversity: { key: 'currentWorkingProcessUniversity', label: 'Thỉnh giảng tại cơ sở giáo dục đại học' },
  currentWorkingProcessRetireTime: { key: 'currentWorkingProcessRetireTime', label: 'Đã nghỉ hưu từ', type: KPTYPE.date },
  currentWorkingProcessRetireUnit: { key: 'currentWorkingProcessRetireUnit', label: 'Nơi làm việc sau khi nghỉ hưu' },
  lastThreeYearUniversity: { key: 'lastThreeYearUniversity', label: 'Tên cơ sở giáo dục đại học nơi có hợp đồng thỉnh giảng 3 năm cuối' },

  pgsStartTime: { key: 'pgsStartTime', label: '10. Đã được bổ nhiệm/công nhận chức danh PGS ngày', type: KPTYPE.date },
  pgsMajor: { key: 'pgsMajor', label: 'Ngành', required: true },
  scientificCouncil: { key: 'scientificCouncil', label: '11. Đăng ký xét đạt tiêu chuẩn chức danh tại HĐGS cơ sở', required: true },
  registeredMajor: { key: 'registeredMajor', label: '12. Đăng ký xét đạt tiêu chuẩn chức danh tại HĐGS ngành, liên ngành', required: true },

  foreignLanguage: { key: 'foreignLanguage', label: '3.1. Ngoại ngữ thành thạo phục vụ chuyên môn', type: KPTYPE.select, options: FORM_CONSTANTS.LANGUAGE_OPTIONS, required: true },
  otherForeignLanguage: { key: 'otherForeignLanguage', label: 'Ngoại ngữ khác' },
  foreignLanguageTrainingAbroad: { key: 'foreignLanguageTrainingAbroad', type: KPTYPE.json },
  foreignLanguageTrainingInland: { key: 'foreignLanguageTrainingInland', type: KPTYPE.json },
  teachingInForeignLanguage: { key: 'teachingInForeignLanguage', type: KPTYPE.json },
  otherObject: { key: 'otherObject', type: KPTYPE.json },
  educateProcess: { key: 'educateProcess', type: KPTYPE.json },
  englishCertificate: { key: 'englishCertificate', label: '3.2. Tiếng Anh (văn bằng, chứng chỉ)', required: true },
  foreignLanguageDegreeCertificate: { key: 'foreignLanguageDegreeCertificate', label: 'Minh chứng trình độ ngoại ngữ', type: KPTYPE.dropzone, labelRender: <></>, required: true },

  remainingPGSTime: { key: 'remainingPGSTime', type: KPTYPE.json },
  remainingEducateTime: { key: 'remainingEducateTime', type: KPTYPE.json },
  remainingDirectTeachingTime: { key: 'remainingDirectTeachingTime', type: KPTYPE.json },
  remainingConvertedTeachingTime: { key: 'remainingConvertedTeachingTime', type: KPTYPE.json },

  generalScientificReportProof: { key: 'generalScientificReportProof', label: 'Minh chứng báo cáo khoa học tổng quan', type: KPTYPE.dropzone, filesLimit: 10, required: true },

  trainingAndScientificResearchProof: { key: 'trainingAndScientificResearchProof', label: 'Minh chứng bản xác nhận thực hiện nhiệm vụ đào tạo và nghiên cứu khoa học của giảng viên', type: KPTYPE.dropzone, filesLimit: 10, required: true },
}

