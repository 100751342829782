import { KPTYPE } from '../components/KPTComponents/KPTextform/KPTextform';


export const EDUCATION_PROGRESS_MODEL = {
  schoolYear: { key: 'schoolYear', label: 'Năm học', required: true },
  main: { key: 'main', label: 'Chính', type: KPTYPE.int },
  other: { key: 'other', label: 'Phụ', type: KPTYPE.int },
  master: { key: 'master', label: 'Số lượng ThS/CK2/BSNT đã hướng dẫn', type: KPTYPE.int },
  bachelor: { key: 'bachelor', label: 'Số đồ án, khóa luận tốt nghiệp ĐH đã HD', type: KPTYPE.int },
  // isLastThreeYear: { key: 'isLastThreeYear', label: 'Là 03 năm học cuối', type: KPTYPE.checkbox },
  dh: { key: 'dh', label: 'ĐH', type: KPTYPE.float },
  sdh: { key: 'sdh', label: 'SĐH', type: KPTYPE.float },
  dm: { key: 'dm', label: 'Tổng số giờ chuẩn gd trực tiếp trên lớp/số giờ chuẩn gd quy đổi/số giờ chuẩn định mức (Vui lòng nhập với định dạng xx/yy/zz (VD: 100/200/300))', required: true },
  proof: { key: 'proof', label: 'Minh chứng', type: KPTYPE.dropzone },
}
