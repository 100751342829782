import React, { useEffect, useState } from 'react';
import { Close } from '@mui/icons-material';
import {
  Box,
  Button,
  Dialog,
  Divider,
  IconButton,
  Typography,
  DialogTitle,
  DialogActions,
  DialogContent,
  Grid,
} from '@mui/material';

import { CRUD_LABELS, ROUTER, SCIENTIFIC_RESEARCH_CONSTANTS, USER_CONSTANTS } from '../../../constants';

import CrudForm from '../../../components/Crud/CrudForm';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

export default function ThanhTichInsert({ dialog, setDialog }) {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  
  const profile = useSelector(state => state.profileReducer.profile);
  const currentUrl = window.location.href;
  const isChamDiemScene = currentUrl.includes(ROUTER.DANH_SACH_THANH_TICH);

  useEffect(() => {
    if (dialog) setOpen(true);
  }, [dialog]);

  const handleClose = () => {
    setOpen(false);

    // Đóng modal rồi mới clear dữ liệu
    setTimeout(() => setDialog(null), 500);
  };

  return (
    <Dialog
      fullWidth
      maxWidth={(dialog?.filterType === 'international' && isChamDiemScene) ? 'lg' : 'sm'}
      open={open}
    >
      <DialogTitle style={{ padding: '4px 12px 4px 24px' }}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='h6'>
            <b>
              {dialog?.filterType ? (
                SCIENTIFIC_RESEARCH_CONSTANTS.FILTER_TYPE.find((e) => (e.key === dialog.filterType))?.title
              ) : (
                CRUD_LABELS[dialog?.type] || 'Thêm thành tích'
              )}
            </b>
          </Typography>
          <IconButton onClick={handleClose}><Close /></IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent>
        {(dialog?.filterType === 'international' && isChamDiemScene) ? (
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <CrudForm {...dialog} handleClose={handleClose} disabled={true} />
            </Grid>
            <Grid item xs={6}>
              <CrudForm {...dialog} handleClose={handleClose} />
            </Grid>
          </Grid>
        ) : (
          <CrudForm {...dialog} handleClose={handleClose} />
        )}
      </DialogContent>
      {(dialog?.filterType === 'international' && isChamDiemScene) && <Divider />}
      <DialogActions style={{ padding: '8px 0' }}>
        <Grid container>
          {(dialog?.filterType === 'international' && isChamDiemScene) && (
            <>
              <Grid item xs={6}>
                <Typography align='center'><b>A: Bản khai của ứng viên</b></Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography align='center'><b>B: Bản kiểm tra của phản biện</b></Typography>
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <Box sx={{ float: 'right' }}>
              <Button variant='outlined' onClick={handleClose}>Huỷ</Button>
              <Button
                form='thanh-tich-insert'
                variant='contained'
                color='primary'
                type='submit'
                sx={{ mr: 3, ml: 1 }}
                // disabled={currentUrl.includes(ROUTER.CHI_TIET_HO_SO) && !!id}
                disabled={!!id && profile?.userRole !== USER_CONSTANTS.ROLE.super_admin}
              >
                Lưu
              </Button>
            </Box>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  );
}
