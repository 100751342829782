import React from 'react';

import KPTable from '../../../../components/KPTComponents/KPTable';

import { ActionColumn } from '../../../../containers/DangKyHoSo/utils';
import { DISCIPLINE_MODEL, CRUD_MODELS } from '../../../../constants';

function KyLuatTable({ rows, setDialog, ...props }) {
  const columns = [
    { ...DISCIPLINE_MODEL.title },
    { ...DISCIPLINE_MODEL.level },
    { ...DISCIPLINE_MODEL.number },
    {
      key: 'link',
      width: '20%',
      label: 'Tác vụ',
      render: (_, row) => (
        <ActionColumn
          row={row}
          type={CRUD_MODELS.DISCIPLINE}
          setDialog={setDialog}
          setDeleteCrud={props.setDeleteCrud}
        />
      )
    }
  ];

  return (
    <KPTable
      rows={rows}
      columns={columns}
      nonBorder={!!props.nonBorder}
    />
  );
}

export default KyLuatTable;
