import { JOURNAL_DOMESTIC_TYPE } from '../constants';

const initialState = {
  list: [],
  loading: false,
}

export function journalDomesticReducer(state = initialState, action) {
  switch (action.type) {
    case JOURNAL_DOMESTIC_TYPE.FILTER_JOURNAL_DOMESTIC_LOADING:
      return { ...state, loading: true };

    case JOURNAL_DOMESTIC_TYPE.FILTER_JOURNAL_DOMESTIC_FAILURE:
      return { ...state, loading: false };

    case JOURNAL_DOMESTIC_TYPE.FILTER_JOURNAL_DOMESTIC_SUCCESS:
      return { ...state, loading: false, list: action.payload };
  
    default:
      return state
  }
}
