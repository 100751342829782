import { HEADERS } from '.';

export const SCORING_TABLE_TYPE = {
  INSERT_SCORING_TABLE_LOADING: 'INSERT_SCORING_TABLE_LOADING',
  INSERT_SCORING_TABLE_SUCCESS: 'INSERT_SCORING_TABLE_SUCCESS',
  INSERT_SCORING_TABLE_FAILURE: 'INSERT_SCORING_TABLE_FAILURE',

  UPDATE_SCORING_TABLE_LOADING: 'UPDATE_SCORING_TABLE_LOADING',
  UPDATE_SCORING_TABLE_SUCCESS: 'UPDATE_SCORING_TABLE_SUCCESS',
  UPDATE_SCORING_TABLE_FAILURE: 'UPDATE_SCORING_TABLE_FAILURE',
}

export const SCORING_TABLE_API = {
  insert: () => ({
    endPoint: `/api/v1/scoringTable/create`,
    headers: HEADERS.DEFAULT_HEADER,
    method: 'POST',
  }),
  update: () => ({
    endPoint: `/api/v1/scoringTable/update`,
    headers: HEADERS.DEFAULT_HEADER,
    method: 'PUT',
  }),
}
