import React, { useEffect, useMemo } from 'react';
import { Box } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';

import KPTable from '../../../components/KPTComponents/KPTable';

import { journalDomesticActions } from '../../../actions';
import { defaultRows, SCORING_INFO_MODEL } from '../../../constants/scoringInformation.constants';
import { CRUD_MODELS } from '../../../constants';

function BangTinhDiem(props) {
  const dispatch = useDispatch();
  const journalDomestics = useSelector(state => state.journalDomesticReducer.list);

  useEffect(() => {
    dispatch(journalDomesticActions.filterJournalDomestic({
		page: 1, pageSize: 10000, percent: 0, title: ''
	  }));
  }, []);

  const rows = useMemo(() => {
    return [
      ...defaultRows,
      ...journalDomestics.map((journal) => ({
        ...journal,
        type: CRUD_MODELS.SCIENTIFIC_RESEARCH,
        description: journal.publisher,
      }))
    ];
  }, [defaultRows, journalDomestics]);

  const columns = [
    { ...SCORING_INFO_MODEL.title, width: '30%' },
    { ...SCORING_INFO_MODEL.issn, width: '15%' },
    {
      ...SCORING_INFO_MODEL.type,
      width: '20%',
      filters: SCORING_INFO_MODEL.type.options,
      render: (value) => SCORING_INFO_MODEL.type.options.find((e) => e.value === value)?.title,
      onFilter: (value, row) => row.type === value,
	},
    { ...SCORING_INFO_MODEL.description, width: '20%' },
    {
      ...SCORING_INFO_MODEL.score,
      width: '15%',
      render: (value) => value?.split('\n').map((e) => <Box>{e}</Box>)
    },
  ];

	return (
    <Box my={4}>
      <KPTable
        columns={columns}
        rows={rows}
      />
      {/* <Grid item xs={5}>
        <Typography variant='h5' style={{ lineHeight: '42px' }}>
          <b>Bảng tính điểm</b>
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <FormControl size='small' variant='outlined' fullWidth>
          <InputLabel>Năm tổ chức</InputLabel>
          <Select
            fullWidth
            value={seasonId || 0}
            MenuProps={{
              getContentAnchorEl: null,
              MenuListProps: { style: { padding: 0 } },
              PaperProps: { style: { left: 8 } },
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
              transformOrigin: { vertical: 'top', horizontal: 'center' },
            }}
            onChange={(e) => setSeasonId(e.target.value)}
            label='Năm tổ chức'
          >
            {seasonList.map((e) => (
              <MenuItem key={e.id} value={e.id}>
                {e.year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={5}>
        <Box style={{ float: 'right', padding: '3px 0' }}>
          <Button variant='outlined' startIcon={<Replay />} onClick={handleReload}>
            Tải lại
          </Button>
        </Box>
      </Grid> */}

			{/* <Grid item xs={12}><Divider /></Grid>

			<Grid item xs={12}>
				<KPTable
					loading={loadingScoreInformation}
					rows={scoreInformationList}
					columns={[
						{
							title: <b>STT</b>,
							dataIndex: 'stt',
							width: '10%',
							render: (value, row) => (
								(selectedRow?.id === row?.id) ? (
									<form onSubmit={handleSubmit}>
										<TextField
											autoFocus
											fullWidth
            					type='int'
											size='small'
											variant='outlined'
											defaultValue={value}
											inputProps={{ style: { fontSize: 14 } }}
											onChange={(e) => setSelectedRow({
												...selectedRow,
												stt: parseInt(e.target.value)
											})}
										/>
									</form>
								) : (
									value
								)
							)
						},
						{
							title: <b>Mục</b>,
							dataIndex: 'code',
							width: '10%',
							render: (value, row) => (
								(selectedRow?.id === row?.id) ? (
									<form onSubmit={handleSubmit}>
										<TextField
											autoFocus
											fullWidth
            					// type='int'
											size='small'
											variant='outlined'
											defaultValue={value}
											inputProps={{ style: { fontSize: 14 } }}
											onChange={(e) => setSelectedRow({
												...selectedRow,
												code: e.target.value
											})}
										/>
									</form>
								) : (
									value
								)
							)
						},
						{
							title: <b>Nội dung</b>,
							dataIndex: 'description',
							width: '60%',
							render: (value, row) => (
								(selectedRow?.id === row?.id) ? (
									<form onSubmit={handleSubmit}>
										<TextField
											autoFocus
											fullWidth
            					type='int'
											size='small'
											variant='outlined'
											defaultValue={value}
											inputProps={{ style: { fontSize: 14 } }}
											onChange={(e) => setSelectedRow({
												...selectedRow,
												description: e.target.value
											})}
										/>
									</form>
								) : (
									value
								)
							)
						},
						{
							title: <b>Điểm</b>,
							dataIndex: 'value',
							width: '20%',
							render: (value, row) => (
								(selectedRow?.id === row?.id) ? (
									<form onSubmit={handleSubmit}>
										<TextField
											autoFocus
											fullWidth
            					type='int'
											size='small'
											variant='outlined'
											defaultValue={value}
											inputProps={{ style: { fontSize: 14 } }}
											onChange={(e) => setSelectedRow({
												...selectedRow,
												value: parseInt(e.target.value)
											})}
										/>
									</form>
								) : (
									value
								)
							)
						},
						{
							title: <b>Tác vụ</b>,
							dataIndex: 'actions',
							width: '10%',
							render: (_, row) => (
								(selectedRow?.id !== row?.id) ? (
									<Typography
										variant='body2'
										color={'primary'}
										className='actions-button'
										onClick={() => setSelectedRow(row)}
										// style={{ lineHeight: '41px' }}
									>
										Sửa
									</Typography>
								) : (
									<Box display='flex'>
										<Typography
											variant='body2'
											color='primary'
											className='actions-button'
											onClick={handleSubmit}
										>
											Lưu
										</Typography>
										<Typography
											variant='body2'
											color='secondary'
											className='actions-button'
											style={{ marginLeft: 8 }}
											onClick={() => setSelectedRow(null)}
										>
											Huỷ
										</Typography>
									</Box>
								)
							),
						},
					]}
				/>
			</Grid> */}
		</Box>
	)
}

export default BangTinhDiem;
