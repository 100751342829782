import { apiCall } from '../utils';
import { ROUTER, USER_API, USER_TYPE } from '../constants';

export const userActions = {
  notification,
  login,
  logout,
  register,
  refreshToken,
  resetByEmail,
  changePassword,
  setLoadingGlobal,
  filter,
  changeRole,
};


function login(payload) {
  return async(dispatch) => {
    dispatch(loading());

    const api = USER_API.login();
    const { response, error } = await apiCall({ ...api, payload });

    if (!error && response.status === 200) {
      dispatch(success(response.data.data));
      dispatch(notification({ message: 'Đăng nhập thành công', type: 'success' }));
      return true;
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Sai tên đăng nhập hoặc mật khẩu', type: 'error' }));
      return false;
    }
  };
  function loading() { return { type: USER_TYPE.LOGIN_LOADING } }
  function success(payload) { return { type: USER_TYPE.LOGIN_SUCCESS, payload } }
  function failure(error) { return { type: USER_TYPE.LOGIN_FAILURE, error } }
}

function logout() {
  return async(dispatch) => {
    dispatch(loading());

    const api = USER_API.logout();
    const { response, error } = await apiCall(api);

    if (!error && response.status === 200) {
      dispatch(success(response.data.data));
      dispatch(notification({ message: 'Đăng xuất thành công', type: 'success' }));
      return true;
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Đăng xuất thất bại', type: 'error' }));
      return false;
    }
  };
  function loading() { return { type: USER_TYPE.LOGOUT_LOADING } }
  function success(payload) { return { type: USER_TYPE.LOGOUT_SUCCESS, payload } }
  function failure(error) { return { type: USER_TYPE.LOGOUT_FAILURE, error } }
}

function register(payload) {
  return async(dispatch) => {
    dispatch(loading());

    const api = USER_API.register();
    const { response, error } = await apiCall({ ...api, payload });

    if (!error && response.status === 200) {
      dispatch(success(response.data.data));
      dispatch(notification({ message: 'Đăng ký tài khoản thành công', type: 'success' }));
      return response.data.data;
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Đăng ký tài khoản thất bại', type: 'error' }));
      return null;
    }
  };
  function loading() { return { type: USER_TYPE.REGISTER_LOADING } }
  function success(payload) { return { type: USER_TYPE.REGISTER_SUCCESS, payload } }
  function failure(error) { return { type: USER_TYPE.REGISTER_FAILURE, error } }
}

function refreshToken() {
  return async(dispatch) => {
    dispatch(loading());

    const api = USER_API.refreshToken();
    const { response, error } = await apiCall({ ...api });

    if (!error && response.status === 200) {
      dispatch(success(response.data.data));
      return true;
    } else {
      dispatch(logout())
      dispatch(failure(error));
      return false;
    }
  };
  function loading() { return { type: USER_TYPE.LOGIN_LOADING } }
  function success(payload) { return { type: USER_TYPE.LOGIN_SUCCESS, payload } }
  function failure(error) { return { type: USER_TYPE.LOGIN_FAILURE, error } }
}

function resetByEmail(email) {
  return async(dispatch) => {
    dispatch(loading());

    const api = USER_API.resetByEmail(email);
    const { response, error } = await apiCall(api);

    if (!error && response.status === 200) {
      dispatch(success(response.data.data));
      return true;
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Khôi phục mật khẩu thất bại!', type: 'error' }));
      return false;
    }
  };
  function loading() { return { type: USER_TYPE.RESET_BY_EMAIL_LOADING } }
  function success(payload) { return { type: USER_TYPE.RESET_BY_EMAIL_SUCCESS, payload } }
  function failure(error) { return { type: USER_TYPE.RESET_BY_EMAIL_FAILURE, error } }
}

function changePassword(payload) {
  return async(dispatch) => {
    dispatch(loading());

    const api = USER_API.changePassword();
    const { response, error } = await apiCall({ ...api, payload });

    if (!error && response.status === 200) {
      dispatch(logout())
      dispatch(success());
      dispatch(notification({ message: 'Đổi mật khẩu thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Đổi mật khẩu thất bại', type: 'error' }));
    }
  };

  function loading() { return { type: USER_TYPE.CHANGE_PASSWORD_LOADING } }
  function success() { return { type: USER_TYPE.CHANGE_PASSWORD_SUCCESS } }
  function failure(error) { return { type: USER_TYPE.CHANGE_PASSWORD_FAILURE, error } }
}

function filter(payload) {
  return async(dispatch) => {
    dispatch(loading());

    const api = USER_API.filter();
    const { response, error } = await apiCall({ ...api, payload });

    if (!error && response.status === 200) {
      dispatch(success(response.data.data));
      dispatch(notification({ message: 'Lấy dữ liệu thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Lấy dữ liệu thất bại', type: 'error' }));
    }
  };

  function loading() { return { type: USER_TYPE.FILTER_USER_LOADING } }
  function success(payload) { return { type: USER_TYPE.FILTER_USER_SUCCESS, payload } }
  function failure(error) { return { type: USER_TYPE.FILTER_USER_FAILURE, error } }
}

function changeRole(payload) {
  return async(dispatch) => {
    dispatch(loading());

    const api = USER_API.changeRole();
    const { response, error } = await apiCall({ ...api, payload });

    if (!error && response.status === 200) {
      dispatch(success(payload));
      dispatch(notification({ message: 'Cập nhật dữ liệu thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Cập nhật dữ liệu thất bại', type: 'error' }));
    }
  };

  function loading() { return { type: USER_TYPE.CHANGE_ROLE_LOADING } }
  function success(payload) { return { type: USER_TYPE.CHANGE_ROLE_SUCCESS, payload } }
  function failure(error) { return { type: USER_TYPE.CHANGE_ROLE_FAILURE, error } }
}

function notification(payload) {
  return { type: USER_TYPE.NOTIFICATION, payload };
}

function setLoadingGlobal(loading) {
  return { type: USER_TYPE.SET_LOADING_GLOBAL, loading };
}
