import React from 'react';

import KPTable from '../../../../components/KPTComponents/KPTable';

import { ActionColumn } from '../../../../containers/DangKyHoSo/utils';
import { CRUD_MODELS, PROGRAM_MODEL } from '../../../../constants';

function ChuongTrinhTable({ rows, setDialog, ...props }) {
  const columns = [
    { ...PROGRAM_MODEL.title, width: '50%' },
    {
      ...PROGRAM_MODEL.role,
      width: '30%',
      filters: PROGRAM_MODEL.role.options,
      render: (value) => PROGRAM_MODEL.role.options.find((e) => e.value === value)?.title,
      onFilter: (value, row) => row.role === value,
    },
    {
      key: 'link',
      width: '20%',
      label: 'Tác vụ',
      render: (_, row) => (
        <ActionColumn
          row={row}
          type={CRUD_MODELS.PROGRAM}
          setDialog={setDialog}
          setPreview={props.setPreview}
          setDeleteCrud={props.setDeleteCrud}
        />
      )
    }
  ];

  return (
    <KPTable
      rows={rows}
      columns={columns}
      nonBorder={!!props.nonBorder}
    />
  );
}

export default ChuongTrinhTable;
