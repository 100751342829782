import React, { Fragment, useEffect, useState } from "react";
import { Add, Replay } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { Box, Card, CardContent, CardHeader, Divider, IconButton, Typography } from "@mui/material";

import KPTable from "../../../components/KPTComponents/KPTable";
import KPTextform from "../../../components/KPTComponents/KPTextform";
import KyLuatTable from "../../Crud/KyLuat/KyLuatTable";
import BangCapTable from "../../Crud/BangCap/BangCapTable";
import KhenThuongTable from "../../Crud/KhenThuong/KhenThuongTable";

import { apiCall } from "../../../utils";
import { textForm } from "../utils";
import { crudActions, userActions } from "../../../actions";
import { RESEARCH_DIRECTION_MODEL, CRUD_MODELS, CRUD_LABELS, HEADERS, USER_CONSTANTS } from "../../../constants";
import { useParams } from "react-router-dom";


function MucA({
  error,
  values,
  setValues,
  setDialog,
  profileValues,
  setPreview,
  setDeleteCrud,
  setProfileValues,
}) {
  const { id } = useParams();
  const dispatch = useDispatch();

  const {
    form,
    rewards,
    disciplines,
    educateDegrees,
    researchDirections,
  } = useSelector((state) => state.formReducer);
  const profile = useSelector(state => state.profileReducer.profile);

  const disabled = !!id && profile?.userRole !== USER_CONSTANTS.ROLE.super_admin;

  const [stat, setStat] = useState({});

  const handleReloadStat = async (formId) => {
    dispatch(userActions.setLoadingGlobal(true));

    const { response, error } = await apiCall({
      endPoint: '/api/v1/researchDirection/statistic/' + formId,
      method: 'GET',
      headers: HEADERS.DEFAULT_HEADER,
    });

    if (!error && response.status === 200) {
      const item = {
        so_bai_bao: 0,
        so_bai_uy_tin: 0,
        so_dtkh: 0,
        so_huong_dan: 0,
        so_ncs_phu: 0,
        so_ncs_chinh: 0,
        so_hv_chinh: 0,
        so_hv_phu: 0,
        so_sach: 0,
      };
      response.data.data?.forEach((direction) => {
        item.so_bai_bao += direction.so_bai_bao;
        item.so_bai_uy_tin += direction.so_bai_uy_tin;
        item.so_dtkh += direction.so_dtkh;
        item.so_huong_dan += direction.so_huong_dan;
        item.so_ncs_chinh += direction.so_ncs_chinh;
        item.so_ncs_phu += direction.so_ncs_phu;
        item.so_hv_chinh += direction.so_hv_chinh;
        item.so_hv_phu += direction.so_hv_phu;
        item.so_sach += direction.so_sach;
      });
      setStat(item);
    } else {
      dispatch(userActions.notification({ message: 'Lấy dữ liệu mục 14 thất bại', type: 'error' }));
    }

    dispatch(userActions.setLoadingGlobal(false));
  }

  useEffect(() => {
    if (form) handleReloadStat(form.id);
  }, [form]);


  const renderNum = (num) => (typeof(num) == 'number' && !isNaN(num)) ? num : '...';

  return (
    <Fragment>
      <Card id="mucA_muc1_6" style={{ width: '100%', marginTop: 16 }}>
        <CardContent>
          <KPTextform
            component={Box}
            error={error}
            values={profileValues}
            setValues={setProfileValues}
            textForm={textForm.mucA.muc1_6}
          />
        </CardContent>
      </Card>
       
      <Card id="mucA_muc7_8" style={{ width: '100%', marginTop: 16 }}>
        <CardHeader sx={{ py: 1 }} title={<Typography className='kpt-textfield-label'><b>7. Quá trình công tác trước đây</b></Typography>} />
        <Divider />
        <CardContent>
          <KPTextform
            component={Box}
            error={error}
            values={values}
            textForm={textForm.mucA.muc7}
            setValues={setValues}
          />
        </CardContent>
      </Card>

      <Card style={{ width: '100%', marginTop: 16 }}>
        <CardHeader sx={{ py: 1 }} title={<Typography className='kpt-textfield-label'><b>8. Quá trình công tác hiện tại</b></Typography>} />
        <Divider />
        <CardContent>
          <KPTextform
            component={Box}
            error={error}
            values={values}
            textForm={textForm.mucA.muc8}
            setValues={setValues}
          />
        </CardContent>
      </Card>

      <Card id="mucA_muc9" style={{ width: '100%', marginTop: 16 }}>
        <CardHeader sx={{ py: 1, pr: 1 }} title={
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography className='kpt-textfield-label'><b>9. Trình độ đào tạo</b></Typography>
            <IconButton disabled={disabled} color='primary' onClick={() => setDialog({ defaultValues: {}, type: CRUD_MODELS.EDUCATE_DEGREE })}><Add /></IconButton>
          </Box>
        } />
        <BangCapTable
          nonBorder={true}
          setDialog={setDialog}
          rows={educateDegrees}
          setPreview={setPreview}
          setDeleteCrud={setDeleteCrud}
        />
      </Card>

      <Card id='mucA_muc10_13' style={{ width: '100%', marginTop: 16 }}>
        <CardContent>
          <KPTextform
            component={Box}
            error={error}
            values={values}
            textForm={textForm.mucA.muc10_13}
            setValues={setValues}
          />
        </CardContent>
      </Card>

      <Card style={{ width: '100%', marginTop: 16 }}>
        <CardHeader sx={{ py: 1, pr: 1 }} title={
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography className='kpt-textfield-label'><b>13. Các hướng nghiên cứu chủ yếu</b></Typography>
            <IconButton disabled={disabled} color='primary' onClick={() => setDialog({ defaultValues: {}, type: CRUD_MODELS.RESEARCH_DIRECTION })}><Add /></IconButton>
          </Box>
        } />
        <Divider />
        <KPTable
          nonBorder={true}
          columns={[
            { ...RESEARCH_DIRECTION_MODEL.title, width: '80%' },
            // { ...RESEARCH_DIRECTION_MODEL.description, width: '80%' },
            {
              title: <b>Tác vụ</b>,
              dataIndex: 'proof',
              width: '20%',
              render: (value, row) => (
                <>
                  <Typography
                    className='action-btn'
                    variant='body2'
                    color='primary'
                    onClick={() => {
                      setDialog({ type: CRUD_MODELS.RESEARCH_DIRECTION, defaultValues: row });
                    }}
                  >
                    Chi tiết
                  </Typography>
                  {!disabled && (
                    <Typography className='action-btn' variant='body2' color='error' onClick={() => {
                      dispatch(crudActions.remove(CRUD_MODELS.RESEARCH_DIRECTION, row?.id));
                    }}>
                      Xóa
                    </Typography>
                  )}
                </>
              ),
            }
          ]}
          rows={researchDirections}
        />
      </Card>

      <Card id='mucA_muc14' style={{ width: '100%', marginTop: 16 }}>
        <CardHeader sx={{ py: 1, pr: 1 }} title={
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography className='kpt-textfield-label'><b>14. Kết quả đào tạo và nghiên cứu khoa học</b></Typography>
            <IconButton color='primary' onClick={() => handleReloadStat(form.id)}><Replay /></IconButton>
          </Box>
        } />
        <Divider />
        <CardContent sx={{ py: '0 !important' }}>
          <p>- Đã hướng dẫn <b>{renderNum(stat?.so_ncs_chinh + stat?.so_ncs_phu)}</b> NCS bảo vệ thành công luận án TS;</p>
          <p>- Đã hướng dẫn <b>{renderNum(stat?.so_hv_chinh + stat?.so_hv_phu)}</b> HVCH/CK2/BSNT bảo vệ thành công luận án ThS/CK2/BSNT (ứng viên chức danh GS không cần kê khai nội dung này);</p>
          <p>- Đã hoàn thành <b>{renderNum(stat?.so_dtkh)}</b> đề tài NCKH từ cấp cơ sở trở lên;</p>
          <p>- Đã công bố <b>{renderNum(stat?.so_bai_bao)}</b> bài báo khoa học, trong đó <b>{renderNum(stat?.so_bai_uy_tin)}</b> bài báo khoa học trên tạp chí quốc tế có uy tín;</p>
          <p>- Đã được cấp <b>{renderNum(stat?.xxxx)}</b> bằng sáng chế, giải pháp hữu ích;</p>
          <p>- Số lượng sách đã xuất bản <b>{renderNum(stat?.so_sach)}</b>, trong đó <b>{renderNum(stat?.xxxx)}</b> thuộc nhà xuất bản có uy tín;</p>
          <p>- Số lượng tác phẩm nghệ thuật, thành tích huấn luyện, thi đấu thể dục, thể thao đạt giải thưởng quốc gia, quốc tế: <b>{renderNum(stat?.xxxx)}</b>.</p>
          <p><b>Lưu ý:</b> Vui lòng chọn Hướng nghiên cứu của các công trình nghiên cứu để liên kết đến Thông tin tại đây</p>
        </CardContent>
      </Card>

      <Card id='mucA_muc15' style={{ width: '100%', marginTop: 16 }}>
        <CardHeader sx={{ py: 1, pr: 1 }} title={
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography className='kpt-textfield-label'><b>15. {CRUD_LABELS[CRUD_MODELS.REWARD]}</b></Typography>
            <IconButton disabled={disabled} color='primary' onClick={() => setDialog({ defaultValues: {}, type: CRUD_MODELS.REWARD })}><Add /></IconButton>
          </Box>
        } />
        <KhenThuongTable
          nonBorder={true}
          rows={rewards}
          setDialog={setDialog}
          setPreview={setPreview}
          setDeleteCrud={setDeleteCrud}
        />
      </Card>

      <Card id='mucA_muc16' style={{ width: '100%', marginTop: 16 }}>
        <CardHeader sx={{ py: 1, pr: 1 }} title={
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Typography className='kpt-textfield-label'><b>16. Kỷ luật</b></Typography>
            <IconButton disabled={disabled} color='primary' onClick={() => setDialog({ defaultValues: {}, type: CRUD_MODELS.DISCIPLINE })}><Add /></IconButton>
          </Box>
        } />
        <KyLuatTable
          nonBorder={true}
          rows={disciplines}
          setDialog={setDialog}
          setPreview={setPreview}
          setDeleteCrud={setDeleteCrud}
        />
      </Card>
    </Fragment>
  )
}

export default MucA;
