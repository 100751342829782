import React, { useEffect, useMemo } from 'react';
import { Link, Box, Typography } from '@mui/material';
import { useSelector, useDispatch} from 'react-redux';

import KPTable from '../../../components/KPTComponents/KPTable';

import { councilActions, formActions, userActions } from '../../../actions';
import { COLOR_PALETTE, FORM_CONSTANTS, HEADERS, ROUTER, USER_CONSTANTS } from '../../../constants';
import { apiCall, downloadFile, fileRender } from '../../../utils';

export default function DanhSachHoSoTable() {
	const dispatch = useDispatch();

  const list = useSelector(state => state.formReducer.list);
	const loading = useSelector(state => state.formReducer.loading);
  const profile = useSelector(state => state.profileReducer.profile);
  const councils = useSelector(state => state.councilReducer.councils);

  const exportFile = async (endPoint) => {
    dispatch(userActions.setLoadingGlobal(true));

    const { response, error } = await apiCall({
      endPoint: '/api/v1/export' + endPoint,
      method: 'GET',
      headers: HEADERS.DEFAULT_HEADER,
    });
    
    if (!error && response.status === 200) {
      downloadFile(fileRender(response.data.data));
    } else {
      dispatch(userActions.notification({ message: 'Xuất dữ liệu thất bại', type: 'error' }));
    }

    dispatch(userActions.setLoadingGlobal(false));
  }

  useEffect(() => {
    if (!profile?.userRole) return;
    
    if (profile.userRole === USER_CONSTANTS.ROLE.council) {
      dispatch(councilActions.filter({ profileId: profile.id, page: 1, pageSize: 10000 }))
    } else if (profile.userRole === USER_CONSTANTS.ROLE.super_admin) {
      dispatch(formActions.getAllApplicationForm());
    }
  }, [profile]);

  const rows = useMemo(() => {
    if (profile.userRole) {
      if (profile.userRole === USER_CONSTANTS.ROLE.council && councils) {
        return councils.find((c) => c.profileId === profile.id)?.applicationForms;
      }
      return list;
    }
    return [];
  }, [profile, list, councils]);

	const columns = [
		{ title: <b>STT</b>, dataIndex: 'id', width: '5%', render: (_, row, id) => id + 1 },
		{ title: <b>Tên ứng viên</b>, dataIndex: 'profile', width: '25%', render: (value) => (
      <>
        <Typography variant='body2' color={COLOR_PALETTE.PRIMARY}><b>{value?.name}</b></Typography>
        <Typography variant='body2' color='grey'>{value?.email}</Typography>
      </>
    ) },
    {
      title: <b>Ngành</b>,
      dataIndex: 'major',
      width: '20%',
      filters: FORM_CONSTANTS.MAJOR_OPTIONS,
      render: (value) => FORM_CONSTANTS.MAJOR_OPTIONS.find((e) => e.value === value)?.title,
      onFilter: (value, row) => row.role === value,
    },
		{
      title: <b>Chuyên ngành</b>,
      dataIndex: 'specialization',
      width: '20%',
      render: (value, row) => FORM_CONSTANTS.SPECIALIZATION_OPTIONS?.[row.major]?.find((e) => e.value === value)?.title,
    },
    {
      title: <b>Tác vụ</b>,
      dataIndex: 'actions',
      width: '30%',
      render: (_, row) => (
        <>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              color='primary'
              variant='body2'
              className='action-btn'
              onClick={() => exportFile('/6a9/' + row.id)}
            >
              Xuất file 6A9
            </Typography>
            <Link href={`${ROUTER.CHI_TIET_HO_SO}/${row.id}`} className='action-btn'>Chi tiết hồ sơ</Link>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <Typography
              color='primary'
              variant='body2'
              className='action-btn'
              onClick={() => exportFile('/' + row.id)}
            >
              Xuất file chấm
            </Typography>
            {row.status === FORM_CONSTANTS.STATUS_MAP.submitted.value ? (
              <Link href={`${ROUTER.DANH_SACH_THANH_TICH}/${row.id}`} className='action-btn'>Chấm điểm sách/báo</Link>
            ) : (
              <Typography color='gray' variant='body2' className='action-btn'>Hồ sơ chưa cập nhật</Typography>
            )}
          </Box>
        </>
      )
    }
	];

	return <KPTable nonBorder={false} loading={loading} columns={columns} rows={rows} />
}
