import React, { useState, useEffect, useMemo, Fragment } from 'react';
import {
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Divider,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import { useDispatch, useSelector } from 'react-redux';

import KPTextform from '../../../components/KPTComponents/KPTextform';

import { KPTYPE } from '../../../components/KPTComponents/KPTextform/KPTextform';
import { autoScore } from '../../../utils';
import { defaultRows } from '../../../constants/scoringInformation.constants';
import { CRUD_MODELS } from '../../../constants';
import { scoringTableActions } from '../../../actions';

function DuyetThanhTichModal({ type, data, setDuyetThanhTich, ...props }) {
	const dispatch = useDispatch();

  const [values, setValues] = useState(null);
  const [scoreInformation, setScoreInformation] = useState(null);

  const form = useSelector((state) => state.formReducer.form);
  const profile = useSelector((state) => state.profileReducer.profile);
  const journalDomestics = useSelector(state => state.journalDomesticReducer.list);
 
  // const textForm = [
  //   { key: 'maxPoint', xs: 6, label: 'Điểm tính cho CTKH', type: 'float', disabled: values?.maxPoint == null, required: values?.maxPoint != null },
  //   { key: 'point', xs: 6, label: 'Điểm tính cho ứng viên', type: 'float', disabled: values?.maxPoint != null, required: true },
  //   { key: 'note', xs: 12, height: 3, label: 'Ghi chú của phản biện (Trong trường hợp sửa điểm)', type: KPTYPE.textarea, height: 2 },
  //   { key: 'submit', xs: 12, label: 'Duyệt thành tích', labelRender: <></>, type: KPTYPE.submit },
  // ];

  const textForm = [
    { key: 'maxPoint', xs: 6, label: 'Sửa khung điểm', type: 'float', required: true },
    { key: 'point', xs: 6, label: 'Điểm của ứng viên', type: 'float', disabled: true, required: true },
    { key: 'note', xs: 12, height: 3, label: 'Ghi chú của phản biện (Trong trường hợp sửa điểm)', type: KPTYPE.textarea, height: 2 },
    { key: 'submit', xs: 12, label: 'Duyệt thành tích', labelRender: <></>, type: KPTYPE.submit },
  ];

  const scoreInfos = useMemo(() => {
    return [
      ...defaultRows,
      ...journalDomestics.map((journal) => ({
        ...journal,
        journalDomesticId: journal.id,
        type: CRUD_MODELS.SCIENTIFIC_RESEARCH,
        description: `${journal.title} (${journal.publisher})`,
      }))
    ];
  }, [defaultRows, journalDomestics]);

  useEffect(() => {
    if (!type || !data || !scoreInfos) return;

    if (data?.scoringTable?.accepted) {
      handleSubmit({ point: 0, maxPoint: 0, note: '' });
    } else {
      const found = autoScore(type, data, scoreInfos, values?.maxPoint);
      setValues({
        ...values,
        point: found.point,
        maxPoint: values?.maxPoint || found.maxPoint,
      });
      setScoreInformation(found);
    }
  }, [type, data, scoreInfos, values?.maxPoint]);

  const handleSubmit = (values) => {
    const payload = {
      ...values,
      profileId: profile.id,
      applicationFormId: form.id,
      modelType: type,
      modelId: data.id,
      id: data?.scoringTable?.id,
      accepted: Boolean(!data?.scoringTable?.accepted),
    }
    if (payload.id) {
      dispatch(scoringTableActions.update(payload));
    } else {
      dispatch(scoringTableActions.insert(payload));
    }
    setDuyetThanhTich(null);
    setValues(null);
  }

  const open = useMemo(() => !!data && !data?.scoringTable?.accepted, [type, data]);

  useEffect(() => {
    if (!open) setValues(null);
  }, [open]);

  return (
    <Dialog fullWidth maxWidth='xs' open={open}>
      <DialogTitle style={{ padding: '4px 12px 4px 24px' }}>
        <Box display='flex' justifyContent='space-between' alignItems='center'>
          <Typography variant='h5'><b>Duyệt thành tích</b></Typography>
          <IconButton onClick={() => {
            setValues(null);
            setDuyetThanhTich(null);
          }}><Close /></IconButton>
        </Box>
      </DialogTitle>
      <Divider />
      <DialogContent style={{ paddingBottom: 16 }}>
        <Box mb={2}>
          {scoreInformation?.text?.map((e, id) => <Fragment key={`text_${id}`}>{e}</Fragment>)}
        </Box>
        <KPTextform
          values={values}
          textForm={textForm}
          setValues={setValues}
          handleSubmit={() => handleSubmit(values)}
        />
      </DialogContent>
    </Dialog>
  );
}

export default DuyetThanhTichModal;
