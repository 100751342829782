import { HEADERS } from "./headers"

export const COUNCIL_API = {
  filter: () => ({
    endPoint: '/api/v1/council/filter',
    headers: HEADERS.DEFAULT_HEADER,
    method: 'POST',
  }),
  insert: () => ({
    endPoint: '/api/v1/council/insert',
    headers: HEADERS.DEFAULT_HEADER,
    method: 'PUT',
  }),
  update: () => ({
    endPoint: '/api/v1/council/update',
    headers: HEADERS.DEFAULT_HEADER,
    method: 'PUT',
  }),
  // getApplicationFormById: (id) => ({
  //   endPoint: `/api/v1/council/form/${id}`,
  //   headers: HEADERS.DEFAULT_HEADER,
  //   method: 'GET',
  // }),
}

export const COUNCIL_TYPE = {
  FILTER_COUNCIL_LOADING: 'FILTER_COUNCIL_LOADING',
  FILTER_COUNCIL_SUCCESS: 'FILTER_COUNCIL_SUCCESS',
  FILTER_COUNCIL_FAILURE: 'FILTER_COUNCIL_FAILURE',

  INSERT_COUNCIL_LOADING: 'INSERT_COUNCIL_LOADING',
  INSERT_COUNCIL_SUCCESS: 'INSERT_COUNCIL_SUCCESS',
  INSERT_COUNCIL_FAILURE: 'INSERT_COUNCIL_FAILURE',

  UPDATE_COUNCIL_LOADING: 'UPDATE_COUNCIL_LOADING',
  UPDATE_COUNCIL_SUCCESS: 'UPDATE_COUNCIL_SUCCESS',
  UPDATE_COUNCIL_FAILURE: 'UPDATE_COUNCIL_FAILURE',

  DELETE_COUNCIL_LOADING: 'DELETE_COUNCIL_LOADING',
  DELETE_COUNCIL_SUCCESS: 'DELETE_COUNCIL_SUCCESS',
  DELETE_COUNCIL_FAILURE: 'DELETE_COUNCIL_FAILURE',
}