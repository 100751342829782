import React, { useState } from 'react';
import { Card, Grid, IconButton, InputAdornment, OutlinedInput, Typography } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { Visibility, VisibilityOff } from '@mui/icons-material';

import KPTextform from '../../../components/KPTComponents/KPTextform';
import { KPTYPE } from '../../../components/KPTComponents/KPTextform/KPTextform';
import { upperCaseFirst } from '../../../utils';
import { userActions } from '../../../actions';

const DEFAULT_VALUES = {
  passwordOld: '',
  passwordNew1: '',
  passwordNew2: '',
  showPasswordOld: false,
  showPasswordNew1: false,
  showPasswordNew2: false,
}

function DoiMatKhau() {
  const dispatch = useDispatch();

  const [values, setValues] = useState({ ...DEFAULT_VALUES });

  const userId = useSelector(state => state.profileReducer.profile?.userId);

  const passwordInput = (props) => {
    const key = props.id;
    const spKey = 'show' + upperCaseFirst(key);

    return (
      <OutlinedInput
        fullWidth
        type={values[spKey] ? KPTYPE.text : 'password'}
        size='small'
        variant='outlined'
        error={!!props.error ? true : undefined}
        value={props.value}
        onChange={(e) => props.handleChangeForm(props.id, e, props.type)}
        inputProps={{ style: { padding: 8 } }}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton onClick={() => setValues({ ...values, [spKey]: !values[spKey] })}>
              {values[spKey] ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
        }
      />
    )
  }

  const textForm = [
    { key: 'passwordOld', xs: 12, label: 'Mật khẩu hiện tại', type: KPTYPE.render, required: true, render: (props) => passwordInput(props) },
    { key: 'passwordNew1', xs: 12, label: 'Mật khẩu mới', type: KPTYPE.render, required: true, render: (props) => passwordInput(props) },
    { key: 'passwordNew2', xs: 12, label: 'Xác nhận mật khẩu mới', type: KPTYPE.render, required: true, render: (props) => passwordInput(props) },
    { key: 'submit', xs: 12, label: 'Đổi mật khẩu', type: 'submit', labelRender: <></>, disabled: !values.passwordNew1 || (values.passwordNew1 !== values.passwordNew2) },
  ];

  const handleSubmit = () => {
    dispatch(userActions.changePassword({
      userID: userId,
      oldPassword: values.passwordOld,
      newPassword: values.passwordNew1,
    }));
    setValues({ ...DEFAULT_VALUES });
  }

  return (
    <Grid
      container
      alignContent='center'
      justifyContent='center'
      style={{ minHeight: 'calc(100vh - 96px)' }}
    >
      <Grid item xs={12} md={6} style={{ display: 'flex', alignItems: 'center' }}>
        <Card style={{ padding: 16, backgroundColor: '#e7f5e5' }}>
          <Typography variant='h5' style={{ textAlign: 'center' }}><b>Đổi mật khẩu</b></Typography>
          <KPTextform
            textForm={textForm}
            values={values}
            setValues={setValues}
            handleSubmit={handleSubmit}
          />
        </Card>
      </Grid>
    </Grid>
  );
}

export default DoiMatKhau;
