import React from 'react';
import { Card, Grid } from '@mui/material';
import ThongTinCaNhan from '../../containers/DanhSachThanhTich/ThongTinCaNhan';
import DanhSachThanhTichTable from '../../containers/DanhSachThanhTich/DanhSachThanhTichTable/DanhSachThanhTichTable';


function DanhSachThanhTich() {
	return (
    <Grid container spacing={2} style={{ marginTop: 32 }}>
      <Grid item xs={2}>
        <ThongTinCaNhan />
      </Grid>
      <Grid item xs={10}>
        <Card>
          <DanhSachThanhTichTable />
        </Card>
      </Grid>
    </Grid>
	)
}

export default DanhSachThanhTich;
