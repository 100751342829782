import React, { Fragment, useEffect, useMemo } from 'react';
import { Close } from '@mui/icons-material';
import { useCookies } from 'react-cookie';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { createBrowserRouter, NavLink, Outlet, RouterProvider } from 'react-router-dom';
import { Box, CircularProgress, Container, IconButton, Typography } from '@mui/material';

import AppHeader from '../../containers/AppLayout/AppHeader';
import ROUTER_CONFIG from './routerConfig';

import { ROUTER, USER_CONSTANTS } from '../../constants';
import { userActions } from '../../actions';
import Loading from '../CommonComponents/Loading';

const NavLayout = ({
  maxWidth = false,
  disableHeader = false,
  disableFooter = false,
  loading,
  loggedIn,
  bgColor = '#FFF',
  authRequired = true,
  adminRoleRequired = false,
  isCouncil = false,
  isSuperAdmin = false,
}) => {
  document.body.style.backgroundColor = bgColor;

  const outletComponent = () => {
    if (loading) {
      return (
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' height='100vh'>
          <CircularProgress />
          <Typography variant='body1'>
            Vui lòng đợi trong giây lát...
          </Typography>
        </Box>
      );
    } else if (authRequired && !loggedIn) {
      return (
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' height='100vh'>
          <NavLink to={ROUTER.DANG_NHAP}>Đăng nhập để truy cập tính năng này</NavLink>
        </Box>
      );
    } else if (adminRoleRequired && loggedIn && !isSuperAdmin) {
      return (
        <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' height='100vh'>
          <NavLink to={ROUTER.HOME}>Bạn không có quyền truy cập tính năng này</NavLink>
        </Box>
      );
    }
    return <Outlet />
  };

  const style = useMemo(() => {
    if (disableHeader) {
      return {
        flexGrow: 1,
        alignContent: 'center',
        paddingBottom: disableFooter ? 0 : 32,
      }
    } else {
      return {
        minHeight: '100vh',
        paddingTop: 64,
        paddingBottom: disableFooter ? 0 : 32,
      }
    }
  }, [disableHeader]);

  useEffect(() => {
    if (disableHeader) {
      // Nhìn thấy footer
      const rootDiv = document.getElementById('root');
      if (rootDiv) {
        rootDiv.style.display = 'flex';
        rootDiv.style.minHeight = '100vh';
        rootDiv.style.flexDirection = 'column';
      }
    }
  }, [disableHeader]);

  return (
    <>
      {!disableHeader && <AppHeader />}
      <Container
        style={style}
        maxWidth={maxWidth}
        disableGutters={!maxWidth}
      >
        {outletComponent()}
      </Container>
      {/* {!disableFooter && <AppFooter />} */}
    </>
  );
}

function AppLayout() {
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [cookies] = useCookies(['jwt']);
  const token = cookies['jwt'];

  const {
    profile,
    notification,
    loadingForm,
    loadingUser,
    loadingGlobal,
    loadingCouncil,
    loadingProfile,
  } = useSelector(state => ({
    profile: state.profileReducer.profile,
    notification: state.userReducer.notification,

    loadingForm: state.formReducer.loading,
    loadingUser: state.userReducer.loading,
    loadingGlobal: state.userReducer.loadingGlobal,
    loadingProfile: state.profileReducer.loading,
    loadingCouncil: state.councilReducer.loading,
  }));

  const loading = loadingUser || loadingGlobal || loadingProfile || loadingCouncil || loadingForm;
  const loggedIn = (!loadingUser) && (!isNaN(profile?.id)) && (!!token);

  const isCouncil = profile?.userRole === USER_CONSTANTS.ROLE.council;
  const isSuperAdmin = profile?.userRole === USER_CONSTANTS.ROLE.super_admin;

  // 1. Trong store có thông báo
  // 2. Hiển thị thông báo
  useEffect(() => {
    if (notification) {
      enqueueSnackbar(notification?.message, {
        variant: notification?.type || 'default',
        action: (key) => (
          <IconButton size='small' onClick={() => closeSnackbar(key)}>
            <Close size='small' style={{ color: 'white' }} />
          </IconButton>
        ),
      });
      closeSnackbar(1);
      dispatch(userActions.notification(null));
    }
  }, [notification]);

  const router = useMemo(() => {
    var routerConfig = [...ROUTER_CONFIG.BASE];

    if (isCouncil) routerConfig = [...routerConfig, ...ROUTER_CONFIG.COUNCIL];
    else if (isSuperAdmin) routerConfig = [...routerConfig, ...ROUTER_CONFIG.ADMIN];

    routerConfig = routerConfig.map((route) => ({
      exact: true,
      children: [route],
      element: (
        <NavLayout
          {...route}
          loggedIn={loggedIn}
          loading={loading}
          isCouncil={isCouncil}
          isSuperAdmin={isSuperAdmin}
        />
      )
    }));

    return createBrowserRouter(routerConfig);
  }, [profile, loggedIn]);

  useEffect(() => {
    if (token && !profile) dispatch(userActions.refreshToken());
  }, []);

  return (
    <Fragment>
      <RouterProvider router={router} />
      <Loading open={loading} />
    </Fragment>
  )
}

export default AppLayout;