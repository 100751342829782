import React from 'react';

import KPTextform from '../../../../components/KPTComponents/KPTextform';

import { PROGRAM_MODEL } from '../../../../constants';

function ChuongTrinhForm(props) {
  const { disabled } = props;

  const textForm = [
    { ...PROGRAM_MODEL.title, xs: 12, disabled },
    { ...PROGRAM_MODEL.role, xs: 12, disabled },
    { ...PROGRAM_MODEL.assessmentDocument, xs: 12, disabled },
    { ...PROGRAM_MODEL.proof, xs: 12, disabled },
  ];

  return <KPTextform {...props} textForm={textForm} />
}

export default ChuongTrinhForm;
