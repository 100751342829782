import React from 'react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import { DropzoneArea } from 'react-mui-dropzone';
import {
  Bold,
  Link,
  List,
  Undo,
  Table,
  Indent,
  Italic,
  Heading,
  Paragraph,
  Essentials,
  IndentBlock,
  ClassicEditor,
} from 'ckeditor5';
import {
  Radio,
  Button,
  Select,
  Checkbox,
  MenuItem,
  Typography,
  RadioGroup,
  FormControl,
  OutlinedInput,
  FormControlLabel,
  FormLabel,
  TextareaAutosize,
  Box,
} from '@mui/material';

import { KPTYPE } from '../KPTextform/KPTextform';

import theme from '../../../theme';
import styles from './KPTextfield.module.css';
import KPTimepicker from '../KPTimepicker';
import DefaultLabel from '../../CommonComponents/DefaultLabel';

function KPTextfield({
  type,
  label,
  error,
  required,
  labelRender,
  handleChangeForm,
  ...props
}) {
  const renderTextfield = () => {
    switch (type) {
      case KPTYPE.button:
      case KPTYPE.submit:
        return (
          <Button
            {...props}
            fullWidth
            type={type}
            color='primary'
            variant='contained'
            style={{ height: 39, marginTop: 25.5 }}
          >
            {label}
          </Button>
        );
      case KPTYPE.render:
        return props.render({ ...props, handleChangeForm, error });
      case KPTYPE.select:
        return (
          <Select
            {...props}
            fullWidth
            displayEmpty={true}
            size='small'
            variant='outlined'
            error={!!error || undefined}
            SelectDisplayProps={{ style: { padding: 8 } }}
            inputProps={{ style: { padding: 8 } }}
            MenuProps={{
              MenuListProps: { style: { padding: 0 } },
              anchorOrigin: { vertical: 'bottom', horizontal: 'center' },
              transformOrigin: { vertical: 'top', horizontal: 'center' },
            }}
            sx={{ '& .MuiSelect-select .notranslate::after': label ? {
              content: `"${label}"`,
              opacity: 0.42
            } : {} }}
            onChange={(e) => handleChangeForm(props.id, e, type)}
          >
            {props.options.map((opt) => (
              <MenuItem key={opt.key} value={opt.value}>{opt.title}</MenuItem>
            ))}
          </Select>
        );
      case KPTYPE.checkbox:
        return (
          <FormControlLabel
            control={<Checkbox checked={props.value} onChange={(e) => handleChangeForm(props.id, e, type)} />}
            label={<Typography className='kpt-textfield-label' sx={{ pb: 0 }}
          >
            <b>{label}</b>
          </Typography>} />
        );
      case KPTYPE.radio:
        return (
          <FormControl>
            <RadioGroup
              value={props.value}
              onChange={(e) => handleChangeForm(props.id, e, type)}
            >
              {props.options.map((opt) => (
                <FormControlLabel key={opt.key} value={opt.value} control={<Radio />} label={opt.title} sx={{ mb: 1 }} />
              ))}
            </RadioGroup>
          </FormControl>
        );
      case KPTYPE.date:
        return (
          <KPTimepicker
            {...props}
            handleChangeForm={handleChangeForm}
          />
        );
      case KPTYPE.richtext:
        return (
          props.value && <CKEditor
            data={props.value}
            editor={ClassicEditor}
            config={{
              toolbar: [
                'undo', 'redo', '|',
                'heading', '|', 'bold', 'italic', '|',
                'link', 'insertTable', '|',
                'bulletedList', 'numberedList', 'indent', 'outdent'
              ],
              plugins: [
                Bold,
                Essentials,
                Heading,
                Indent,
                IndentBlock,
                Italic,
                Link,
                List,
                Paragraph,
                Table,
                Undo
              ],
            }}
            onChange={(_e, editor) => handleChangeForm(props.id, editor.getData(), type)}
          />
        );
      case KPTYPE.dropzone: {
        return (
          <DropzoneArea
            clearOnUnmount={false}
            classes={{
              root: styles.dropzoneRoot,
              text: styles.dropzoneText,
              icon: styles.dropzoneIcon,
            }}
            filesLimit={props?.filesLimit || 1}
            initialFiles={props.value}
            maxFileSize={10485760}
            useChipsForPreview={!(props?.acceptedFiles?.length === 1 && props?.acceptedFiles?.[0] === 'image/*')}
            acceptedFiles={props?.acceptedFiles || []}
            dropzoneProps={{ disabled: props?.disabled }}
            onChange={(files) => handleChangeForm(props.id, files, type)}
            dropzoneText={props.labelRender || label || (
              <Typography variant='body1'>
                <b>Upload file minh chứng tại đây</b>
              </Typography>
            )}
            previewChipProps={{ style: { maxWidth: '75%' } }}
            previewGridProps={{
              container: {
                spacing: 1,
                direction: 'row',
                alignItems: 'center',
                justifyContent: 'center'
              },
              item: { style: { width: "100%" } }
            }}
            getFileAddedMessage={(fileName) => `Đã chọn file: ${fileName}`}
            getFileRemovedMessage={(fileName) => `Đã xóa file: ${fileName}`}
            getFileLimitExceedMessage={(filesLimit) => `Kích thước file tối đa: ${filesLimit}!`}
          >
          </DropzoneArea>
        )
      }
      case KPTYPE.textarea:
        return (
          // <TextareaAutosize
          //   {...props}
          //   minRows={props.height}
          //   placeholder={label}
          //   onChange={(e) => handleChangeForm(props.id, e, type)}
          // />
          <OutlinedInput
            {...props}
            fullWidth
            size='small'
            variant='outlined'
            placeholder={label}
            minRows={props.height || 1}
            multiline={true}
            error={!!error ? true : undefined}
            onChange={(e) => handleChangeForm(props.id, e, type)}
            inputProps={{ style: { padding: 8 } }}
            sx={{ padding: 0 }}
          />
        )
      default:
        return (
          <OutlinedInput
            {...props}
            fullWidth
            size='small'
            variant='outlined'
            placeholder={label}
            type={{
              [KPTYPE.int]: 'number',
              [KPTYPE.float]: 'number',
            }[type]}
            // rows={props.height}
            // multiline={!isNaN(props.height)}
            error={!!error ? true : undefined}
            onChange={(e) => handleChangeForm(props.id, e, type)}
            inputProps={{ style: { padding: 8 } }}
          />
        );
    }
  }

  return (
    <React.Fragment>
      {labelRender ?? <DefaultLabel label={label} required={required} />}
      {renderTextfield()}
      {error && (
        <Typography style={{fontSize: 12, color: theme.palette.error.main }}>{error}</Typography>
      )}
    </React.Fragment>
  )
}

export default KPTextfield;
