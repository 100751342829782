import React from 'react';

import KPTable from '../../../../components/KPTComponents/KPTable';

import { ActionColumn } from '../../../../containers/DangKyHoSo/utils';
import { CRUD_MODELS, SOLUTION_MODEL } from '../../../../constants';

function SangCheTable({ rows, setDialog, ...props }) {
  const columns = [
    { ...SOLUTION_MODEL.title, xs: '60%' },
    {
      ...SOLUTION_MODEL.role,
      xs: '20%',
      filters: SOLUTION_MODEL.role.options,
      render: (value) => SOLUTION_MODEL.role.options.find((e) => e.value === value)?.title,
      onFilter: (value, row) => row.role === value,
    },
    {
      key: 'link',
      label: 'Tác vụ',
      width: '20%',
      render: (_, row) => (
        <ActionColumn
          row={row}
          type={CRUD_MODELS.SOLUTION}
          setDialog={setDialog}
          setPreview={props.setPreview}
          setDeleteCrud={props.setDeleteCrud}
        />
      )
    }
  ];

  return (
    <KPTable
      columns={columns}
      nonBorder={!!props.nonBorder}
      rows={rows}
      // loading={loading}
    />
  );
}

export default SangCheTable;
