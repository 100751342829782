import React from 'react';
import { Chip } from '@mui/material';

import KPTable from '../../../../components/KPTComponents/KPTable';

import { ActionColumn } from '../../../../containers/DangKyHoSo/utils';
import { CRUD_MODELS, SCIENTIFIC_RESEARCH_CONSTANTS, SCIENTIFIC_RESEARCH_MODEL } from '../../../../constants';

function BaiBaoTable({ rows, setDialog, setDuyetThanhTich, ...props }) {
  // TODO: useMemo
  const columns = [
    { key: 'orderNumber', width: setDuyetThanhTich ? '5%' : '10%', title: 'STT' },
    { ...SCIENTIFIC_RESEARCH_MODEL.title, width: '25%' },
    { ...SCIENTIFIC_RESEARCH_MODEL.journalTitle, width: '20%' },
    {
      key: 'type',
      align: 'center',
      width: setDuyetThanhTich ? '15%' : '18%',
      label: 'Loại Tạp chí quốc tế uy tín: ISI, Scopus (IF, Qi)',
      render: (_, row) => (
        <>
          <span>{row.journalCoreCollection}</span>
          <br />
          <span>{SCIENTIFIC_RESEARCH_CONSTANTS.TYPE_OPTIONS.find((t) => t.value === row.type)?.title}</span>
          <br />
        </>
      ),
    },
  ];

  if (setDuyetThanhTich) {
    columns.push({
      key: 'point',
      label: 'Điểm',
      align: 'center',
      width: '5%',
      render: (_, row) => row.scoringTable?.point
    });
    columns.push({
      key: 'accepted',
      label: 'Trạng thái',
      align: 'center',
      width: '15%',
      render: (_, row) => (
        <Chip
          size='small'
          color={row.scoringTable?.accepted ? 'primary' : 'default'}
          label={row.scoringTable?.accepted ? 'Đã chấm' : 'Chưa chấm'}
          variant={row.scoringTable?.accepted ? 'filled' : 'outlined'}
        />
      ),
    });
  }

  columns.push({
    key: 'link',
    label: 'Tác vụ',
    width: setDuyetThanhTich ? '15%' : '20%',
    render: (_, row) =>
      <ActionColumn
        row={row}
        setDialog={setDialog}
        type={CRUD_MODELS.SCIENTIFIC_RESEARCH}
        filterType={props.filterType}
        setPreview={props.setPreview}
        setDeleteCrud={props.setDeleteCrud}
        setDuyetThanhTich={setDuyetThanhTich}
      />
  });

  return (
    <KPTable
      columns={columns}
      nonBorder={!!props.nonBorder}
      rows={rows?.filter((row) => row.filterType === props.filterType)}
      // loading={loading}
    />
  );
}

export default BaiBaoTable;
