import React from 'react';
import { Box, Typography } from '@mui/material';

import { KPTYPE } from '../../../../components/KPTComponents/KPTextform/KPTextform';
import { DISCIPLINE_MODEL } from '../../../../constants';

import KPTextform from '../../../../components/KPTComponents/KPTextform';

function KyLuatForm(props) {
  const { disabled } = props;

  const textForm = [
    { ...DISCIPLINE_MODEL.title, xs: 12, disabled },
    { ...DISCIPLINE_MODEL.level, xs: 7, disabled },
    { ...DISCIPLINE_MODEL.number, xs: 5, disabled },
    { ...DISCIPLINE_MODEL.effectiveStartTime, xs: 5, disabled },
    { key: 'to_0', xs: 2, labelRender: <Box sx={{ marginTop: '25.5px' }} />, type: KPTYPE.render, render: (props) => (
      <Box sx={{ height: 39, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Typography className='kpt-textfield-label'>đến</Typography>
      </Box>
    ) },
    { ...DISCIPLINE_MODEL.effectiveEndTime, xs: 5, labelRender: <Box sx={{ marginTop: '25.5px' }} />, disabled },
    { ...DISCIPLINE_MODEL.proof, xs: 12, disabled },
  ];

  return <KPTextform {...props} textForm={textForm} />
}

export default KyLuatForm;
