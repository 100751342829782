import { HEADERS } from '.';

export const USER_API = {
  login: () => ({
    endPoint: '/api/v1/auth/login',
    headers: HEADERS.DEFAULT_HEADER,
    method: 'POST',
  }),
  register: () => ({
    endPoint: '/api/v1/auth/register',
    headers: HEADERS.DEFAULT_HEADER,
    method: 'POST',
  }),
  refreshToken: () => ({
    endPoint: '/api/v1/auth/refreshToken',
    headers: HEADERS.DEFAULT_HEADER,
    method: 'GET',
  }),
  logout: () => ({
    endPoint: '/api/v1/auth/logout',
    headers: HEADERS.DEFAULT_HEADER,
    method: 'GET',
  }),
  changePassword: () => ({
    endPoint: '/api/v1/auth/changePassword',
    headers: HEADERS.DEFAULT_HEADER,
    method: 'POST',
  }),
  resetByEmail: (email) => ({
    endPoint: `/api/v1/auth/resetPasswordWithEmail/${email}`,
    headers: HEADERS.DEFAULT_HEADER,
    method: 'GET',
  }),
  filter: () => ({
    endPoint: '/api/v1/user/filter',
    headers: HEADERS.DEFAULT_HEADER,
    method: 'POST',
  }),
  changeRole: () => ({
    endPoint: '/api/v1/user/updateRole',
    headers: HEADERS.DEFAULT_HEADER,
    method: 'POST',
  }),
}

export const USER_TYPE = {
  NOTIFICATION: 'NOTIFICATION',
  SET_LOADING_GLOBAL: 'SET_LOADING_GLOBAL',

  LOGIN_LOADING: 'LOGIN_LOADING',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',

  LOGOUT_LOADING: 'LOGOUT_LOADING',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',
  LOGOUT_FAILURE: 'LOGOUT_FAILURE',

  REGISTER_LOADING: 'REGISTER_LOADING',
  REGISTER_SUCCESS: 'REGISTER_SUCCESS',
  REGISTER_FAILURE: 'REGISTER_FAILURE',

  RESET_BY_EMAIL_LOADING: 'RESET_BY_EMAIL_LOADING',
  RESET_BY_EMAIL_SUCCESS: 'RESET_BY_EMAIL_SUCCESS',
  RESET_BY_EMAIL_FAILURE: 'RESET_BY_EMAIL_FAILURE',
  
  CHANGE_PASSWORD_LOADING: 'CHANGE_PASSWORD_LOADING',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

  FILTER_USER_LOADING: 'FILTER_USER_LOADING',
  FILTER_USER_SUCCESS: 'FILTER_USER_SUCCESS',
  FILTER_USER_FAILURE: 'FILTER_USER_FAILURE',

  CHANGE_ROLE_LOADING: 'CHANGE_ROLE_LOADING',
  CHANGE_ROLE_SUCCESS: 'CHANGE_ROLE_SUCCESS',
  CHANGE_ROLE_FAILURE: 'CHANGE_ROLE_FAILURE',
}

export const USER_CONSTANTS = {
  ROLE: {
    council: 'council',
    candidate: 'candidate',
    super_admin: 'super_admin',
  },
  ROLE_OPTIONS: [
    { key: 'candidate', title: 'Ứng cử viên', value: 'candidate' },
    { key: 'super_admin', title: 'Quản trị viên hệ thống', value: 'super_admin' },
    { key: 'council', title: 'Hội đồng chấm điểm', value: 'council' },
  ]
}