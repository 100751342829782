import React from 'react';

import { Box, Card } from '@mui/material';
import noImage from '../../../assets/image/app/no-image.png';


function AnhChanDung({ photoUrl, handleImageChange = undefined }) {
  return (
    <Card className='dkhs-container'>
      <img className='dkhs-photo' src={photoUrl || noImage} alt='anh-chan-dung' />
      {handleImageChange && (
        <React.Fragment>
          <label className='dkhs-insertBtn' htmlFor='upload-photo'>
            <Box className='dkhs-insertBtnContent'>
              <Box className='dkhs-insertBtnLabel'>Click để thêm ảnh...</Box>
            </Box>
          </label>
          <input
            type='file'
            id='upload-photo'
            style={{ opacity: 0, zIndex: -1, position: 'absolute', width: '100%' }}
            onChange={handleImageChange}
          />
        </React.Fragment>
      )}
    </Card>
  );
}

export default AnhChanDung;
