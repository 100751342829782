import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, Button, Typography, Box, Divider, Dialog, DialogTitle, IconButton, DialogContent, DialogActions } from '@mui/material';
import { FORM_CONSTANTS, FORM_MODEL, ROUTER, USER_CONSTANTS } from '../../../constants';
import KPTable from '../../../components/KPTComponents/KPTable';
import PopupMenu from '../../../components/CommonComponents/PopupMenu';
import KPTextform from '../../../components/KPTComponents/KPTextform';
import { councilActions, formActions, userActions } from '../../../actions';
import { KPTYPE } from '../../../components/KPTComponents/KPTextform/KPTextform';
import { Close, ReplayOutlined, Save, Start } from '@mui/icons-material';
import DefaultLabel from '../../../components/CommonComponents/DefaultLabel';
import { textForm } from '../../DangKyHoSo/utils';
import KPTextfield from '../../../components/KPTComponents/KPTextfield/KPTextfield';


function PhanQuyenHoiDong(props) {
  const dispatch = useDispatch();

  const list = useSelector(state => state.formReducer.list);
  const councils = useSelector((state) => state.councilReducer.councils);

  const [formValues, setFormValues] = useState(null);
  const [roleValues, setRoleValues] = useState(null);
  const [modifyMenuAnchor, setModifyMenuAnchor] = useState(false);

  const handleReload = () => {
    dispatch(formActions.getAllApplicationForm());
    dispatch(councilActions.filter({ page: 1, pageSize: 10000 }));
  };

  useEffect(() => {
    handleReload();
  }, []);

  const openModifyMenu = (e, row) => {
    setRoleValues({
      profileId: row.profileId,
      applicationFormIds: row.applicationForms.map((form) => form.id)
    });
    setModifyMenuAnchor(e.currentTarget);
  };

  const closeModifyMenu = () => {
    setRoleValues(null);
    setModifyMenuAnchor(null);
  };

  const updateCouncil = () => {
    dispatch(councilActions.update(roleValues)).then(handleReload);
    closeModifyMenu();
  };

  var columns = [
    { title: <b>STT</b>, dataIndex: 'id', width: '5%', render: (_, row, id) => id + 1 },
    { title: <b>Hội đồng</b>, dataIndex: 'name', width: '20%' },
    { title: <b>Email</b>, dataIndex: 'email', width: '20%' },
    {
      title: <b>DS HS được phân quyền</b>,
      dataIndex: 'applicationForms',
      width: '30%',
      render: (value) => (
        <ul style={{ padding: 0 }}>
          {value?.map((form) => (
            <li key={form.id}>
              <Link className='action-btn' href={`${ROUTER.DANH_SACH_THANH_TICH}/${form.id}`}>
                <span>{form.profile?.email}</span>
              </Link>
            </li>
          ))}
        </ul>
      ),
    },
    {
      title: <b>Tác vụ</b>,
      dataIndex: 'cc-actions',
      width: '15%',
      render: (_, row) => (
        <>
          <Typography
            variant='body2'
            color='primary'
            className='action-btn'
            onClick={(e) => setFormValues(e, row)}
          >
            Hồ sơ Hội đồng
          </Typography>
          <Typography
            variant='body2'
            color='primary'
            className='action-btn'
            onClick={(e) => openModifyMenu(e, row)}
          >
            Gán hội đồng
          </Typography>
        </>
      ),
    },
  ];

  const form = [
    { ...FORM_MODEL.category, xs: 12, label: 'TĐ đào tạo và CDKH' },
    { ...FORM_MODEL.entity, xs: 12 },
    { ...FORM_MODEL.major, xs: 12 },
    {
      ...FORM_MODEL.specialization,
      type: KPTYPE.render,
      xs: 12,
      labelRender: <></>,
      render: ({ values, error, handleChangeForm }) => (
        <KPTextfield
          error={error}
          id='specialization'
          key='specialization'
          label='Chuyên ngành'
          type={KPTYPE.select}
          value={values.specialization || ''}
          options={FORM_CONSTANTS.SPECIALIZATION_OPTIONS[values.major] || []}
          handleChangeForm={handleChangeForm}
        />
      )
    },
  ];

  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', my: 2 }}>
        <Typography variant='h5'><b>Phân quyền hội đồng</b></Typography>
        <Button variant='outlined' color='inherit' startIcon={<ReplayOutlined />} onClick={handleReload}>Tải lại</Button>
      </Box>
      <KPTable columns={columns} rows={councils} />

      <PopupMenu
        position='center'
        anchorEl={modifyMenuAnchor}
        handleClose={closeModifyMenu}
        render={
          <div style={{ padding: 8, width: 320, minWidth: 320, maxWidth: 320 }}>
            <DefaultLabel label='Gán hồ sơ cho hội đồng' required={true} />
            <KPTextform
              {...props}
              values={roleValues}
              setValues={setRoleValues}
              textForm={[{
                key: 'applicationFormIds',
                xs: 12,
                multiple: true,
                type: KPTYPE.select,
                value: roleValues?.applicationFormIds || [],
                inputProps: { multiple: true },
                options: list.map((item) => ({ key: item.id, value: item.id, title: item.profile.email }))
              }]}
            />
            <Button
              variant='outlined'
              color='secondary'
              style={{ width: 'calc(50% - 4px)', marginTop: '10px' }}
              onClick={closeModifyMenu}
            >
              Huỷ
            </Button>
            <Button
              variant='contained'
              color='primary'
              style={{
                marginLeft: 8,
                width: 'calc(50% - 4px)',
                marginTop: '10px',
              }}
              onClick={updateCouncil}
            >
              Xác nhận
            </Button>
          </div>
        }
      />

      <Dialog open={!!formValues} maxWidth='xs' fullWidth>
        <DialogTitle style={{ padding: '4px 12px 4px 24px' }}>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Typography variant='h5'><b>Thông tin thành viên hội đồng</b></Typography>
            <IconButton onClick={() => setFormValues(null)}><Close /></IconButton>
          </Box>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <KPTextform
            id='update-f0rm'
            textForm={form}
            values={formValues || {}}
            setValues={setFormValues}
            handleSubmit={() => {
              // dispatch(userActions.resetByEmail(resetValues.email)).then((res) => setApiCalled(res));
            }}
          />
        </DialogContent>
        <DialogActions sx={{ px: 3 }}>
          <Button
            fullWidth
            variant='contained'
            type='submit'
            form='update-f0rm'
            startIcon={<Save />}
            // disabled={apiCalled}
          >
            Lưu thông tin
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
export default PhanQuyenHoiDong;
