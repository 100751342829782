import React from 'react';
import { useSelector } from 'react-redux';

import KPTextform from '../../../../components/KPTComponents/KPTextform';
import DefaultLabel from '../../../../components/CommonComponents/DefaultLabel';

import { KPTYPE } from '../../../../components/KPTComponents/KPTextform/KPTextform';
import { MONOGRAPH_CONSTANTS, MONOGRAPH_MODEL } from '../../../../constants';
import KPTextfield from '../../../../components/KPTComponents/KPTextfield';

function SachForm(props) {
  const { disabled } = props;

  const dOptions = useSelector((state) => state.formReducer.researchDirections?.map(
    (e, id) => ({ key: e.id, value: e.id, title: `[${id + 1}] ${e.title}` })
  )) || [];

  const textForm = [
    { ...MONOGRAPH_MODEL.title, xs: 12, disabled },
    { ...MONOGRAPH_MODEL.researchDirectionId, xs: 12, type: KPTYPE.select, options: dOptions, disabled },
    { ...MONOGRAPH_MODEL.type, xs: 6, disabled },
    { ...MONOGRAPH_MODEL.publishedYear, xs: 6, disabled },
    { ...MONOGRAPH_MODEL.phase, xs: 12, disabled },
    { ...MONOGRAPH_MODEL.publisher, xs: 12, disabled },
    // { ...MONOGRAPH_MODEL.isLegit, xs: 12, labelRender: <></>, disabled },
    { ...MONOGRAPH_MODEL.isLegitPublisher, xs: 12, labelRender: <></>, disabled },
    { ...MONOGRAPH_MODEL.author, xs: 12, disabled },
    { ...MONOGRAPH_MODEL.numberAuthor, xs: 6, disabled },
    {
      ...MONOGRAPH_MODEL.numberMain,
      label: props.values[MONOGRAPH_MODEL.type.key] === 'chuongSach'
        ? 'Số tác giả chính'
        : 'Số chủ biên',
      xs: 6,
      disabled
    },
    {
      ...MONOGRAPH_MODEL.role,
      type: KPTYPE.render,
      xs: 6,
      labelRender: <></>,
      render: ({ values, error, handleChangeForm }) => (
        <KPTextfield
          error={error}
          id={MONOGRAPH_MODEL.role.key}
          key={MONOGRAPH_MODEL.role.key}
          label={MONOGRAPH_MODEL.role.label}
          type={KPTYPE.select}
          value={values[MONOGRAPH_MODEL.role.key] || ''}
          options={values[MONOGRAPH_MODEL.type.key] === 'chuongSach'
            ? MONOGRAPH_CONSTANTS.CHAPTER_ROLE_OPTIONS
            : MONOGRAPH_CONSTANTS.ROLE_OPTIONS}
          handleChangeForm={handleChangeForm}
        />
      )
    },
    { ...MONOGRAPH_MODEL.issn, xs: 6, disabled },
    { ...MONOGRAPH_MODEL.editorPart, xs: 12, disabled },
    { ...MONOGRAPH_MODEL.acceptedCount, xs: 12, disabled },
    { ...MONOGRAPH_MODEL.doi, xs: 12, disabled },
    // { ...MONOGRAPH_MODEL.isRepresentative, xs: 12, labelRender: <></>, disabled },
    { ...MONOGRAPH_MODEL.proof, labelRender: (
      <DefaultLabel label={MONOGRAPH_MODEL.proof.label + ' (Trang bìa)'} />
    ), xs: 12, disabled },
  ];

  return <KPTextform {...props} textForm={textForm} />
}

export default SachForm;
