import React, { useEffect, useState, Fragment } from "react";
import { Box, Card, CardContent, Typography } from "@mui/material";

import { textForm } from "../utils";

import theme from "../../../theme";
import KPTextform from "../../../components/KPTComponents/KPTextform";
import AnhChanDung from "../../../components/CommonComponents/AnhChanDung";

function MucA0({ error, values, setValues }) {
  const [imagePreviewUrl, setImagePreviewUrl] = useState('');

  useEffect(() => {
    if (
      Array.isArray(values.photoUrl) &&
      values.photoUrl.length === 1 &&
      typeof(values.photoUrl[0]) === 'string'
    ) {
      setImagePreviewUrl(values.photoUrl[0]);
    }
  }, [values]);

  const handleImageChange = (e) => {
    e.preventDefault();

    let reader = new FileReader();
    let readedFile = e.target.files[0];

    reader.onloadend = () => {
      if (readedFile && reader?.result) {
        setValues({ ...values, photoUrl: [readedFile] })
        setImagePreviewUrl(reader.result);
      }
    }

    if (readedFile) reader.readAsDataURL(readedFile);
  }

  return (
    <Fragment>
      <Card style={{ width: '100%', marginTop: 16 }}>
        <CardContent>
          <Box display='flex'>
            <Box width='100%'>
              <KPTextform
                component={Box}
                error={error}
                values={values}
                setValues={setValues}
                textForm={textForm.mucA0}
              />
            </Box>
            <Box ml={1}>
              <Typography className='kpt-textfield-label'>
                <span>Ảnh chân dung</span>
                <span style={{ color: theme.palette.secondary.main }}>*</span>
                <span>:</span>
              </Typography>
              <AnhChanDung photoUrl={imagePreviewUrl} handleImageChange={handleImageChange} />
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Fragment>
  )
  
}

export default MucA0;
