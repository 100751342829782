import { apiCall } from '../utils';
import { PROFILE_API, PROFILE_TYPE } from '../constants';

export const profileActions = {
  updateProfile,
};

function updateProfile(payload) {
  return async(dispatch) => {
    dispatch(loading());

    const api = PROFILE_API.updateProfile();
    const { response, error } = await apiCall({ ...api, payload });

    if (!error && response.status === 200 && response.data.success) {
      dispatch(success(payload));
      // dispatch(notification({ message: 'Cập nhật thông tin thành công', type: 'success' }));
    } else {
      dispatch(failure(error));
      // dispatch(notification({ message: 'Cập nhật thông tin thất bại', type: 'error' }));
    }
  };

  function loading() { return { type: PROFILE_TYPE.UPDATE_PROFILE_LOADING } }
  function success(profile) { return { type: PROFILE_TYPE.UPDATE_PROFILE_SUCCESS, profile } }
  function failure(error) { return { type: PROFILE_TYPE.UPDATE_PROFILE_FAILURE, error } }
}
