import { KPTYPE } from "../components/KPTComponents/KPTextform/KPTextform";

export const DISCIPLINE_MODEL = {
  title: { key: 'title', label: 'Tên kỷ luật', required: true },
  level: { key: 'level', label: 'Cấp quyết định', required: true },
  number: { key: 'number', label: 'Số quyết định', required: true },
  effectiveStartTime: { key: 'effectiveStartTime', label: 'Thời gian hiệu lực từ ngày', type: KPTYPE.date, required: true },
  effectiveEndTime: { key: 'effectiveEndTime', label: 'Thời gian hiệu lực đến ngày', type: KPTYPE.date, required: true },
  proof: { key: 'proof', label: 'Minh chứng', type: KPTYPE.dropzone, required: true }
};
