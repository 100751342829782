import { KPTYPE } from '../components/KPTComponents/KPTextform/KPTextform';
import { PHASE_OPTIONS } from './enums';
import { AUTHOR_BASE_MODEL } from './scienctificResearch.constants';

export const MONOGRAPH_CONSTANTS = {
  TYPE_OPTIONS: [
    { value: "sachChuyenKhao", title: "Sách chuyên khảo", key: "sachChuyenKhao" },
    { value: "sachGiaoTrinh", title: "Sách giáo trình", key: "sachGiaoTrinh" },
    { value: "sachThamKhao", title: "Sách tham khảo", key: "sachThamKhao" },
    { value: "sachHuongDan", title: "Sách hướng dẫn", key: "sachHuongDan" },
    { value: "chuongSach", title: "Chương sách", key: "chuongSach" },
  ],
  ROLE_OPTIONS: [
    { value: "main", title: "Chủ biên", key: "main" },
    // { value: "cumain", title: "Đồng chủ biên", key: "cumain" },
    { value: "other", title: "Thành viên", key: "other" },
  ],
  CHAPTER_ROLE_OPTIONS: [
    { value: "main", title: "Tác giả chính", key: "main" },
    { value: 'other', title: 'Đồng tác giả', key: 'other' },
  ],
}

export const MONOGRAPH_MODEL = {
  ...AUTHOR_BASE_MODEL,
  type: { key: 'type', label: 'Phân loại sách', type: KPTYPE.select, options: MONOGRAPH_CONSTANTS.TYPE_OPTIONS, required: true },
  issn: { key: 'issn', label: 'ISSN/ISBN' },
  researchDirectionId: { key: 'researchDirectionId', label: 'Hướng nghiên cứu' },
  title: { key: 'title', label: 'Tên sách', type: KPTYPE.textarea, required: true },
  phase: { key: 'phase', label: 'Giai đoạn viết sách (Trước/Sau TS/PGS)', type: KPTYPE.select, options: PHASE_OPTIONS },
  publisher: { key: 'publisher', label: 'Nhà xuất bản', type: KPTYPE.textarea, height: 1, required: true },
  publishedYear: { key: 'publishedYear', label: 'Năm xuất bản', type: KPTYPE.int, required: true },
  isLegitPublisher: { key: 'isLegitPublisher', label: 'Do Nhà xuất bản có uy tín xuất bản', type: KPTYPE.checkbox },
  // isLegit: { key: 'isLegit', label: 'Chuyên khảo uy tín', type: KPTYPE.checkbox },
  // isLegit: { key: 'isLegit', label: 'Chuyên khảo uy tín trong nước/Thế giới sau TS/PGS', type: KPTYPE.checkbox },
  editorPart: { key: 'editorPart', label: 'Phần biên soạn', type: KPTYPE.textarea, required: true },
  acceptedCount: { key: 'acceptedCount', label: 'Số văn bản xác nhận sử dụng sách', type: KPTYPE.textarea, required: true },
  doi: { key: 'doi', label: 'Link DOI' },
  journalTitle: { key: 'journalTitle', label: 'Tên tạp chí/kỷ yếu', required: true },
  impactFactor: { key: 'impactFactor', label: 'Chỉ số IF, Qi của tạp chí' },
  referencesCount: { key: 'referencesCount', label: 'Số trích dẫn', type: KPTYPE.int },
  page: { key: 'page', label: 'Trang' },
  volume: { key: 'volume', label: 'Tập' },
  issue: { key: 'issue', label: 'Số' },
  author: { key: 'author', label: 'Danh sách tác giả', type: KPTYPE.textarea },
  numberMain: { key: 'numberMain', label: 'Số chủ biên', type: KPTYPE.int },
  note: { key: 'note', label: 'Ghi chú' },
  proof: { key: 'proof', label: 'Minh chứng', type: KPTYPE.dropzone },
  applicationFormId: { key: 'applicationFormId', label: 'Mẫu đơn ứng dụng', required: true },
  role: { key: 'role', label: 'Vai trò', type: KPTYPE.select, options: MONOGRAPH_CONSTANTS.ROLE_OPTIONS, required: true },
};
