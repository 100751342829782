import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { FORM_MODELS } from '../../../reducers/form.reducer';
import { convertFeToBe } from '../../../utils';
import { crudActions, userActions } from '../../../actions';
import { CRUD_MODELS, RESEARCH_DIRECTION_MODEL } from '../../../constants';

import KPTextform from '../../KPTComponents/KPTextform';

import SachForm from '../../../containers/Crud/Sach/SachForm';
import DeTaiForm from '../../../containers/Crud/DeTai/DeTaiForm';
import BaiBaoForm from '../../../containers/Crud/BaiBao/BaiBaoForm';
import KyLuatForm from '../../../containers/Crud/KyLuat/KyLuatForm';
import BangCapForm from '../../../containers/Crud/BangCap/BangCapForm';
import NhiemVuForm from '../../../containers/Crud/NhiemVu/NhiemVuForm';
import SangCheForm from '../../../containers/Crud/SangChe/SangCheForm';
import TacPhamForm from '../../../containers/Crud/TacPham/TacPhamForm';
import HuongDanForm from '../../../containers/Crud/HuongDan/HuongDanForm';
import KhenThuongForm from '../../../containers/Crud/KhenThuong/KhenThuongForm';
import ChuongTrinhForm from '../../../containers/Crud/ChuongTrinh/ChuongTrinhForm';
import QuaTrinhDaoTaoForm from '../../../containers/Crud/QuaTrinhDaoTao/QuaTrinhDaoTaoForm';


function CrudForm({ type, defaultValues, filterType, disabled=false, ...props }) {
  const dispatch = useDispatch();

  const [values, setValues] = useState(null);

  const form = useSelector((state) => state.formReducer.form);
  const profile = useSelector((state) => state.profileReducer.profile);

  useEffect(() => {
    setValues(defaultValues || {});
  }, [defaultValues]);
  
  const handleSubmit = async () => {
    dispatch(userActions.setLoadingGlobal(true));
    const converted = await convertFeToBe(values, FORM_MODELS[type], '/form/' + form.id);
    dispatch(userActions.setLoadingGlobal(false));

    if (converted.error) {
      dispatch(userActions.notification({ message: converted.error, type: 'error' }));
      return;
    }
    
    const payload = {
      ...converted.values,
      // filterType: converted.filterType || filterType,
      profileId: profile.id,
      applicationFormId: form.id,
    };

    if (payload.id) {
      dispatch(crudActions.update(type, payload));
    } else {
      dispatch(crudActions.insert(type, payload));
    }

    props.handleClose();
  };

  const formMapComponent = useMemo(() => {
    const formProps = { id: 'thanh-tich-insert', disabled, values, setValues, handleSubmit};

    const formMap = {
      [CRUD_MODELS.AWARD]: <TacPhamForm { ...formProps } />,
      [CRUD_MODELS.REWARD]: <KhenThuongForm { ...formProps } />,
      [CRUD_MODELS.PROGRAM]: <ChuongTrinhForm { ...formProps } />,
      [CRUD_MODELS.SOLUTION]: <SangCheForm { ...formProps } />,
      [CRUD_MODELS.MONOGRAPH]: <SachForm { ...formProps } />,
      [CRUD_MODELS.DISCIPLINE]: <KyLuatForm { ...formProps } />,
      [CRUD_MODELS.EDUCATE_DEGREE]: <BangCapForm { ...formProps } />,
      [CRUD_MODELS.SCIENTIFIC_TASK]: <NhiemVuForm { ...formProps } />,
      [CRUD_MODELS.RESEARCH_PROJECT]: <DeTaiForm { ...formProps } />,
      [CRUD_MODELS.TRAINEE_GUIDANCE]: <HuongDanForm { ...formProps } />,
      [CRUD_MODELS.SCIENTIFIC_RESEARCH]: <BaiBaoForm { ...formProps } filterType={filterType} />,
      
      [CRUD_MODELS.RESEARCH_DIRECTION]: <KPTextform
        { ...formProps }
        textForm={[
          { ...RESEARCH_DIRECTION_MODEL.title, xs: 12 },
          { ...RESEARCH_DIRECTION_MODEL.description, xs: 12 },
        ]}
      />,

      // TH Đặc biệt: Update form
      'educateProcess': <QuaTrinhDaoTaoForm
        { ...formProps }
        handleSubmit={(values) => {
          const educateProcess = props.values.educateProcess;
          educateProcess[values.id] = values;
          props.saveForm({ educateProcess })
          props.handleClose();
        }} />
    }

    if (!type || !formMap.hasOwnProperty(type)) return null;
    return formMap[type];
  }, [values, type, filterType]);

  return values ? formMapComponent : null;
}

export default CrudForm;
