import React from 'react';

import KPTextform from '../../../../components/KPTComponents/KPTextform';

import { KPTYPE } from '../../../../components/KPTComponents/KPTextform/KPTextform';
import { SOLUTION_CONSTANTS, SOLUTION_MODEL, SR_BASE_MODEL } from '../../../../constants';

function SangCheForm(props) {
  const { disabled } = props;

  const textForm = [
    { ...SOLUTION_MODEL.title, xs: 12, height: 1, disabled  },
    { ...SOLUTION_MODEL.organizationName, xs: 12, disabled },
    { ...SOLUTION_MODEL.type, xs: 12, disabled },
    { ...SOLUTION_MODEL.issueDate, xs: 6, disabled },
    { ...SOLUTION_MODEL.phase, xs: 6, disabled },
    { ...SOLUTION_MODEL.author, xs: 12, disabled },
    { ...SOLUTION_MODEL.numberMain, xs: 6, disabled },
    { ...SOLUTION_MODEL.role, type: KPTYPE.select, options: SOLUTION_CONSTANTS.ROLE_OPTIONS, xs: 6, disabled },
    { ...SOLUTION_MODEL.note, xs: 12, disabled },
    { ...SR_BASE_MODEL.proof, xs: 12, disabled },
  ];

  return <KPTextform {...props} textForm={textForm} />
}

export default SangCheForm;
