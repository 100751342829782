import React from 'react';

import KPTextform from '../../../../components/KPTComponents/KPTextform';
import { REWARD_MODEL } from '../../../../constants';

function KhenThuongForm(props) {
  const { disabled } = props;

  const textForm = [
    { ...REWARD_MODEL.title, xs: 12, disabled },
    { ...REWARD_MODEL.level, xs: 7, disabled },
    { ...REWARD_MODEL.year, xs: 5, disabled },
    { ...REWARD_MODEL.proof, xs: 12, disabled },
  ];

  return <KPTextform {...props} textForm={textForm} />
}

export default KhenThuongForm;
