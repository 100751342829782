import React, { Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Card, CardContent, Link, Typography } from '@mui/material';

import theme from '../../../theme';
import KPTextform from '../../../components/KPTComponents/KPTextform';
import KPTextfield from '../../../components/KPTComponents/KPTextfield';

import { KPTYPE } from '../../../components/KPTComponents/KPTextform/KPTextform';
import { crudActions, userActions } from '../../../actions';
import { FORM_CONSTANTS, FORM_MODEL, PROFILE_MODEL } from '../../../constants';
import { useParams } from 'react-router-dom';

const mucBSub = {
  foreignLanguageTrainingAbroad: (values=null) => [
    { key: 'foreignLanguageTrainingAbroad.hocDaiHoc', xs: 12, label: 'Học đại học', labelRender: <></>, type: KPTYPE.checkbox },
    { key: 'foreignLanguageTrainingAbroad.noiHocDH', xs: 6, label: 'Tại nước', disabled: !values.foreignLanguageTrainingAbroad.hocDaiHoc },
    { key: 'foreignLanguageTrainingAbroad.namBDHocDH', xs: 3, label: 'Từ năm', disabled: !values.foreignLanguageTrainingAbroad.hocDaiHoc },
    { key: 'foreignLanguageTrainingAbroad.namKTHocDH', xs: 3, label: 'Đến năm', disabled: !values.foreignLanguageTrainingAbroad.hocDaiHoc },
    { key: 'foreignLanguageTrainingAbroad.baoVe', xs: 4, label: 'Bảo vệ', type: KPTYPE.select, options: [
      { key: 'null', title: '-', value: null },
      { key: 'ths', title: 'Luận văn ThS', value: 'ths' },
      { key: 'ts', title: 'Luận án TS', value: 'ts' },
      { key: 'tskh', title: 'TSKH', value: 'tskh' },

    ] },
    { key: 'foreignLanguageTrainingAbroad.noiBVLuanVan', xs: 5, label: 'Tại nước', disabled: !values.foreignLanguageTrainingAbroad.baoVe },
    { key: 'foreignLanguageTrainingAbroad.namBVLuanVan', xs: 3, label: 'Năm', disabled: !values.foreignLanguageTrainingAbroad.baoVe },
  ],
  foreignLanguageTrainingInland: (values=null) => [
    { key: 'foreignLanguageTrainingInland.truongCapBangDH', xs: 12, label: 'Trường ĐH cấp bằng tốt nghiệp ĐH ngoại ngữ', required: true },
    { key: 'foreignLanguageTrainingInland.soHieuBang', xs: 6, label: 'Số bằng', required: true },
    { key: 'foreignLanguageTrainingInland.namCapBang', xs: 6, label: 'Ngày cấp', type: KPTYPE.date, required: true },
  ],
  teachingInForeignLanguage: (values=null) => [
    { key: 'teachingInForeignLanguage.ngoaiNguGiangDay', xs: 12, label: 'Giảng dạy bằng ngoại ngữ', required: true },
    { key: 'teachingInForeignLanguage.noiGiangDay', xs: 12, label: 'Nơi giảng dạy (cơ sở đào tạo, nước)', type: KPTYPE.textarea, height: 1, required: true },
  ]
}

export const textForm = {
  mucA0: [
    { ...FORM_MODEL.category, xs: 12 },
    { ...FORM_MODEL.entity, xs: 12 },
    { ...FORM_MODEL.major, xs: 12 },
    {
      ...FORM_MODEL.specialization,
      type: KPTYPE.render,
      xs: 12,
      labelRender: <></>,
      render: ({ values, error, handleChangeForm }) => (
        <KPTextfield
          error={error}
          id='specialization'
          key='specialization'
          label='Chuyên ngành'
          type={KPTYPE.select}
          value={values.specialization || ''}
          options={FORM_CONSTANTS.SPECIALIZATION_OPTIONS[values.major] || []}
          handleChangeForm={handleChangeForm}
        />
      )
    },
  ],
  mucA: {
    muc1_6: [
      { ...PROFILE_MODEL.name, xs: 8 },
      { ...PROFILE_MODEL.birth, xs: 4 },
      { key: 'noti', xs: 12, type: KPTYPE.render, labelRender: <></>, render: () => (
          <Typography variant='subtitle1' style={{ color: theme.palette.info.main }}>
            <i>(Viết bằng Tiếng Việt có dấu, không viết hoa toàn bộ tên)</i>
          </Typography>
        )
      },
      { ...PROFILE_MODEL.gender, xs: 3 },
      { ...PROFILE_MODEL.nation, xs: 3 },
      { ...PROFILE_MODEL.ethnic, xs: 3 },
      { ...PROFILE_MODEL.religion, xs: 3 },
      { ...PROFILE_MODEL.isPartyMember, xs: 12 },
      { key: 'notixx', xs: 12, type: KPTYPE.render, render: () => null, label: '4. Quê quán' },
      { ...PROFILE_MODEL.commune, xs: 4 },
      { ...PROFILE_MODEL.district, xs: 4 },
      { ...PROFILE_MODEL.province, xs: 4 },
      { ...PROFILE_MODEL.placeOfResidence, xs: 12 },
      { ...PROFILE_MODEL.contactAddress, xs: 12 },
      { ...PROFILE_MODEL.address, xs: 12 },
      { ...PROFILE_MODEL.phone, xs: 12 },
      { ...PROFILE_MODEL.email, xs: 12, disabled: true },
    ],
    muc7: [
      { ...FORM_MODEL.pastWorkingProcessStartTime, xs: 5 },
      { key: 'to_0', xs: 2, labelRender: <Box sx={{ marginTop: '25.5px' }} />, type: KPTYPE.render, render: (props) => (
        <Box sx={{ height: 39, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography className='kpt-textfield-label'>đến</Typography>
        </Box>
      ) },
      { ...FORM_MODEL.pastWorkingProcessEndTime, xs: 5, labelRender: <Box sx={{ marginTop: '25.5px' }} /> },
      { ...FORM_MODEL.pastWorkingProcessUnit, xs: 12 },
      { ...FORM_MODEL.pastWorkingProcessUnitAddress, xs: 12 },
      { ...FORM_MODEL.pastWorkingProcessPosition, xs: 12 },
    ],
    muc8: [
      { ...FORM_MODEL.currentWorkingProcessPosition, xs: 12, label: 'Chức vụ hiện nay', required: true },
      { key: 'noti_0', xs: 12, type: KPTYPE.render, labelRender: <></>, render: () => (
        <Typography variant='subtitle1' style={{ color: theme.palette.info.main }}>
          <i>(Nếu đã nghỉ hưu, điền <b>Không có</b>)</i>
        </Typography>
      ) },
      { ...FORM_MODEL.currentWorkingProcessHighestPosition, xs: 12 },
      { ...FORM_MODEL.currentWorkingProcessUnit, xs: 12, md: 8 },
      { ...FORM_MODEL.currentWorkingProcessUnitPhone, xs: 12, md: 4 },
      { ...FORM_MODEL.currentWorkingProcessUnitAddress, xs: 12 },
      { ...FORM_MODEL.currentWorkingProcessUniversity, xs: 12 },
      { ...FORM_MODEL.currentWorkingProcessRetireTime, xs: 12 },
      { ...FORM_MODEL.currentWorkingProcessRetireUnit, xs: 12 },
      { ...FORM_MODEL.lastThreeYearUniversity, xs: 12 },
    ],
    muc10_13: [
      { ...FORM_MODEL.pgsStartTime, xs: 7 },
      { ...FORM_MODEL.pgsMajor, xs: 5 },
      { ...FORM_MODEL.scientificCouncil, xs: 12 },
      { ...FORM_MODEL.registeredMajor, xs: 12 },
    ],
  },
  mucB: {
    muc1: [
      { ...FORM_MODEL.selfAssessment, xs: 12 },
    ],
    muc2: [
      { ...FORM_MODEL.totalEducateYear, xs: 6 },
      { ...FORM_MODEL.totalEducateMonth, xs: 6 },
    ],
    muc3: [
      { ...FORM_MODEL.foreignLanguage, xs: 7 },
      {
        ...FORM_MODEL.otherForeignLanguage,
        xs: 5,
        type: KPTYPE.render,
        labelRender: <></>,
        required: true,
        render: ({ values, error, handleChangeForm }) => (
          <KPTextfield
            error={error}
            id='otherForeignLanguage'
            key='otherForeignLanguage'
            label='Ngoại ngữ khác'
            value={values.otherForeignLanguage || ''}
            disabled={values.foreignLanguage !== 'other'}
            required={values.foreignLanguage === 'other'}
            handleChangeForm={handleChangeForm}
          />
        )
      },
      { key: 'noti_muc3', xs: 12, type: KPTYPE.render, labelRender: <></>, render: () => (
        <Typography variant='subtitle1' style={{ color: theme.palette.info.main }}>
          <i>(<b>Lưu ý:</b> trường hợp ứng viên thành thạo nhiều hơn 1 ngoại ngữ thì chọn "Ngôn ngữ khác" và khai báo đầy đủ vào ô Ngoại ngữ khác)</i>
        </Typography>
      ) },
      { key: 'foreignLanguageTrainingAbroad.selected', xs: 12, label: 'a) Được đào tạo ở nước ngoài', labelRender: <></>, type: KPTYPE.checkbox },
      { key: 'foreignLanguageTrainingAbroadSub', xs: 12, type: KPTYPE.render, labelRender: <></>, render: ({ values, error, handleChangeForm }) => (
        <Fragment>
          {values.foreignLanguageTrainingAbroad?.selected && (
            <Card>
              <CardContent>
                <KPTextform
                  component={Box}
                  error={error}
                  values={values}
                  textForm={mucBSub.foreignLanguageTrainingAbroad(values)}
                  handleChangeForm={handleChangeForm}
                />
              </CardContent>
            </Card>
          )}
        </Fragment>
      )},
      { key: 'foreignLanguageTrainingInland.selected', xs: 12, label: 'b) Được đào tạo ngoại ngữ trong nước', labelRender: <></>, type: KPTYPE.checkbox },
      { key: 'foreignLanguageTrainingInlandSub', xs: 12, type: KPTYPE.render, labelRender: <></>, render: ({ values, error, handleChangeForm }) => (
        <Fragment>
          {values.foreignLanguageTrainingInland?.selected && (
            <Card>
              <CardContent>
                <KPTextform
                  component={Box}
                  error={error}
                  values={values}
                  textForm={mucBSub.foreignLanguageTrainingInland(values)}
                  handleChangeForm={handleChangeForm}
                />
              </CardContent>
            </Card>
          )}
        </Fragment>
      )},
      { key: 'teachingInForeignLanguage.selected', xs: 12, label: 'c) Giảng dạy bằng tiếng nước ngoài', labelRender: <></>, type: KPTYPE.checkbox },
      { key: 'teachingInForeignLanguageSub', xs: 12, type: KPTYPE.render, labelRender: <></>, render: ({ values, error, handleChangeForm }) => (
        <Fragment>
          {values.teachingInForeignLanguage?.selected && (
            <Card>
              <CardContent>
                <KPTextform
                  component={Box}
                  error={error}
                  values={values}
                  textForm={mucBSub.teachingInForeignLanguage(values)}
                  handleChangeForm={handleChangeForm}
                />
              </CardContent>
            </Card>
          )}
        </Fragment>
      )},
      { key: 'otherObject.selected', xs: 12, label: 'd) Đối tượng khác', labelRender: <></>, type: KPTYPE.checkbox },
      { key: 'otherObjectSub', xs: 12, type: KPTYPE.render, labelRender: <></>, render: ({ values, error, handleChangeForm }) => (
        <Fragment>
          {values.otherObject?.selected && (
            <Card>
              <CardContent>
                <KPTextform
                  component={Box}
                  error={error}
                  values={values}
                  textForm={[
                    { key: 'otherObject.dienGiai', xs: 12, label: 'Diễn giải', required: true },
                  ]}
                  handleChangeForm={handleChangeForm}
                />
              </CardContent>
            </Card>
          )}
        </Fragment>
      )},
      { ...FORM_MODEL.englishCertificate, xs: 12 },
      { ...FORM_MODEL.foreignLanguageDegreeCertificate, xs: 12, labelRender: <></> },
    ],
    muc9: [
      { key: 'remainingPGSTime',
        xs: 12,
        label: 'a) Thời gian được bổ nhiệm PGS',
        type: KPTYPE.render,
        render: () => (
          <Typography className='kpt-textfield-label' variant='subtitle1'>
            Được bổ nhiệm PGS chưa đủ 3 năm, thiếu (số lượng năm, tháng)
          </Typography>
        )
      },
      { key: 'remainingPGSTime.year', xs: 6, type: KPTYPE.int, label: 'Số lượng năm' },
      { key: 'remainingPGSTime.month', xs: 6, type: KPTYPE.int, label: 'Số lượng tháng' },
      {
        key: 'remainingEducateTime',
        xs: 12,
        label: 'b) Hoạt động đào tạo',
        type: KPTYPE.render,
        render: () => (
          <Typography className='kpt-textfield-label' variant='subtitle1'>
            Thâm niên đào tạo chưa đủ 6 năm (ƯV PGS), còn thiếu (số lượng năm, tháng)
          </Typography>
        )
      },
      { key: 'remainingDirectTeachingTime.year', xs: 6, type: KPTYPE.int, label: 'Số lượng năm' },
      { key: 'remainingConvertedTeachingTime.month', xs: 6, type: KPTYPE.int, label: 'Số lượng tháng' },
      {
        key: 'remainingEducateTime-noti',
        xs: 12,
        label: 'Giờ giảng dạy',
        type: KPTYPE.render,
        render: () => null
      },
      { key: 'remainingEducateTime.direct', xs: 12, label: 'Giờ chuẩn giảng dạy trực tiếp trên lớp không đủ, còn thiếu (năm học/số giờ thiếu)' },
      { key: 'remainingEducateTime.convert', xs: 12, label: 'Giờ chuẩn giảng dạy quy đổi không đủ, còn thiếu (năm học/số giờ thiếu)' },
      // { key: 'remainingDirectTeachingTime', xs: 12, label: '+ Giờ chuẩn giảng dạy trực tiếp trên lớp không đủ, còn thiếu (năm học/số giờ thiếu)', type: KPTYPE.render, render: () => null, },
      // { key: 'remainingDirectTeachingTime.month', xs: 6, label: 'Số lượng tháng' },
      // { key: 'remainingConvertedTeachingTime', xs: 12, label: '+ Giờ chuẩn giảng dạy quy đổi không đủ, còn thiếu (năm học/số giờ thiếu)', type: KPTYPE.render, render: () => null, },
      // { key: 'remainingConvertedTeachingTime.year', xs: 6, label: 'Số lượng năm' },
    ]
  },
  mucC: [
    { ...FORM_MODEL.generalScientificReportProof, xs: 12, labelRender: <></> },
  ],
  mucE: [
    { ...FORM_MODEL.trainingAndScientificResearchProof, xs: 12, labelRender: <></> },
  ],
}

export const ActionColumn = ({
  row,
  type,
  setDialog,
  filterType,
  setDuyetThanhTich,
  setPreview,
  setDeleteCrud,
}) => {
  const { id } = useParams();
  const dispatch = useDispatch();

  return (
    <Box>
      {(row.doi || row.proof) && (
        <Link
          variant='body2'
          className='action-btn'
          onClick={() => {
            if (typeof(row.doi || row.proof) === 'string') {
              window.open(row.doi || row.proof, '_blank');
            } else if (Array.isArray(row.doi || row.proof)) {
              if ((row.doi || row.proof).length > 0) {
                setPreview(row.doi || row.proof);
              } else {
                dispatch(userActions.notification({
                  message: 'Không có minh chứng', type: 'error'
                }));
              }
            }
          }}
          style={{ display: 'block' }}
        >
          {row.doi?.includes('doi') ? 'Link DOI' : 'Minh chứng'}
        </Link>
      )}
      <Link
        variant='body2'
        className='action-btn'
        color='primary'
        style={{ display: 'block', cursor: 'pointer' }}
        onClick={() => {
          setDialog({ type, filterType, defaultValues: row });
        }}
      >
        Chi tiết
      </Link>
      {setDuyetThanhTich ? (
        <Link
          className='action-btn'
          color={row.scoringTable?.accepted ? theme.palette.error.main : theme.palette.primary.main}
          style={{ display: 'block', cursor: 'pointer' }}
          onClick={() => setDuyetThanhTich(row)}
        >
          {row.scoringTable?.accepted ? 'Hủy cho điểm' : 'Cho điểm'}
        </Link>
      ) : (
        <>
          {!id && (
            <Link
              color='error'
              className='action-btn'
              style={{ display: 'block', cursor: 'pointer' }}
              onClick={(e) => {
                setDeleteCrud({
                  type,
                  anchorEl: e.currentTarget,
                  handleDelete: () => dispatch(crudActions.remove(type, row?.id)),
                });
              }}
            >
              Xóa
            </Link>
          )}
        </>
      )}
    </Box>
  )
}
