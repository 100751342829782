import React, { useState,useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate } from 'react-router-dom';
import { Box, Grid, Card, Typography } from '@mui/material';

import theme from '../../../theme';
import KPTextform from '../../../components/KPTComponents/KPTextform';

import { KPTYPE } from '../../../components/KPTComponents/KPTextform/KPTextform';
import { userActions } from '../../../actions';
import { convertFeToBe } from '../../../utils';
import { COLOR_PALETTE, PROFILE_MODEL, ROUTER, USER_CONSTANTS } from '../../../constants';

const TUTORIAL_STEP = [
  {
    title: 'Hoàn thiện thông tin',
    description: 'Hãy điền hết thông tin trong các ô, các trường thông tin yêu cầu phải chính xác, trung thực'
  },
  {
    title: 'Xác thực tài khoản',
    description: <>Sau khi đã hoàn thiện các trường thông tin, click vào nút <b>ĐĂNG KÝ TÀI KHOẢN</b>, mật khẩu sẽ được gửi về email của ứng viên</>,
  },
  {
    title: 'Đăng ký thành công',
    description: 'Với mật khẩu được gửi về email, ứng viên đã có thể sử dụng để truy cập vào hệ thống'
  },
];

function DangKy() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const profile = useSelector(state => state.profileReducer.profile);

  const [values, setValues] = useState({});

  useEffect(() => {
    if (!!profile) {
      const { ROLE } = USER_CONSTANTS;
      if (profile?.userRole === ROLE.council || profile?.userRole === ROLE.super_admin) {
        navigate(ROUTER.DANH_SACH_HO_SO);
      } else {
        navigate(ROUTER.DANG_KY_HO_SO);
      }
    }
  }, [profile]);

  const textForm = [
    { ...PROFILE_MODEL.name, xs: 12, md: 8 },
    { ...PROFILE_MODEL.birth, xs: 12, md: 4 },
    { key: 'noti', xs: 12, type: KPTYPE.render, labelRender: <></>, render: () => (
      <Typography variant='subtitle1' style={{ color: theme.palette.info.main }}>
        (Viết bằng Tiếng Việt có dấu, không viết hoa toàn bộ tên)
      </Typography>
    )
    },
    { ...PROFILE_MODEL.gender, xs: 12, md: 4 },
    { ...PROFILE_MODEL.phone, xs: 12, md: 8 },
    { ...PROFILE_MODEL.email, xs: 12 },
    { key: 'submit', xs: 12, label: 'Đăng ký tài khoản', labelRender: <></>, type: 'submit' },
  ];

  const handleSubmit = async () => {
    const payload = await convertFeToBe({...values}, PROFILE_MODEL);
    dispatch(userActions.register(payload.values)).then((user) => {
      if (user) navigate(ROUTER.DANG_NHAP);
    });
  }

  return (
    <Box height='calc(100vh - 64px)' alignContent='center'>
      <Grid container alignContent='center' justifyContent='center' spacing={5}>
        <Grid item xs={12} md={4} lg={4}>
          {TUTORIAL_STEP.map((e, id) => (
            <Box key={`tutor-${id}`} display='flex'>
              <Box display='flex' flexDirection='column'>
                <Box
                  width={42}
                  height={42}
                  marginRight={4}
                  display='flex'
                  color={COLOR_PALETTE.PRIMARY}
                  alignItems='center'
                  justifyContent='center'
                  borderRadius='50%'
                  border={`4px solid ${COLOR_PALETTE.PRIMARY}`}
                >
                  <b style={{ fontSize: 24, fontFamily: 'SFProDisplay-Bold' }}>
                    {id + 1}
                  </b>
                </Box>
                <Box display='flex' justifyContent='center' flexGrow={1} marginRight={4} py={1}>
                  <Box width='1px' borderLeft='4px dotted #cdcdcd' />
                </Box>
              </Box>
              <Box width='calc(100% - 80px)' marginBottom={4}>
                <Typography variant='h5' color={COLOR_PALETTE.PRIMARY} style={{ fontFamily: 'SFProDisplay-Bold' }}>
                  <b>{e.title}</b>
                </Typography>
                <Typography variant='subtitle1' color='gray' style={{ textAlign: 'justify' }}>
                  {e.description}
                </Typography>
              </Box>
            </Box>
          ))}
        </Grid>
        <Grid item xs={12} md={8} lg={6}>
          <Card style={{ padding: 16, backgroundColor: '#e7f5e5' }}>
            <Typography variant='h4' sx={{ mb: 4, textAlign: 'center', fontFamily: 'SFProDisplay-Bold' }}>
              <b>Đăng ký tài khoản</b>
            </Typography>
            <KPTextform
              textForm={textForm}
              values={values}
              setValues={setValues}
              handleSubmit={handleSubmit}
            />
          </Card>
          <Typography style={{ marginTop: 8, textAlign: 'center' }}>
            {'Đã có tài khoản? '}
            <NavLink to={ROUTER.DANG_NHAP} style={{ color: COLOR_PALETTE.PRIMARY }}>Đăng nhập</NavLink>
          </Typography>
        </Grid>
      </Grid>
    </Box>
  );
}

export default DangKy;
