import React from 'react';

import KPTable from '../../../../components/KPTComponents/KPTable';

import { ActionColumn } from '../../../../containers/DangKyHoSo/utils';
import { CRUD_MODELS, SCIENTIFIC_TASK_MODEL } from '../../../../constants';

function NhiemVuTable({ rows, setDialog, ...props }) {
  const columns = [
    { key: 'orderNumber', width: '10%', title: 'STT' },
    { ...SCIENTIFIC_TASK_MODEL.title, width: '30%' },
    { ...SCIENTIFIC_TASK_MODEL.code, width: '20%' },
    {
      ...SCIENTIFIC_TASK_MODEL.role,
      width: '20%',
      filters: SCIENTIFIC_TASK_MODEL.role.options,
      render: (value) => SCIENTIFIC_TASK_MODEL.role.options.find((e) => e.value === value)?.title,
      onFilter: (value, row) => row.role === value,
    },
    {
      key: 'link',
      label: 'Tác vụ',
      width: '20%',
      render: (_, row) => (
        <ActionColumn
          row={row}
          type={CRUD_MODELS.SCIENTIFIC_TASK}
          setDialog={setDialog}
          setPreview={props.setPreview}
          setDeleteCrud={props.setDeleteCrud}
        />
      )
    }
  ];

  return (
    <KPTable
      columns={columns}
      nonBorder={!!props.nonBorder}
      rows={rows}
      // loading={loading}
    />
  );
}

export default NhiemVuTable;
