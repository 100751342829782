import React from 'react';

import KPTextform from '../../../../components/KPTComponents/KPTextform';
import { EDUCATE_DEGREE_MODEL } from '../../../../constants';

function BangCapForm(props) {
  const { disabled } = props;

  const textForm = [
    { ...EDUCATE_DEGREE_MODEL.degreeType, xs: 12, disabled },
    { ...EDUCATE_DEGREE_MODEL.major, xs: 6, disabled },
    { ...EDUCATE_DEGREE_MODEL.specialization, xs: 6, disabled },
    { ...EDUCATE_DEGREE_MODEL.issueDate, xs: 6, disabled },
    { ...EDUCATE_DEGREE_MODEL.number, xs: 6, disabled },
    { ...EDUCATE_DEGREE_MODEL.issuePlace, xs: 12, disabled },
    { ...EDUCATE_DEGREE_MODEL.proof, xs: 12, disabled },
  ];

  return <KPTextform {...props} textForm={textForm} />
}

export default BangCapForm;
