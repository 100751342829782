import React from 'react';

import KPTable from '../../../../components/KPTComponents/KPTable';

import { ActionColumn } from '../../../../containers/DangKyHoSo/utils';
import { MONOGRAPH_MODEL, CRUD_MODELS } from '../../../../constants';
import { Chip } from '@mui/material';

function SachTable({ rows, setDialog, setDuyetThanhTich, ...props }) {
  const columns = [
    { key: 'orderNumber', width: setDuyetThanhTich ? '5%' : '10%', title: 'STT' },
    { ...MONOGRAPH_MODEL.title, width: '25%' },
    {
      ...MONOGRAPH_MODEL.type,
      width: '15%',
      align: 'center',
      render: (value) => MONOGRAPH_MODEL.type.options.find((e) => e.value === value)?.title,
	  },
    {
      ...MONOGRAPH_MODEL.role,
      align: 'center',
      width: '15%',
      render: (value) => MONOGRAPH_MODEL.role.options.find((e) => e.value === value)?.title,
	  },
  ];

  if (setDuyetThanhTich) {
    columns.push({
      key: 'point',
      label: 'Điểm',
      align: 'center',
      width: '5%',
      render: (_, row) => row.scoringTable?.point
    });
    columns.push({
      key: 'accepted',
      label: 'Trạng thái',
      align: 'center',
      width: '15%',
      render: (_, row) => (
        <Chip
          size='small'
          color={row.scoringTable?.accepted ? 'primary' : 'default'}
          label={row.scoringTable?.accepted ? 'Đã chấm' : 'Chưa chấm'}
          variant={row.scoringTable?.accepted ? 'filled' : 'outlined'}
        />
      )
    });
  }

  columns.push({
    key: 'accepted',
    label: 'Trạng thái',
    width: '15%',
    render: (_, row) => (
      <ActionColumn
        row={row}
        type={CRUD_MODELS.MONOGRAPH}
        setDialog={setDialog}
        setDeleteCrud={props.setDeleteCrud}
        setDuyetThanhTich={setDuyetThanhTich}
      />
    )
  });

  return (
    <KPTable
      columns={columns}
      nonBorder={!!props.nonBorder}
      rows={rows}
      // loading={loading}
    />
  );
}

export default SachTable;
