import { KPTYPE } from '../components/KPTComponents/KPTextform/KPTextform';
import { PROGRAM_CONSTANTS } from './program.constants';


export const RESEARCH_PROJECT_MODEL = {
  title: { key: 'title', label: 'Tên', type: KPTYPE.textarea, required: true },
  role: { key: 'role', label: 'Vai trò', type: KPTYPE.select, options: PROGRAM_CONSTANTS.ROLE_OPTIONS, required: true },
  taskAssignmentDocument: { key: 'taskAssignmentDocument', label: 'Văn bản giao nhiệm vụ (số, ngày, tháng, năm)', type: KPTYPE.textarea, required: true },
  assessmentAgency: { key: 'assessmentAgency', label: 'Cơ quan thẩm định', type: KPTYPE.textarea, required: true },
  usageDocument: { key: 'usageDocument', label: 'Văn bản đưa vào sử dụng', type: KPTYPE.textarea },
  note: { key: 'note', label: 'Ghi chú', type: KPTYPE.textarea },
  proof: { key: 'proof', label: 'Minh chứng', type: KPTYPE.dropzone }
};
