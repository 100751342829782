import { KPTYPE } from '../components/KPTComponents/KPTextform/KPTextform';

export const EDUCATE_DEGREE_CONSTANTS = {
  TYPE_OPTIONS: [
    { value: "daiHoc", title: "Đại Học", key: "daiHoc" },
    { value: "thacSi", title: "Thạc Sĩ", key: "thacSi" },
    { value: "tienSi", title: "Tiến Sĩ", key: "tienSi" },
    { value: "TSKH", title: "Tiến Sĩ Khoa Học", key: "TSKH" },
    { value: "PGS", title: "Phó Giáo Sư", key: "PGS" },
  ],
};

export const EDUCATE_DEGREE_MODEL = {
  degreeType: { key: 'degreeType', label: 'Loại bằng cấp', type: KPTYPE.select, options: EDUCATE_DEGREE_CONSTANTS.TYPE_OPTIONS, required: true },
  major: { key: 'major', label: 'Ngành', required: true },
  specialization: { key: 'specialization', label: 'Chuyên ngành', required: true },
  issueDate: { key: 'issueDate', label: 'Ngày cấp bằng', type: KPTYPE.date, required: true },
  issuePlace: { key: 'issuePlace', label: 'Nơi cấp bằng', required: true },
  number: { key: 'number', label: 'Số văn bằng' },
  proof: { key: 'proof', label: 'Minh chứng', type: KPTYPE.dropzone },
};

