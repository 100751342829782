import { KPTYPE } from '../components/KPTComponents/KPTextform/KPTextform';
import { HEADERS } from '.';
import EthnicOptions from '../constants/ethnic.json';

export const PROFILE_API = {
  getProfileByUserId: (user_id) => ({
    endPoint: `/api/v1/profile/user/${user_id}`,
    headers: HEADERS.TOKEN_HEADER,
    method: 'GET',
  }),
  updateProfile: () => ({
    endPoint: '/api/v1/profile/update',
    headers: HEADERS.TOKEN_HEADER,
    method: 'PUT',
  }),
  getProfileList: () => ({
    endPoint: '/api/v1/form/list',
    headers: HEADERS.TOKEN_HEADER,
    method: 'GET',
  }),
}

export const PROFILE_TYPE = {
  GET_PROFILE_BY_USER_ID_LOADING: 'GET_PROFILE_BY_USER_ID_LOADING',
  GET_PROFILE_BY_USER_ID_SUCCESS: 'GET_PROFILE_BY_USER_ID_SUCCESS',
  GET_PROFILE_BY_USER_ID_FAILURE: 'GET_PROFILE_BY_USER_ID_FAILURE',

  UPDATE_PROFILE_LOADING: 'UPDATE_PROFILE_LOADING',
  UPDATE_PROFILE_SUCCESS: 'UPDATE_PROFILE_SUCCESS',
  UPDATE_PROFILE_FAILURE: 'UPDATE_PROFILE_FAILURE',

  GET_PROFILE_LIST_LOADING: 'GET_PROFILE_LIST_LOADING',
  GET_PROFILE_LIST_SUCCESS: 'GET_PROFILE_LIST_SUCCESS',
  GET_PROFILE_LIST_FAILURE: 'GET_PROFILE_LIST_FAILURE',

  GET_PROFILE_FILTER_FORM_LOADING: 'GET_PROFILE_FILTER_FORM_LOADING',
  GET_PROFILE_FILTER_FORM_SUCCESS: 'GET_PROFILE_FILTER_FORM_SUCCESS',
  GET_PROFILE_FILTER_FORM_FAILURE: 'GET_PROFILE_FILTER_FORM_FAILURE',
};

export const PROFILE_CONSTANTS = {
  GENDER_OPTIONS: [
    { key: 'male', title: 'Nam', value: 'male' },
    { key: 'female', title: 'Nữ', value: 'female' },
  ],
  RELIGION_OPTIONS: [
    { value: "none", title: "Không", key: "none" },
    { value: "phatGiao", title: "Phật giáo", key: "phatGiao" },
    { value: "congGiao", title: "Công giáo", key: "congGiao" },
    { value: "caoDai", title: "Cao Đài", key: "caoDai" },
    { value: "hoaHao", title: "Hòa Hảo", key: "hoaHao" },
    { value: "tinLanh", title: "Tin Lành", key: "tinLanh" },
    { value: "hoiGiao", title: "Hồi Giáo", key: "hoiGiao" },
    { value: "baLaMon", title: "Bà La Môn", key: "baLaMon" },
    { value: "daoTu", title: "Đạo Tứ ấn hiếu nghĩa", key: "daoTu" },
    { value: "tinhDoCuSiPhatHoiVN", title: "Tịnh độ cư sĩ Phật hội Việt Nam", key: "tinhDoCuSiPhatHoiVN" },
    { value: "buuSonKyHuong", title: "Bửu sơn Kỳ hương", key: "buuSonKyHuong" },
    { value: "minhSu", title: "Minh Sư Đạo", key: "minhSu" },
    { value: "baha", title: "Baha'i", key: "baha" },
    { value: "minhLy", title: "Minh Lý Đạo", key: "minhLy" }
  ],
}

export const PROFILE_MODEL = {
  name: { key: 'name', label: '1. Họ và tên người đăng ký', required: true },
  birth: { key: 'birth', label: '2. Ngày tháng năm sinh', type: KPTYPE.date, required: true },
  gender: { key: 'gender', label: 'Giới tính', type: KPTYPE.select, options: PROFILE_CONSTANTS.GENDER_OPTIONS, required: true },
  nation: { key: 'nation', label: 'Quốc tịch', required: true },
  ethnic: { key: 'ethnic', label: 'Dân tộc', type: KPTYPE.select, options: EthnicOptions.map((e) => ({ ...e, value: e.title })), required: true },
  religion: { key: 'religion', label: 'Tôn giáo', type: KPTYPE.select, options: PROFILE_CONSTANTS.RELIGION_OPTIONS, required: true },
  isPartyMember: { key: 'isPartyMember', label: '3. Là Đảng viên Đảng Cộng sản Việt Nam', labelRender: <></>, type: KPTYPE.checkbox },
  placeOfOrigin: { key: 'placeOfOrigin', label: '4. Quê quán (xã/phường, quận/huyện, tỉnh/thành phố)', required: true },
  placeOfResidence: { key: 'placeOfResidence', label: '5. Nơi đăng ký hộ khẩu thường trú (số nhà, phố, phường, quận, thành phố hoặc xã, huyện, tỉnh)', type: KPTYPE.textarea, required: true },
  contactAddress: { key: 'contactAddress', label: '6. Địa chỉ liên hệ (ghi rõ, đầy đủ để liên hệ được qua Bưu điện): ', type: KPTYPE.textarea, required: true },
  
  commune: { key: 'commune', label: 'Xã/phường', required: true },
  district: { key: 'district', label: 'Huyện/quận', required: true },
  province: { key: 'province', label: 'Tỉnh/thành phố', required: true },
  
  address: { key: 'address', label: 'Điện thoại nhà riêng' },
  phone: { key: 'phone', label: 'Số điện thoại', required: true },
  email: { key: 'email', label: 'Email', required: true },
}
