import React from "react";
import { Box } from "@mui/material";

import DanhSachHoSoTable from "../../containers/DanhSachHoSo/DanhSachHoSoTable/DanhSachHoSoTable";

function DanhSachHoSo() {
  return <Box my={6}><DanhSachHoSoTable /></Box>
}

export default DanhSachHoSo;
