import { apiCall } from '../utils';
import { FORM_API, FORM_TYPE } from '../constants';

import { userActions } from '.';
const { notification } = userActions;

export const formActions = {
  getApplicationFormById,
  getAllApplicationForm,
  filterApplicationForm,
  insertApplicationForm,
  updateApplicationForm,
};

function getApplicationFormById(id) {
  return async (dispatch) => {
    dispatch(loading());

    const api = FORM_API.getApplicationFormById(id);
    const { response, error } = await apiCall(api);

    if (!error && response.status === 200) {
      dispatch(success(response.data.data));
      dispatch(notification({ message: 'Lấy hồ sơ thành công', type: 'success' }));
      return true;
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Lấy hồ sơ thất bại', type: 'error' }));
      return false;
    }
  };

  function loading() { return { type: FORM_TYPE.GET_APPLICATION_FORM_BY_ID_LOADING } }
  function success(payload) { return { type: FORM_TYPE.GET_APPLICATION_FORM_BY_ID_SUCCESS, payload } }
  function failure(error) { return { type: FORM_TYPE.GET_APPLICATION_FORM_BY_ID_FAILURE, error } }
}

function getAllApplicationForm() {
  return async (dispatch) => {
    dispatch(loading());

    const api = FORM_API.getAllApplicationForm();
    const { response, error } = await apiCall(api);

    if (!error && response.status === 200) {
      dispatch(success(response.data.data));
      dispatch(notification({ message: 'Lấy danh sách hồ sơ thành công', type: 'success' }));
      return true;
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Lấy danh sách hồ sơ thất bại', type: 'error' }));
      return false;
    }
  };

  function loading() { return { type: FORM_TYPE.GET_ALL_APPLICATION_FORM_LOADING } }
  function success(payload) { return { type: FORM_TYPE.GET_ALL_APPLICATION_FORM_SUCCESS, payload } }
  function failure(error) { return { type: FORM_TYPE.GET_ALL_APPLICATION_FORM_FAILURE, error } }
}

function insertApplicationForm(payload) {
  return async (dispatch) => {
    dispatch(loading());

    const api = FORM_API.insertApplicationForm();
    const { response, error } = await apiCall({ ...api, payload });

    if (!error && response.status === 200) {
      dispatch(success({
        ...payload,
        id: response.data.data
      }));
      dispatch(notification({ message: 'Cập nhật thông tin hồ sơ thành công', type: 'success' }));
      return true;
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Cập nhật thông tin hồ sơ thất bại', type: 'error' }));
      return false;
    }
  };

  function loading() {
    return { type: FORM_TYPE.INSERT_APPLICATION_FORM_LOADING };
  }
  function success(payload) {
    return { type: FORM_TYPE.INSERT_APPLICATION_FORM_SUCCESS, payload };
  }
  function failure(error) {
    return { type: FORM_TYPE.INSERT_APPLICATION_FORM_FAILURE, error };
  }
}

function updateApplicationForm(payload) {
  return async (dispatch) => {
    dispatch(loading());

    const api = FORM_API.updateApplicationForm();
    const { response, error } = await apiCall({ ...api, payload });

    if (!error && response.status === 200) {
      dispatch(success(payload));
      dispatch(notification({ message: 'Cập nhật thông tin hồ sơ thành công', type: 'success' }));
      return true;
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Cập nhật thông tin hồ sơ thất bại', type: 'error' }));
      return false;
    }
  };

  function loading() { return { type: FORM_TYPE.UPDATE_APPLICATION_FORM_LOADING }}
  function success(payload) { return { type: FORM_TYPE.UPDATE_APPLICATION_FORM_SUCCESS, payload }}
  function failure(error) { return { type: FORM_TYPE.UPDATE_APPLICATION_FORM_FAILURE, error }}
}

function filterApplicationForm(payload) {
  return async (dispatch) => {
    dispatch(loading());

    const api = FORM_API.filterApplicationForm();
    const { response, error } = await apiCall({ ...api, payload });

    if (!error && response.status === 200) {
      dispatch(success(response.data.data?.[0] || {}));
      dispatch(notification({ message: 'Lấy thông tin hồ sơ thành công', type: 'success' }));
      return response.data.data?.[0];
    } else {
      dispatch(failure(error));
      dispatch(notification({ message: 'Lọc hồ sơ thất bại', type: 'error' }));
      return null;
    }
  };

  function loading() { return { type: FORM_TYPE.FILTER_APPLICATION_FORM_LOADING } }
  function success(payload) { return { type: FORM_TYPE.FILTER_APPLICATION_FORM_SUCCESS, payload } }
  function failure(error) { return { type: FORM_TYPE.FILTER_APPLICATION_FORM_FAILURE, error } }
}
