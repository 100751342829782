import dayjs from "dayjs";
import { importMultiFileAsync } from "../actions";

import { KPTYPE } from "../components/KPTComponents/KPTextform/KPTextform";
import { fileRender } from "./photoRender";
import { dateFormatter } from "./valueFormatter";

export const convertFeToBe = async (orgValues, model, fileDir) => {
  const values = { ...orgValues };
  const keys = Object.keys(values).filter((key) => model.hasOwnProperty(key));

  const fileData = { key: [], file: [], path: [] };

  keys.forEach((key) => {
    switch (model[key].type) {
      case KPTYPE.date:
        values[key] = dateFormatter(values[key]);
        break;
      case KPTYPE.json:
        values[key] = JSON.stringify(values[key]);
        break;
      case KPTYPE.dropzone:
        if (!Array.isArray(values[key])) break;
        const files = [...values[key]];
        files.forEach((file) => {
          if (typeof(file) === 'string') {
            // string thì dùng dữ liệu cũ
            // values[key] = undefined;
          } else {
            // file mới thì import
            fileData.key.push(key);
            fileData.file.push(file);
            fileData.path.push(fileDir);
          }
        });
        values[key] = undefined;
        break;
      default:
        break;
    }
  });

  if (fileData.file.length > 0) {
    const { response, error } = await importMultiFileAsync(fileData.file, fileData.path);
    if (error || response.status !== 200) {
      return { values, error: 'Thêm minh chứng thất bại' }
    } else {
      // File Start
      fileData.key.forEach((key, id) => {
        if (!values[key]) values[key] = [];
        // const pathname = fileData.path[id] + '/' + fileData.file[id].name;
        values[key].push(response.data.data[id]);
      });
    }
  }

  fileData.key.forEach((key) => {
    if (typeof(values[key]) !== 'string') {
      values[key] = values[key].map((item) => item.replaceAll(`${process.env.REACT_APP_DOMAIN}/api/v1/media`, ''))
      values[key] = JSON.stringify(values[key]);
    }
  });
  // File End

  return { values, error: null }
}

export const convertBeToFe = (orgValues, model) => {
  const values = { ...orgValues };
  if (!values) return {};

  const keys = Object.keys(values).filter((key) => model.hasOwnProperty(key));

  keys.forEach((key) => {
    switch (model[key].type) {
      case KPTYPE.date:
        const tempDate = dayjs(values[key]);
        if (tempDate.isValid()) values[key] = tempDate.format('DD/MM/YYYY');
        break;
      case KPTYPE.json:
        try {
          values[key] = JSON.parse(values[key] || '{}');
          if (typeof(values[key]) !== 'object') values[key] = {};
        } catch (e) {
          console.error(key, e);
          values[key] = {};
        }
        break;
      case KPTYPE.dropzone:
        if (Array.isArray(values[key])) break;
        try {
          values[key] = JSON.parse(values[key] || '[]');
        } catch (e) {
          values[key] = [];
        }
        if (!Array.isArray(values[key])) break;
        const files = [...values[key]].map((file) => fileRender(file));
        values[key] = files;
        break;
      default:
        break;
    }
  });

  return values;
}
