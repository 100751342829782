import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';

function Loading({ open=false }) {
  return (
    <Backdrop open={open} style={{ zIndex: 2000 }}>
      <CircularProgress style={{ color: 'white' }} />
    </Backdrop>
  );
}

export default Loading;
