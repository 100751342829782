import { COUNCIL_TYPE } from '../constants';

const initialState = {
  councils: null,
  loading: false,
}

export function councilReducer(state = initialState, action) {
  switch (action.type) {
    case COUNCIL_TYPE.FILTER_COUNCIL_LOADING:
    case COUNCIL_TYPE.UPDATE_COUNCIL_LOADING:
      return { ...state, loading: true };

    case COUNCIL_TYPE.FILTER_COUNCIL_FAILURE:
    case COUNCIL_TYPE.UPDATE_COUNCIL_FAILURE:
      return { ...state, loading: false };

    case COUNCIL_TYPE.FILTER_COUNCIL_SUCCESS:
      return { ...state, loading: false, councils: action.payload };

    case COUNCIL_TYPE.UPDATE_COUNCIL_SUCCESS:
      const tempCouncils = state.councils;
      const index = tempCouncils.findIndex((council) => council.profieId === action.payload.id);
      tempCouncils[index].applicationFormIds = action.payload.applicationFormIds;
      return { ...state, loading: false, councils: tempCouncils };

    default:
      return state
  }
}
