import React from 'react';

import { ROUTER } from '../../constants';

import DangKy from '../../containers/AppLayout/DangKy';
import DangNhap from '../../containers/AppLayout/DangNhap';
import DoiMatKhau from '../../containers/AppLayout/DoiMatKhau';
import QuanLyNguoiDung from '../../containers/AppLayout/QuanLyNguoiDung';
import PhanQuyenHoiDong from '../../containers/Admin/PhanQuyenHoiDong';

import Home from '../Home';
// import GioiThieu from '../GioiThieu';
import DangKyHoSo from '../DangKyHoSo';
import DanhSachHoSo from '../DanhSachHoSo';
import CauHinhHeThong from '../CauHinhHeThong';
import DanhSachThanhTich from '../DanhSachThanhTich';

const ROUTER_CONFIG = {
  BASE: [
    { path: ROUTER.HOME, element: <Home />, disableHeader: true, maxWidth: 'xl', authRequired: false },
    { path: ROUTER.DANG_KY, element: <DangKy />, disableHeader: true, maxWidth: 'xl', authRequired: false },
    { path: ROUTER.DANG_NHAP, element: <DangNhap />, disableHeader: true, maxWidth: 'md', authRequired: false },
    // { path: ROUTER.GIOI_THIEU, element: <GioiThieu />, authRequired: false },
    { path: ROUTER.DOI_MAT_KHAU, element: <DoiMatKhau />, maxWidth: 'md' },
    { path: ROUTER.DANG_KY_HO_SO, element: <DangKyHoSo />, maxWidth: 'lg', bgColor: '#e7f5e5' },
    { path: ROUTER.CHI_TIET_HO_SO + '/:id', element: <DangKyHoSo />, maxWidth: 'lg', bgColor: '#e7f5e5' },
  ],
  COUNCIL: [
    { path: ROUTER.DANH_SACH_HO_SO, element: <DanhSachHoSo />, maxWidth: 'lg' },
    { path: ROUTER.DANH_SACH_THANH_TICH + '/:id', element: <DanhSachThanhTich />, maxWidth: 'lg', exact: false },
  ],
  ADMIN: [
    { path: ROUTER.CAU_HINH, element: <CauHinhHeThong />, maxWidth: 'lg', adminRoleRequired: true },
    { path: ROUTER.QUAN_LY_NGUOI_DUNG, element: <QuanLyNguoiDung />, maxWidth: 'lg', adminRoleRequired: true },
    { path: ROUTER.PHAN_QUYEN_HOI_DONG, element: <PhanQuyenHoiDong />, maxWidth: 'lg', adminRoleRequired: true },
    { path: ROUTER.DANH_SACH_HO_SO, element: <DanhSachHoSo />, maxWidth: 'lg' },
    { path: ROUTER.DANH_SACH_THANH_TICH + '/:id', element: <DanhSachThanhTich />, maxWidth: 'lg', exact: false },
  ]
}

export default ROUTER_CONFIG;
