import { KPTYPE } from '../components/KPTComponents/KPTextform/KPTextform';

export const PROGRAM_CONSTANTS = {
  ROLE_OPTIONS: [
    { value: "main", title: "Chủ trì", key: "main" },
    { value: "other", title: "Tham gia", key: "other" },
  ]
}

export const PROGRAM_MODEL = {
  title: { key: 'title', label: 'Tên chương trình đào tạo, khoa học và công nghệ', required: true },
  role: { key: 'role', label: 'Trách nhiệm', type: KPTYPE.select, options: PROGRAM_CONSTANTS.ROLE_OPTIONS, required: true },
  assessmentDocument: { key: 'assessmentDocument', label: 'Văn bản thẩm định để đưa vào sử dụng (văn bản, số, ngày, tháng, năm)', required: true },
  proof: { key: 'proof', label: 'Minh chứng', type: KPTYPE.dropzone, required: true },
};
