import React from 'react';
import { useDispatch } from 'react-redux';

import KPTable from '../../../../components/KPTComponents/KPTable';

import { ActionColumn } from '../../../../containers/DangKyHoSo/utils';
import { AWARD_MODEL, CRUD_MODELS } from '../../../../constants';

function TacPhamTable({ rows, setDialog, ...props }) {
  const dispatch = useDispatch();

  const columns = [
    { ...AWARD_MODEL.title, width: '35%' },
    {
      ...AWARD_MODEL.level,
      width: '25%',
      filters: AWARD_MODEL.level.options,
      render: (value) => AWARD_MODEL.level.options.find((e) => e.value === value)?.title,
      onFilter: (value, row) => row.level === value,
    },
    { ...AWARD_MODEL.authorNumber, width: '20%' },
    {
      key: 'link',
      label: 'Tác vụ',
      width: '20%',
      render: (_, row) => (
        <ActionColumn
          row={row}
          type={CRUD_MODELS.AWARD}
          setDialog={setDialog}
          setPreview={props.setPreview}
          setDeleteCrud={props.setDeleteCrud}
        />
      ),
    }
  ];

  return (
    <KPTable
      columns={columns}
      nonBorder={!!props.nonBorder}
      rows={rows}
      // loading={loading}
    />
  );
}

export default TacPhamTable;
